import './EventsCalendar.scss';

import { Button, Col, Modal, Row, Tooltip, Typography } from 'antd';
import crossPawIcon from 'assets/img/crossPaw.svg';
import doctorIcon from 'assets/img/doctor.svg';
import doctorsIcon from 'assets/img/doctors.svg';
import locationIcon from 'assets/img/location.svg';
import personIcon from 'assets/img/person.svg';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedEvent as setSelectedEventData } from 'redux/events/actions';
import { getTime } from 'utils/get-time';
import { getAtendees, getParticipants, getPatients, getServices } from 'utils/string-from-entities-list';

const { Title } = Typography;

export const EventModal = ({
  selectedEvent,
  selectedEventData,
  setSelectedEvent,
  selectedAppointmentData,
  locale,
  setShowCalcelEventModal,
  onEditEventClick,
  showEventButtons
}: any): JSX.Element => {
  const { event_type, location, clinic_id } = selectedEvent?.resource ?? {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [event, setEvent] = useState(null);

  const user = useSelector(({ user }: IAppState) => user);

  useEffect(() => {
    if (event_type === 'appointment' && selectedAppointmentData) {
      setEvent(selectedAppointmentData);
    } else if (event_type === 'event' && selectedEventData) {
      setEvent(selectedEventData);
    } else if (event_type === 'event' && location?.details === 'unavailable') {
      setEvent(selectedEvent.resource);
    } else if (!selectedEvent) {
      setEvent(null);
    }
  }, [selectedEvent, selectedAppointmentData, selectedEventData, event]);

  return (
    !!event && (
      <Modal
        open={selectedEvent}
        onCancel={() => {setSelectedEvent(null); dispatch(setSelectedEventData(null)); }}
        footer={null}
        width={'auto'}
        centered
        styles={{
          content: {
            minWidth: 350
          },
          mask: {
            backgroundColor: 'transparent'
          }
        }}
      >
        <>
          {getTime(event)}
          <Title level={5}>
            {event_type === 'appointment'
              ? (event?.title || getServices(event.services))
              : selectedEvent.title}
          </Title>
          <Row>
            <Col style={{ marginRight: 30, maxWidth: '50%' }}>
              {event.doctor?.first_name && (
                <Row align='middle' className='event-modal-text-row'>
                  <Col className='event-modal-text-row__img-cont'>
                    <img src={doctorIcon} />
                  </Col>
                  <Col>
                    {event.doctor?.first_name} {event.doctor?.last_name}
                  </Col>
                </Row>
              )}
              {!!(event.participants?.length || event.attendees?.length) && (
                <Row align='middle' className='event-modal-text-row'>
                  <Col className='event-modal-text-row__img-cont'>
                    <img src={doctorsIcon} />
                  </Col>
                  <Col style={{ maxWidth: 'calc(100% - 26px)' }}>
                    {event_type === 'appointment'
                      ? getParticipants(event.participants)
                      : getAtendees(event.attendees)}
                  </Col>
                </Row>
              )}
              {event.location?.details !== 'unavailable' && (
                <Row align='middle' className='event-modal-text-row'>
                  <Col className='event-modal-text-row__img-cont'>
                    <img src={locationIcon} />
                  </Col>
                  <Col>{event.location?.details}</Col>
                </Row>
              )}
            </Col>
            <Col>
              {event.client && (
                <Row align='middle' className='event-modal-text-row'>
                  <Col className='event-modal-text-row__img-cont'>
                    <img src={personIcon} />
                  </Col>
                  <Col>
                    {`${event.client?.name} (+${event.client?.country_code}${event.client?.phone_number})`}
                  </Col>
                </Row>
              )}
              {event.patients?.length && (
                <Row align='middle' className='event-modal-text-row'>
                  <Col className='event-modal-text-row__img-cont'>
                    <img src={crossPawIcon} />
                  </Col>
                  <Col>
                    <Typography>{getPatients(event.patients)}</Typography>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {event_type === 'appointment' && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
              <Button
                type='primary'
                onClick={() =>
                  navigate(`/clinic/${clinic_id}/appointments/${selectedAppointmentData.id}`)
                }
                style={{ borderRadius: 30, whiteSpace: 'normal', minWidth: '80%' }}
              >
                {locale.buttons.viewAppointment}
                <span className='icofont icofont-external-link icon-after-btn' />
              </Button>
            </div>
          )}
              {event_type === 'event' && selectedEvent?.resource?.title !== 'Busy' && showEventButtons && (
                <div className='event-modal-buttons'>
                  <Tooltip title={selectedEventData?.organiser_id !== user.id && locale.messages.eventCantBeEdited} trigger={'hover'} style={{display:'flex'}}>
                    <Row>
                    <Button onClick={onEditEventClick} type='primary' className={'inventory-update-buttons'}
                      disabled={selectedEventData?.organiser_id !== user.id}
                      shape={'circle'}>
                      <span className='icofont icofont-edit-alt' />
                    </Button>
                    <Button onClick={() => setShowCalcelEventModal(true)} danger type='primary' className={'inventory-update-buttons'}
                      disabled={selectedEventData?.organiser_id !== user.id}
                      shape={'circle'}>
                      <span className='icofont icofont-ban' />
                    </Button>
                    </Row>
                    </Tooltip>
                </div>
          )}
        </>
      </Modal>
    )
  );
};
