import { IAppointment, IAppointmentsList } from '../../interfaces/appointment';
import { IBillDetails } from '../../services/clinic/bills/models/IBillDetails';

export const GET_APPOINTMENTS = '[appointments] Set';
export const SHOW_MODAL = '[appointments] Add';
export const SET_APPOINTMENTS_LOADING = '[appointments] Edit';
export const SET_APPOINTMENT = '[appointments] Select';
export const SET_APPOINTMENT_STATUS = '[appointments] SET_STATUS';
export const SET_APPOINTMENT_BILL = '[appointments] SET_BILL';
export const SET_APPOINTMENT_PATIENTS = 'SET_APPOINTMENT_PATIENTS';
export const SET_APPOINTMENT_PATIENT_HEALTH_RECORDS = 'SET_APPOINTMENT_PATIENT_HEALTH_RECORDS';
export const SET_APPOINTMENT_HEALTH_RECORDS = 'SET_APPOINTMENT_HEALTH_RECORDS';
export const SET_INITIAL_DATA_FOR_APPOINTMENT = 'SET_INITIAL_DATA_FOR_APPOINTMENT';
export const SHOW_PATIENT_MODAL = 'SHOW_PATIENT_MODAL';
export const SHOW_BILL_MODAL = '[appointments] SHOW_BILL_MODAL';
export const SHOW_PAYMENT_MODAL = '[appointments] SHOW_PAYMENT_MODAL';
export const SHOW_EDIT_APPOINTMENT_MODAL = 'SHOW_EDIT_APPOINTMENT_MODAL';
export const SET_APPOINTMENT_HEALTH_RECORD_INVENTORY = 'SET_APPOINTMENT_HEALTH_RECORD_INVENTORY';
export const SET_SELECTED_PATIENT_HEALTH_RECORDS = 'SET_SELECTED_PATIENT_HEALTH_RECORDS';
export const RESET = '[appointments] RESET';

export interface AppointmentsResetAction {
  type: typeof RESET;
}

export interface GetAppointmentsAction {
  type: typeof GET_APPOINTMENTS;
  payload: IAppointmentsList;
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: boolean;
}

export interface ShowEditAppointmentModalAction {
  type: typeof SHOW_EDIT_APPOINTMENT_MODAL;
  payload: boolean;
}

export interface ShowPatientModalAction {
  type: typeof SHOW_PATIENT_MODAL;
  payload: boolean;
}

export interface ShowBillModalAction {
  type: typeof SHOW_BILL_MODAL;
  payload: boolean;
}

export interface ShowPaymentModalAction {
  type: typeof SHOW_PAYMENT_MODAL;
  payload: boolean;
}

export interface SetLoadingAction {
  type: typeof SET_APPOINTMENTS_LOADING;
  payload: boolean;
}

export interface SetSelectedAppointment {
  type: typeof SET_APPOINTMENT;
  payload: IAppointment | null;
}

export interface SetAppointmentStatus {
  type: typeof SET_APPOINTMENT_STATUS;
  payload: string | null;
}

export interface SetSelectedAppointmentPatients {
  type: typeof SET_APPOINTMENT_PATIENTS;
  payload: IAppointment | null;
}

export interface SetSelectedAppointmentHealthRecords {
  type: typeof SET_APPOINTMENT_HEALTH_RECORDS;
  payload: IAppointment | null;
}

export interface SetSelectedAppointmentPatientHealthRecords {
  type: typeof SET_APPOINTMENT_PATIENT_HEALTH_RECORDS;
  payload: IAppointment | null;
}

export interface SetInitialDataForAppointment {
  type: typeof SET_INITIAL_DATA_FOR_APPOINTMENT;
  payload: IAppointment | null;
}

export interface SetSelectedAppointmentHealthRecordInventory {
  type: typeof SET_APPOINTMENT_HEALTH_RECORD_INVENTORY;
  payload: IAppointment | null;
}

export interface setSelectedPatientHealsRecords {
  type: typeof SET_SELECTED_PATIENT_HEALTH_RECORDS;
  payload: IAppointment | null;
}

export interface SetAppointmentBill {
  type: typeof SET_APPOINTMENT_BILL;
  payload: IBillDetails | null;
}

export type AppointmentsActions =
  | AppointmentsResetAction
  | GetAppointmentsAction
  | SetAppointmentStatus
  | ShowModalAction
  | ShowBillModalAction
  | ShowPaymentModalAction
  | SetLoadingAction
  | SetSelectedAppointment
  | SetAppointmentBill
  | SetInitialDataForAppointment
  | SetSelectedAppointmentPatients
  | SetSelectedAppointmentPatientHealthRecords
  | SetSelectedAppointmentHealthRecords
  | ShowPatientModalAction
  | ShowEditAppointmentModalAction
  | SetSelectedAppointmentHealthRecordInventory
  | setSelectedPatientHealsRecords;
