import { useI18n } from 'hooks/usei18n';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
import { useCallback } from 'react';
import { ISelectedAuditItem } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';
import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';

type IResidual = {
  getResidual: (residual) => string;
  unitOfMeasurement: string;
  packagingFormat: string;
  noPackageFormat: boolean;
};

export const useGetResidual = (selectedStock: ISelectedStock | ISelectedAuditItem): IResidual => {
  const unitOfMeasurement = useI18n(measurementsLocale)[selectedStock?.unit_of_measurement] || '';
  const packagingFormat = useI18n(packagesLocale)[selectedStock?.packaging_format] || '';
  const noPackageFormat = selectedStock?.packaging_format === 'none';

  const getResidual = useCallback(
    (residual: number): string => {
      const packagesResidual = Math.floor(residual / selectedStock.quantity_per_package) || 0;
      const unitsResidual = residual % selectedStock.quantity_per_package || 0;

      if (noPackageFormat) {
        return `${residual} ${unitOfMeasurement}`;
      }

      if ((unitsResidual && !packagesResidual) || !residual) {
        return `${unitsResidual} ${unitOfMeasurement}`;
      }

      if (packagesResidual && !unitsResidual) {
        return `${packagesResidual} ${packagingFormat}`;
      }

      return `${packagesResidual} ${packagingFormat} ${unitsResidual} ${unitOfMeasurement}`;
    },
    [selectedStock]
  );

  return {
    getResidual,
    unitOfMeasurement,
    packagingFormat,
    noPackageFormat
  };
};
