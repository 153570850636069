import { Form, Input, Select, Spin } from 'antd';
import { FormInstance } from 'antd/lib';
import DateRangePicker from 'components/DateRangePicker';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { IEvent } from 'interfaces/event';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editEvent } from 'redux/events/actions';

const { TextArea } = Input;
const { Option } = Select;

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  initialValues: IEvent
  callbackFunc: () => void
  form: FormInstance
  children: React.ReactNode
}

export const EditEventForm = ({ form, initialValues, children, callbackFunc }: Props) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const locale = useLocale('private.appointments.event'); 

  const events = useSelector<IAppState, any>((state) => state.events);
  const employees = useSelector<IAppState, any>((state) => state.employees.data);
  const rooms = useSelector<IAppState, any>((state) => state.rooms.data);

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);
  const [locationType, setLocationType] = useState<string>(initialValues?.location?.type);
  const [selectedAttendees, setSelectedAttendees] = useState([]);

  useEffect(() => {
    form.resetFields();
    if (initialValues?.start_time) {
      const eventDuration = dayjs(
        dayjs(initialValues.end_time).diff(dayjs(initialValues.start_time)) - 10800000
      );
      setStartDate(dayjs(initialValues?.start_time));
      setEndDate(dayjs(initialValues?.end_time));
      setDuration(dayjs(eventDuration));
      form.setFieldValue('date_from', dayjs(initialValues?.start_time));
      form.setFieldValue('time_from', dayjs(initialValues?.start_time));
      form.setFieldValue('end_time', dayjs(initialValues?.end_time));
      form.setFieldValue('duration', dayjs(eventDuration));
    }
    setSelectedAttendees(initialValues.attendees.map(el => el.user_id));
    setLocationType(initialValues?.location?.type);

  }, [initialValues]);

  const onSubmit = (data: any): void => {
    const dateTimeFormat: string = 'DD.MM.YYYYTHH:mm';
    data.start_time = startDate.tz('utc').format(dateTimeFormat);
    data.end_time = endDate.tz('utc').format(dateTimeFormat);
    delete data.date_from;
    delete data.time_from;
    delete data.duration;
    dispatch(editEvent(data, initialValues.id, callbackFunc));
  };

  const onLocationTypeSelect = (value: any) => {
    setLocationType(value);
  };

  const locations = useLocalizedList(locationTypes);

  const attendeesList = employees.map((item) => {
    return (
        <Option key={item.full_name} title={item.full_name} value={item.id}>
          {item.full_name}
        </Option>
    );
  });

  const roomsList = rooms
  .map((item) => {
    return (
      <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
        {item.name}
      </Option>
    );
  });

  return (
    <>
      <Spin spinning={events.loading}>
        <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form} initialValues={initialValues}>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <Form.Item name='clinic_id' initialValue={clinicId} hidden>
                <Input />
              </Form.Item> 
              <Form.Item
                label={locale.labels.title}
                name='title'
                rules={[
                  {
                    pattern:
                      /^[\u0041-\u005A\u0061-\u007A\u0400-\u04FF\u0080-\u00FF\u0100-\u024F\s'\\.,;:!?&%$#@()*+=\-_ʼ"`~^0-9]{1,100}$/,
                    message: locale.messages.titleNotValid
                  },
                  {
                    required: true,
                    message: locale.messages.mandatoryField
                  }
                ]}
              >
                <Input hidden={true} />
              </Form.Item> 
              <Form.Item
              className='add-appointment-form__multi-select-field'
              label={locale.labels.attendees}
              name='attendees'
            >
              <Select
                className='attendees-select'
                mode='multiple'
                value={selectedAttendees}
                onSelect={(value) => setSelectedAttendees([...selectedAttendees, value])}
                filterOption={(input, option) => {
                  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                showSearch
              >
                {attendeesList}
              </Select>
            </Form.Item> 
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                duration={duration}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDuration={setDuration}
              />
              <Form.Item label={''}>
                <Form.Item
                  className='add-equipment-form__single-select-field'
                  name={['location', 'type']}
                  label={locale.labels.location}
                  initialValue={'room'}
                  rules={[
                    {
                      required: true,
                      message: locale.errors.enterLocationType
                    }
                  ]}
                >
                  <Select
                    filterOption={false}
                    onSelect={onLocationTypeSelect}
                    options={locations}
                  />
                </Form.Item>
                <Form.Item
                  name={['location', 'details']}
                  label={locale.labels.details}
                  hidden={locationType !== 'other'}
                  rules={[
                    {
                      required: locationType === 'other',
                      message: locale.errors.enterTheLocation
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['location', 'id']}
                  className='add-equipment-form__single-select-field'
                  hidden={locationType !== 'room'}
                  label={locale.labels.room}
                  rules={[
                    {
                      required: locationType === 'room',
                      message: locale.errors.enterRoom
                    }
                  ]}
                >
                       <Select
                  filterOption={(input, option) => {
                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                >
                  {roomsList}
                </Select>
                </Form.Item>
              </Form.Item>
            </div>
            <div className='col-md-6 col-sm-12'>
              <Form.Item label={locale.labels.notes} name='notes'>
                <TextArea rows={5} />
              </Form.Item>
            </div>
          </div>
          {children}
        </Form>
      </Spin>
    </>
  );
}; 
