import { Form, Input, Select, Spin } from 'antd';
import DateRangePicker from 'components/DateRangePicker';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from 'redux/employees/actions';
import { createEvent } from 'redux/events/actions';
import { getRooms } from '../../../redux/rooms/actions';
import { RoomsSelect } from '../../components/roomsSelect/RoomsSelect';
import { EmployeesSelect } from '../../components/employeesSelect/EmployeesSelect';
import { LocalStorageFilters } from '../../../pages/calendar/types.ts';

const { TextArea } = Input;

dayjs.extend(utc);
dayjs.extend(timezone);

const EventForm = ({ eventType, form, initialValues, children, locale }: any) => {
  const dispatch = useDispatch();
  const events = useSelector<IAppState, any>((state) => state.events);
  const userId = useSelector(({ user }: IAppState) => user.id);
  const clinicId = useClinicId();
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);
  const [locationType, setLocationType] = useState<string>('room');

  useEffect(() => {
    if (!events.backendError) {
      form.resetFields();
    }
  }, [events.backendError]);

  useEffect(() => {
    form.resetFields();
    dispatch(getRooms(clinicId, 0, [{ name: 'deleted', value: 'false' }]));
    dispatch(getEmployees(clinicId, 0));
    if (initialValues?.start_time) {
      setStartDate(dayjs(initialValues?.start_time));
      setEndDate(dayjs(initialValues?.end_time));
      setDuration(dayjs(initialValues?.duration));
      form.setFieldValue('date_from', dayjs(initialValues?.start_time));
      form.setFieldValue('time_from', dayjs(initialValues?.start_time));
      form.setFieldValue('end_time', dayjs(initialValues?.end_time));
      form.setFieldValue('duration', dayjs(initialValues?.duration));
    } else {
      form.setFieldValue('date_from', dayjs(new Date()));
      form.setFieldValue('time_from', dayjs(new Date()));
    }
  }, [initialValues]);

  const onSubmit = (data: any): void => {
    const dateTimeFormat: string = 'DD.MM.YYYYTHH:mm';
    data.start_time = startDate.tz('utc').format(dateTimeFormat);
    data.end_time = endDate.tz('utc').format(dateTimeFormat);
    delete data.date_from;
    delete data.time_from;
    delete data.duration;
    const calendarFilters: LocalStorageFilters =
      JSON.parse(localStorage.getItem('eventCalendarFilters'));
    const searchFilters = calendarFilters[clinicId]?.filters || [];
    dispatch(createEvent(data, clinicId, searchFilters));
  };

  const onLocationTypeSelect = (value: any) => {
    setLocationType(value);
  };

  const locations = useLocalizedList(locationTypes);

  return (
    <>
      <Spin spinning={events.loading}>
        <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <Form.Item name='clinic_id' initialValue={clinicId} hidden>
                <Input />
              </Form.Item>
              <Form.Item name='event_type' initialValue={eventType} hidden>
                <Input />
              </Form.Item>
              <Form.Item
                label={locale.labels.title}
                name='title'
                rules={[
                  {
                    required: true,
                    pattern:
                      /^[\u0041-\u005A\u0061-\u007A\u0400-\u04FF\u0080-\u00FF\u0100-\u024F\s'\\.,;:!?&%$#@()*+=\-_ʼ"`~^0-9]{1,100}$/,
                    message: locale.messages.titleNotValid
                  },
                  {
                    required: true,
                    message: locale.messages.mandatoryField
                  }
                ]}
              >
                <Input hidden={true} />
              </Form.Item>
              <Form.Item label={locale.labels.attendees} name='attendees'>
                <EmployeesSelect selectedDoctorId={userId}/>
              </Form.Item>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                duration={duration}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDuration={setDuration}
              />
              <Form.Item label={''}>
                <Form.Item
                  className='add-equipment-form__single-select-field'
                  name={['location', 'type']}
                  label={locale.labels.location}
                  initialValue={'room'}
                  rules={[
                    {
                      required: true,
                      message: locale.errors.enterLocationType
                    }
                  ]}
                >
                  <Select
                    filterOption={false}
                    onSelect={onLocationTypeSelect}
                    options={locations}
                  />
                </Form.Item>
                <Form.Item
                  name={['location', 'details']}
                  label={locale.labels.details}
                  hidden={locationType !== 'other'}
                  rules={[
                    {
                      required: locationType === 'other',
                      message: locale.errors.enterTheLocation
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['location', 'id']}
                  className='add-equipment-form__single-select-field'
                  hidden={locationType !== 'room'}
                  label={locale.labels.room}
                  rules={[
                    {
                      required: locationType === 'room',
                      message: locale.errors.enterRoom
                    }
                  ]}
                >
                  <RoomsSelect />
                </Form.Item>
              </Form.Item>
            </div>
            <div className='col-md-6 col-sm-12'>
              <Form.Item label={locale.labels.notes} name='notes'>
                <TextArea rows={5} />
              </Form.Item>
            </div>
          </div>
          {children}
        </Form>
      </Spin>
    </>
  );
};

export default EventForm;
