import { ILocale } from 'interfaces/locale';

interface LocalizedBillTypes {
  name: string;
  value: string;
  label: string;
}

export const getPaymentName = (record: any, locale: ILocale, localizedBillTypes: LocalizedBillTypes[]): string => {
  const subType = record.sub_type;
  const localizedType = localizedBillTypes.find((el) => el.value === subType)?.label;
  const clientDetails = record.client
    ? `: ${locale.labels.client} - ${record.client.name ? record.client.name :
      record.client.first_name + ' ' + record.client.last_name} (+${record.client.country_code ? 
      record.client.country_code : ''}${record.client.phone_number})`
    : '';

  return subType === 'standalone' ? record.name : `${localizedType} ${record.id}${clientDetails}`;
};
