import 'components/scss/HealthRecordsHistory.scss';
import 'moment/locale/uk';
import '../scss/StockDetails.scss';

import { Spin, Table, TableColumnProps } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getStockItemsById, setSelectedStockItems } from 'redux/inventory/stock/actions';
import { ISelectedStockItem } from 'services/interfaces/clinics/inventory/stock/ISelectedStockItem';

import getExpirationStatus from '../helpers/ExpirationTagSelector';

type Props = {
  locale: ILocale;
  stockId: number;
};

export const StockHistoryTab = ({ locale, stockId }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const currencies = useLocale('private.currencies').labels;

  const selectedStockItems = useSelector(({ stock }: IAppState) => stock.selectedStockItems);
  const loading = useSelector(({ stock }: IAppState) => stock.stockItemsLoading);

  useEffect(() => {
    dispatch(getStockItemsById(clinicId, stockId, 0));

    return () => {
      dispatch(setSelectedStockItems(null));
    };
  }, []);

  const getExpirationDate = (e: ISelectedStockItem): JSX.Element | '-' => {
    return e.expiration_date ? getExpirationStatus(e.expiration_date) : '-';
  };

  const columns: TableColumnProps<ISelectedStockItem>[] = [
    {
      key: 'invoice_number',
      title: locale.labels.invoiceNumber,
      render: (e) =>
        e.invoice_id
          ? ListFieldWithTooltip(
              <Link
                className={'custom-links'}
                to={`/clinic/${clinicId}/inventory/invoices/${e.invoice_id}`}
              >
                {e.invoice_number}
              </Link>
            )
          : '-'
    },
    {
      key: 'purchase_date',
      title: locale.labels.purchaseDate,
      render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
    },
    {
      key: 'series',
      title: locale.labels.series,
      render: (e) => e.series || '-'
    },
    {
      key: 'expiration_date',
      title: locale.labels.expirationDate,
      render: getExpirationDate
    },
    {
      key: 'purchase_price',
      title: locale.labels.pricePerUnit,
      render: (e) => `${e.price_per_unit ? `${money(e.price_per_unit)} ${currencies.uah}` : '-'}`
    },
    {
      key: 'initial_quantity',
      title: locale.labels.purchaseQuantity,
      render: (e) => e.initial_quantity || '-'
    },
    {
      key: 'selling_price',
      title: locale.labels.price,
      render: (e) => `${e.selling_price ? `${money(e.selling_price)} ${currencies.uah}` : '-'}`
    }
  ];

  return (
    <div className='stock-row-container__table'>
      <Spin spinning={loading}>
        {Boolean(selectedStockItems?.data) && (
          <Table
            pagination={false}
            className='accent-header stock-list'
            rowKey='id'
            dataSource={selectedStockItems.data.reverse()}
            columns={columns}
          />
        )}
      </Spin>
    </div>
  );
};
