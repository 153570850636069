import './scss/BillPrint.scss';

import dayjs from 'dayjs';
import { IAppState } from 'interfaces/app-state';
import { IPatient } from 'interfaces/patient';
import { getAnimalAge } from 'pages/patients/helpers/PatientHelper';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { localizedPetData } from 'utils/localized-pet-data';
import { parseDiagnosis } from 'utils/string-from-entities-list';

import { useLocale } from '../../../../hooks/useLocale';

interface Props {
    patient: IPatient
}

export const PatientPrintInfo = ({ patient }: Props): JSX.Element => {
    const eventLocale = useLocale('private.appointments.event');
    const locale = useLocale('private.appointments.appointment-bill')?.labels;
    const patientLocale = useLocale('private.patients.patient-page');
    const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);

    const getPatientSex = (sex: string) => {
        if (sex === 'male') return '♂';
        if (sex === 'female') return '♀';
        return '';
    };

    const getPatientDetails = (): ReactNode => {
        const { name, sex, birth_date } = patient;
        const patName = name;
        const { species, breed } = localizedPetData(patient, localeCode);
        const patSpecies = species ? `, ${species}` : '';
        const patBreed = breed ?? '';
        const patAge = birth_date ? getAnimalAge(patientLocale, birth_date): '';
        const additionalInfo = [patBreed, getPatientSex(sex), patAge].filter(el => !!el && el !== '');

        return `${patName}${patSpecies} ${additionalInfo.length > 0 ? `(${additionalInfo.join(', ')})` : ''}`;
    };

    return <div className="patient-print-info">
        <div style={{ marginTop: 20 }}>
            <span style={{ fontWeight: '600' }}>{`${locale.patient}: `}</span>
            {getPatientDetails()}
        </div>
        <table>
            <tbody>
                <tr>
                    <th>{eventLocale.labels.diagnosis}</th>
                    <td>{patient?.health_record.diagnosis === '[]' || patient?.health_record.diagnosis === null ?
                        eventLocale.labels.noDiagnosisRequired : parseDiagnosis(patient?.health_record.diagnosis)}</td>
                </tr>
                {patient?.health_record?.provisional_diagnosis !== '[]' && patient?.health_record?.provisional_diagnosis !== null && <tr>
                    <th>{eventLocale.labels.preliminaryDiagnosis}</th>
                    <td>{parseDiagnosis(patient.health_record.provisional_diagnosis)}</td>
                </tr>}
                <tr>
                    <th>{eventLocale.labels.recomendations}</th>
                    <td><div style={{whiteSpace:'break-spaces'}}>{patient?.health_record?.recommendations ?? '-'}</div></td>
                </tr>
                <tr>
                    <th>{eventLocale.labels.returnVisitRecomended}</th>
                    <td>{patient.health_record.date_of_next_visit ?
                        dayjs(patient.health_record.date_of_next_visit).format('DD.MM.YYYY') : eventLocale.labels.notSet}</td>
                </tr>
            </tbody>
        </table>
    </div>;
};