import './Actions.scss';

import React from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from '../../../interfaces/app-state';
import HeaderButtons from './HeaderButtons';
import SettingsDropdown from './SettingsDropdown';

const Actions = () => {
  const loggedIn = useSelector<IAppState, any>((state) => state.user.id);

  return loggedIn ? (
    <>
      <SettingsDropdown />
    </>
  ) : (
    <HeaderButtons containerClass='elem-list hide-on-mobile' />
  );
};

export default Actions;
