import '../scss/StockForm.scss';

import { Button, Col, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
import { IAppState } from 'interfaces/app-state';
import AddDictionary from 'layout/modals/addDictionary/AddDictionary';
import { FORMATS } from 'pages/sales/utils/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/dictionaries/dictionary/actions';
import { indexStockCategories } from 'redux/dictionaries/stock-categories/actions';
import { indexStockSubCategories } from 'redux/dictionaries/stock-sub-categories/actions';
import { indexStockTypes } from 'redux/dictionaries/stock-types/actions';
import { getRooms } from 'redux/rooms/actions';
import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';

const { TextArea } = Input;
const { Option } = Select;

type StockInputProps = {
  name: string;
  label: string;
  requiredMsg?: string;
  max?: number;
};

type Props = React.PropsWithChildren<{
  onFinish: (data) => void;
  form: FormInstance;
  initialValues?: ISelectedStock;
}>;

export const StockItemForm = ({ onFinish, form, initialValues, children }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const locale = useLocale('private.inventory.stock');
  const packages = useI18n(packagesLocale);
  const measurements = useI18n(measurementsLocale);

  const [dictionarySearchValue, setDictionarySearchValue] = useState('');
  const [selectedUnitOfMeasurement, setSelectedUnitOfMeasurement] = useState(null);
  const [selectedPackagingFormat, setSelectedPackagingFormat] = useState(null);
  const [selectedPackagingType, setSelectedPackagingType] = useState(
    initialValues?.packaging_format === 'none' ? FORMATS.unit : FORMATS.package
  );
  const [dictionaryModalType, setDictionaryModalType] = useState('');
  const noPackaging = selectedPackagingType === FORMATS.unit;

  const stockTypes = useSelector(({ stockTypes }: IAppState) => stockTypes.data);
  const clinic = useSelector(({ clinic }: IAppState) => clinic);
  const stockCategories = useSelector(({ stockCategories }: IAppState) => stockCategories.data);
  const stockSubCategories = useSelector(
    ({ stockSubCategories }: IAppState) => stockSubCategories.data
  );
  const packagingFormat = packages[selectedPackagingFormat];
  const unitOfMeasurement = measurements[selectedUnitOfMeasurement];
  const packageAndUnitSelected = packagingFormat && unitOfMeasurement;

  useEffect((): void => {
    dispatch(indexStockCategories(clinicId, 0));
    dispatch(indexStockSubCategories(clinicId, 0));
    dispatch(indexStockTypes(clinicId, 0));
    dispatch(getRooms(clinicId, 0));

    setSelectedPackagingFormat(initialValues?.packaging_format);
  }, []);

  const onCreateCallback = (id: number): void => {
    const dispatchAction = {
      stock_type_id: indexStockTypes,
      stock_category_id: indexStockCategories,
      stock_sub_category_id: indexStockSubCategories
    }[dictionaryModalType];

    dispatch(
      dispatchAction(clinicId, 0, [], () => {
        form.setFieldValue(dictionaryModalType, id);
      })
    );
  };

  const mapInitialValues = () =>
    (!!initialValues && {
      ...initialValues,
      packaging_format:
        initialValues?.packaging_format === 'none' ? null : initialValues?.packaging_format
    }) || {
      markup_package: clinic.markup_package,
      markup_unit: clinic.markup_unit
    };

  const generateOptions = (options): JSX.Element[] =>
    options.map((item) => (
      <Option key={item.id + item.name} title={item.name} value={item.id}>
        {item.name}
      </Option>
    ));

  const filterStockOption = (input: string, option): boolean => {
    return option?.title.toLowerCase().includes(input.toLocaleLowerCase());
  };

  const localizedUsageUnits = Object.keys(measurements).map((key) => (
    <Option key={`status_${key}`} value={key}>
      {measurements[key]}
    </Option>
  ));

  const localizedPackages = Object.keys(packages).map((key) => (
    <Option key={`status_${key}`} value={key}>
      {packages[key]}
    </Option>
  ));

  const selectPackagingFormat = (value: string): void => {
    if (value === 'none') {
      form.setFieldValue('quantity_per_package', 1);
    }
    setSelectedPackagingFormat(value);
  };

  const StockSelect = useCallback(
    ({ label, type, options }): JSX.Element => (
      <Form.Item name={type} label={label}>
        <Select
          showSearch
          onSearch={(val) => {
            setDictionarySearchValue(val);
            if (dictionaryModalType !== type) setDictionaryModalType(type);
          }}
          filterOption={filterStockOption}
          notFoundContent={
            <div
              onClick={() => {
                dispatch(showModal(true));
                setDictionaryModalType(type);
              }}
              id='addNewServiceGroupBtn'
            >
              + {locale.labels.addNewValue}
            </div>
          }
        >
          {generateOptions(options)}
        </Select>
      </Form.Item>
    ),
    [dictionaryModalType]
  );

  const StockInput = useCallback(
    ({ name, label, requiredMsg, max }: StockInputProps): JSX.Element => {
      const required = requiredMsg ? [{ required: true, message: requiredMsg }] : [];
      const rules = [
        ...required,
        {
          max: max || 50,
          message: locale.errors[`enterMaxFieldValueLength_1_${max || 50}`]
        }
      ];

      return (
        <Form.Item name={name} label={label} rules={rules}>
          <Input />
        </Form.Item>
      );
    },
    []
  );

  const TextAreaField = useCallback(
    ({ name }: { name: string }): JSX.Element => (
      <Form.Item
        label={locale.labels[name]}
        name={name}
        rules={[
          {
            max: 300,
            message: locale.errors.enterMaxFieldValueLength_1_300
          }
        ]}
      >
        <TextArea rows={2} />
      </Form.Item>
    ),
    []
  );

  return (
    <Form
      key={'StockForm'}
      onFinish={onFinish}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={mapInitialValues()}
    >
      <Row gutter={[40, 0]} className={'form-row'}>
        <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
          <Form.Item label={<h5>{locale.labels.details}</h5>}>
            <StockInput
              name='name'
              label={locale.labels.name}
              requiredMsg={locale.errors.enterStockName}
              max={100}
            />
            <StockInput
              name='manufacturer'
              label={locale.labels.manufacturer}
              requiredMsg={locale.errors.enterManufacturer}
            />
            <StockInput name='barcode' label={locale.labels.barcode} />
            <StockInput name='sku' label={locale.labels.sku} />
            <StockSelect
              type='stock_type_id'
              label={locale.labels.stockType}
              options={stockTypes}
            />
            <StockSelect
              type='stock_type_id'
              label={locale.labels.category}
              options={stockCategories}
            />
            <StockSelect
              type='stock_sub_category_id'
              label={locale.labels.subCategory}
              options={stockSubCategories}
            />
            <StockInput name='unique_code' label={locale.labels.uniqueCode} />

            <Form.Item label={<h5>{locale.labels.defaultMarkups}</h5>}>
              {!noPackaging && (
                <Form.Item
                  label={locale.labels.perPackage}
                  name='markup_package'
                  rules={[
                    {
                      required: true,
                      message: locale.messages.canNotBeEmpty
                    }
                  ]}
                >
                  <VLXInputNumber placeholder='0' type='number' precision={0} step={1} />
                </Form.Item>
              )}
              <Form.Item
                label={locale.labels.perUnit}
                name='markup_unit'
                rules={[
                  {
                    required: true,
                    message: locale.messages.canNotBeEmpty
                  }
                ]}
              >
                <VLXInputNumber placeholder='0' type='number' precision={0} step={1} />
              </Form.Item>
            </Form.Item>
          </Form.Item>
        </Col>

        {/* SECOND COLUMN */}

        <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
          <Form.Item label={<h5>{locale.labels.packing}</h5>}>
            {!initialValues && (
              <Form.Item label={`${locale.labels.packagingType}`}>
                <p className='packaging-type-hint'>
                  <span className='icofont icofont-info-circle' style={{ paddingRight: 5 }}></span>
                  {locale.messages.packagingTypeHint}
                </p>
                <Button
                  className={`packaging-type-btn left ${noPackaging ? 'active' : ''}`}
                  onClick={() => setSelectedPackagingType(FORMATS.unit)}
                >
                  {locale.labels.pieces}
                </Button>
                <Button
                  className={`packaging-type-btn right ${!noPackaging ? 'active' : ''}`}
                  onClick={() => setSelectedPackagingType(FORMATS.package)}
                >
                  {locale.labels.packaging}
                </Button>
                <p className='packaging-type-hint'>
                  {selectedPackagingType === FORMATS.unit
                    ? locale.messages.unitFormatHint
                    : locale.messages.packagingFormatHint}
                </p>
              </Form.Item>
            )}
            {!noPackaging && (
              <>
                <Form.Item
                  name='packaging_format'
                  label={`${locale.labels.packagingFormat}`}
                  rules={[
                    {
                      required: true,
                      message: locale.errors.enterPackagingFormat
                    },
                    {
                      max: 50,
                      message: locale.errors.enterMaxFieldValueLength_1_50
                    }
                  ]}
                >
                  <Select onChange={selectPackagingFormat} filterOption={false}>
                    {localizedPackages}
                  </Select>
                </Form.Item>
                <Form.Item
                  name='unit_of_measurement'
                  label={locale.labels.usageMeasurement}
                  rules={[
                    {
                      required: true,
                      message: locale.errors.enterUsageMeasurement
                    }
                  ]}
                >
                  <Select
                    onChange={(value) => setSelectedUnitOfMeasurement(value)}
                    filterOption={false}
                  >
                    {localizedUsageUnits}
                  </Select>
                </Form.Item>
                <Form.Item
                  name='quantity_per_package'
                  label={`${locale.labels.quantityPerPackage} (${locale.labels.inMesUn})`}
                  rules={[
                    {
                      required: true,
                      message: locale.errors.enterQuantityPerPackage
                    },
                    {
                      pattern: /^\d{0,10}$/,
                      message: locale.errors.onlyNumberAllowedMax10Chars
                    }
                  ]}
                >
                  <Input
                    disabled={!!initialValues}
                    onKeyPress={(event) => {
                      const fieldValue = form.getFieldValue('usage_step');
                      const regExp = /[.,]/.test(fieldValue) ? /[0-9]/ : /[0-9.,]/;
                      if (!regExp.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              name='reorder_point'
              label={`${locale.labels.reorderPoint} ${
                noPackaging ? '' : `(${locale.labels.inMesUn})`
              }`}
              rules={[
                {
                  pattern: /^\d{0,10}$/,
                  message: locale.errors.onlyNumberAllowedMax10Chars
                }
              ]}
            >
              <Input
                onKeyPress={(event) => {
                  const fieldValue = form.getFieldValue('usage_step');
                  const regExp = /[.,]/.test(fieldValue) ? /[0-9]/ : /[0-9.,]/;
                  if (!regExp.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Form.Item>
          {!initialValues && (
            <Form.Item label={<h5>{locale.labels.initialPricesAndResidual}</h5>}>
              {!noPackaging && (
                <Form.Item
                  name='price_per_package'
                  label={`${locale.labels.price} (${packagingFormat || locale.labels.package})`}
                >
                  <VLXInputNumber
                    placeholder='0'
                    type='number'
                    precision={2}
                    step={1}
                    disabled={!packageAndUnitSelected}
                  />
                </Form.Item>
              )}
              <Form.Item
                name='price_per_unit'
                label={`${locale.labels.price} ${
                  noPackaging ? '' : `(${unitOfMeasurement || locale.labels.units})`
                }`}
              >
                <VLXInputNumber
                  placeholder='0'
                  type='number'
                  precision={2}
                  step={1}
                  disabled={!noPackaging && !packageAndUnitSelected}
                />
              </Form.Item>
              {!noPackaging && (
                <Form.Item
                  name='quantity_in_packages'
                  label={`${locale.labels.quantity} (${packagingFormat || locale.labels.packages})`}
                >
                  <VLXInputNumber
                    placeholder='0'
                    type='number'
                    precision={0}
                    step={1}
                    disabled={!packageAndUnitSelected}
                  />
                </Form.Item>
              )}
              <Form.Item
                name='quantity_in_units'
                label={`${locale.labels.quantity} ${
                  noPackaging
                    ? ''
                    : `(${unitOfMeasurement || locale.labels.units}, ${
                        locale.labels.notIncludedInPackage
                      })`
                }`}
              >
                <VLXInputNumber
                  placeholder='0'
                  type='number'
                  precision={3}
                  step={1}
                  disabled={!noPackaging && !packageAndUnitSelected}
                />
              </Form.Item>
            </Form.Item>
          )}
          <Form.Item label={<h5>{locale.labels.additionalDetails}</h5>}>
            <TextAreaField name='description' />
            <TextAreaField name='instructions' />
            <TextAreaField name='ingredients' />
            <TextAreaField name='notes' />
          </Form.Item>
        </Col>
      </Row>
      {children}
      <AddDictionary
        defaultDictionaryNameValue={dictionarySearchValue}
        popupTitle={locale.labels.addNewValue}
        actionType={dictionaryModalType}
        hideCreateBtn
        callBackAfterCreate={({ id }) => onCreateCallback(id)}
      />
    </Form>
  );
};
