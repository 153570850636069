import './AppointmentDetails.scss';

import { Col, Collapse, Menu, MenuProps, Row, Spin, Tag, Tooltip, Typography } from 'antd';
import checkedIcon from 'assets/img/checked.svg';
import warningIcon from 'assets/img/warning.svg';
import DocumentsTab from 'components/DocumentsTab';
import HealthRecordsHistory from 'components/HealthRecordsHistory';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import { IPatient } from 'interfaces/patient';
import { IService } from 'interfaces/services';
import AddAppointmentPatient from 'layout/modals/addAppointmentPatient/AddAppointmentPatient';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAppointmentBill,
  getAppointmentDetails,
  getAppointmentDetailsPatients,
  showPatientModal
} from 'redux/appointments/actions';
import { getClientDetails } from 'redux/clients/actions';
import { getPatientDocumentsList, mergePatientDocumentsList } from 'redux/patients/actions';
import { getServices } from 'redux/services/actions';
import { getOpenedShift } from 'redux/shifts/actions';

import ClientDetailsPetsList from '../clients/ClientDetailsPetsList';
import ClientDetailsPetsTab from '../clients/ClientDetailsPetsTab';
import AppointmentDetailsPatientTab from './AppointmentDetailsPatientTab';
import AppointmentDetailsTopContainer from './AppointmentDetailsTopContainer';
import AppointmentNewServicesContainer from './AppointmentServices/AppointmentServicesContainer';

const AppointmentDetails = (): JSX.Element => {
  const params = useParams() as any;
  const locale = useLocale('private.appointments.event');
  const currencies = useLocale('private.currencies').labels;
  const editAccess = useUserAccess().accesses.appointments.edit;
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const patientIdSearchParam = useLinkParams('patientId');
  const activeTabSearchParam = useLinkParams('tab');

  const appointment = useSelector(
    ({ appointments }: IAppState) => appointments.selectedAppointment
  );
  const appointmentBill = useSelector(
    ({ appointments }: IAppState) => appointments.appointmentBill
  );
  const appointmentLoading = useSelector(({ appointments }: IAppState) => appointments.loading);
  const appointmentPatients = useSelector(
    ({ appointments }: IAppState) => appointments.selectedAppointmentPatients?.data
  );
  const appointmentClient = useSelector(({ clients }: IAppState) => clients.selectedClient);
  const [servicesStockStatus, setServicesStockStatus] = useState([]);

  const selectedPatientDocuments = useSelector(
    ({ patients }: IAppState) => patients.selectedPatientDocuments
  );

  const { openedShift } = useSelector(({ shifts }: IAppState) => shifts) || {};

  const [selectedPatientServices, setSelectedPatientServices] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [currentTab, setCurrentTab] = useState('card');
  const [currentPatient, setCurrentPatient] = useState(null);
  const [patientHealthRecord, setPatientHealthRecord] = useState(null);
  const [selectedPatientData, setSelectedPatientData] = useState(null);

  useEffect((): void => {
    dispatch(getOpenedShift(clinicId));
  }, [clinicId]);

  useEffect((): void => {
    if (clinicId) {
      dispatch(getAppointmentDetails(clinicId, params.appointmentId));
      dispatch(getAppointmentDetailsPatients(clinicId, params.appointmentId));
      dispatch(getServices(clinicId, 0));
      dispatch(getAppointmentBill(clinicId, params.appointmentId));
    }
  }, [clinicId, params.appointmentId]);

  useEffect((): void => {
    const statuses = selectedPatientServices?.map((el) => ({
      id: el?.id,
      status: el?.stock_saved
    }));

    setServicesStockStatus(statuses);
  }, [selectedPatientServices]);

  useEffect(() => {
    changeSelectedPatient(currentPatient);
  }, [patientIdSearchParam, appointmentPatients]);

  useEffect(() => {
    if (appointment?.client?.id && appointmentClient?.id !== appointment?.client?.id) {
      dispatch(getClientDetails(clinicId, appointment?.client?.id));
    }
  }, [appointment]);

  useEffect(() => {
    if (activeTabSearchParam) {
      setCurrentTab(activeTabSearchParam);
    }
  }, [activeTabSearchParam]);

  const getDetailsTabIcon = (): JSX.Element => {
    const checked = selectedPatientHasHealthRecord();
    return (
      <Tooltip trigger={'hover'} placement='topLeft' title={locale.labels.mandatoryToEnter}>
        <img
          style={{ height: 20, width: 20, verticalAlign: -4 }}
          src={checked ? checkedIcon : warningIcon}
        />
      </Tooltip>
    );
  };

  const getStockTabIcon = (): JSX.Element => {
    let checked = false;
    if (servicesStockStatus?.length) {
      if (servicesStockStatus.some((service) => !service.status)) {
        checked = false;
      } else {
        checked = true;
      }
    }
    return (
      <Tooltip trigger={'hover'} placement='topLeft' title={locale.labels.mandatoryToEnter}>
        <img
          style={{ height: 20, width: 20, verticalAlign: -4 }}
          src={checked ? checkedIcon : warningIcon}
        />
      </Tooltip>
    );
  };

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.card,
      key: 'card'
    },
    {
      label: locale.tabs.details,
      key: 'details',
      icon: getDetailsTabIcon()
    },
    {
      label: locale.tabs.servicesAndStock,
      key: 'stock',
      icon: getStockTabIcon()
    },
    {
      label: locale.tabs.documents,
      key: 'documents'
    },
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  function changeSelectedPatient(patientId) {
    if (appointmentPatients && !!appointmentPatients.length) {
      if (patientId) {
        const patient = appointmentPatients.find((el) => el?.id === Number(patientId));
        if (patient) {
          onSelectedPatientChange(patient);
          return;
        }
      } else if (patientIdSearchParam) {
        const patient = appointmentPatients.find((el) => el?.id === Number(patientIdSearchParam));
        if (patient) {
          onSelectedPatientChange(patient);
          return;
        }
      }
      const patient = appointmentPatients[0];
      onSelectPatient(patient);
      onSelectedPatientChange(patient);
    }
  }

  function disableFields(): boolean {
    if (!appointmentBill) return false;
    return (
      appointmentBill.payment_status === 'paid' ||
      appointmentBill.payment_status === 'partially_paid' ||
      appointment.status === 'finished' ||
      appointment.status === 'cancelled' ||
      appointment.status === 'future'
    );
  }

  function disablePatientsListModifications(): boolean {
    if (!appointmentBill) return false;
    return appointment.status === 'finished' || appointment.status === 'cancelled';
  }

  function disableEditAppointmentDetails(): boolean {
    if (!appointmentBill) return false;
    return appointment.status === 'finished' || appointment.status === 'cancelled';
  }

  function onSelectedPatientChange(patient): void {
    setSelectedPatient(patient);
    setPatientHealthRecord(patient?.health_record);
    setSelectedPatientData(patient);
    setSelectedPatientServices(patient?.health_record?.services);
    dispatch(getPatientDocumentsList(clinicId, patient?.id));
  }

  const changeTab: MenuProps['onClick'] = (e): void => {
    pushHistoryState(currentPatient, e.key);
    setCurrentTab(e.key);

    if (e.key === 'details') {
      dispatch(getAppointmentDetailsPatients(clinicId, params.appointmentId));
    }
  };

  function pushHistoryState(patientId: number, tab: string): void {
    if (window.history.pushState) {
      const newUrl = `${window.location.pathname}?patientId=${patientId}&tab=${tab}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }

  const addNewPetAction = (): void => {
    dispatch(showPatientModal(true));
  };

  const onSelectPatient = (pet: IPatient): void => {
    pushHistoryState(pet.id, 'card');
    setCurrentTab('card');
    setCurrentPatient(pet.id);
    changeSelectedPatient(pet.id);
  };

  function saveStatusTag(serviceId: number): JSX.Element {
    const isServicesStockStatusSaved = servicesStockStatus?.find(
      (el) => el.id === serviceId
    )?.status;

    return (
      <Tag
        id='bill-status-type'
        color={isServicesStockStatusSaved ? 'green' : 'red'}
        style={{
          marginLeft: 5,
          fontWeight: 'bold'
        }}
      >
        {isServicesStockStatusSaved
          ? locale.labels.savedStockChanges
          : locale.labels.unsavedStockChanges}
      </Tag>
    );
  }

  const collapseHeader = (service: IService): JSX.Element => (
    <Row gutter={[24, 12]} justify={'start'} className={'collapse-header-row'}>
      <Col md={9} lg={9} xl={9}>
        <Typography.Title level={5}>{service?.name}</Typography.Title>
      </Col>
      <Col md={6} lg={6} xl={6}>
        {saveStatusTag(service?.id)}
      </Col>
      <Col xs={0} sm={0} md={1} lg={2} xl={2} />
      <Col md={8} lg={7} xl={7} style={{ textAlign: 'right' }}>
        <Typography.Title level={5}>{calculateFullPrice(service)}</Typography.Title>
      </Col>
    </Row>
  );

  const setDocumentsList = (data): void => {
    dispatch(mergePatientDocumentsList(data));
  };

  function calculateFullPrice(service): string {
    const startingPrice = service?.total_price;
    let stockPrice = 0;
    service?.stock.forEach((item) => {
      stockPrice = stockPrice + (item.price || 0);
    });

    const price = (Number(startingPrice) + Number(stockPrice)).toFixed(2);
    return `${currencies.totalPrice}: ${price} ${currencies.uah}`;
  }

  function selectedPatientHasHealthRecord(): boolean {
    const healthRec = appointmentPatients?.find(
      (el) => el.id === selectedPatient?.id
    )?.health_record;

    return !!healthRec?.complaints;
  }

  return (
    <Spin spinning={!appointment} className='appointment-details-page-spin-container'>
      {appointment && (
        <>
          <AppointmentDetailsTopContainer
            appointment={appointment}
            clinicId={clinicId}
            locale={locale}
            currencies={currencies}
            appointmentClient={appointmentClient}
            dispatch={dispatch}
            appointmentBill={appointmentBill}
            appointmentPatients={appointmentPatients}
            servicesStockStatus={servicesStockStatus}
            disableAppointmentEdit={disableEditAppointmentDetails()}
            openedShift={openedShift}
            appointmentLoading={appointmentLoading}
          />
          <Col
            span={24}
            style={{
              padding: '0 12px',
              marginTop: 20
            }}
          >
            <Col className='ant-card-bordered personal-info-card'>
              {' '}
              {appointmentPatients && (
                <Row>
                  <ClientDetailsPetsList
                    appointment_id={appointment.id}
                    clientPatients={appointmentPatients}
                    selectedPatient={selectedPatient}
                    lgRowWidth={6}
                    setSelectedPatient={onSelectPatient}
                    addNewPetAction={addNewPetAction}
                    addMarkIcon
                    bill={appointmentBill}
                    disabled={disablePatientsListModifications()}
                    showDeletePatientIcon={appointmentPatients?.length > 1}
                  />
                  <Col span={24} lg={18} className='client-details__patient-details'>
                    <Menu
                      onClick={changeTab}
                      selectedKeys={[currentTab]}
                      mode='horizontal'
                      items={items}
                      style={{ marginBottom: 20 }}
                    />
                    {currentTab === 'card' && !!selectedPatientData && (
                      <ClientDetailsPetsTab
                        key={'clients tab'}
                        accessGroup={editAccess}
                        clinicId={clinicId}
                        data={selectedPatientData}
                      />
                    )}
                    {currentTab === 'details' && (
                      <AppointmentDetailsPatientTab
                        key={'details tab'}
                        locale={locale}
                        onlyServices={false}
                        appointment={appointment}
                        patientId={selectedPatientData?.id}
                        initialValues={patientHealthRecord}
                        disabled={false}
                      />
                    )}
                    {currentTab === 'stock' && (
                      <Spin spinning={selectedPatientServices === null}>
                        <React.Fragment key={'clients tab'}>
                          <AppointmentDetailsPatientTab
                            key={'details tab'}
                            locale={locale}
                            onlyServices
                            appointment={appointment}
                            patientId={selectedPatientData?.id}
                            initialValues={patientHealthRecord}
                            disabled={disableFields()}
                          />
                          {selectedPatientServices?.length > 0 &&
                            <Collapse
                              accordion
                              className='appointment-items-collapse-header'
                              size='large'
                              style={{ marginTop: 20 }}
                              items={selectedPatientServices.map((el) => ({
                                label: collapseHeader(el),
                                key: el?.id,
                                children: (
                                  <AppointmentNewServicesContainer
                                    disabled={disableFields()}
                                    key={el?.id}
                                    clinicId={clinicId}
                                    serviceData={el}
                                    servicesStockStatus={servicesStockStatus}
                                    setServicesStockStatus={setServicesStockStatus}
                                    stockData={el?.stock}
                                    appointmentId={appointment?.id}
                                    healthRecordId={patientHealthRecord.id}
                                  />
                                )
                              }))}
                            ></Collapse>}
                        </React.Fragment>
                      </Spin>
                    )}
                    {currentTab === 'documents' && (
                      <DocumentsTab
                        uploadDocumentLink={`/v1/clinics/${clinicId}/patients/${selectedPatientData?.id}/documents`}
                        setDocumentsList={setDocumentsList}
                        data={selectedPatientDocuments}
                      />
                    )}
                    {currentTab === 'history' && !!selectedPatient && (
                      <HealthRecordsHistory
                        key={'history tab'}
                        clinicId={clinicId}
                        selectedPatientId={selectedPatient.id}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Col>
          <AddAppointmentPatient
            clientId={appointment?.client?.id}
            appointmentId={appointment?.id}
            appointmentPatients={appointmentPatients}
          />
        </>
      )}
    </Spin>
  );
};
export default AppointmentDetails;
