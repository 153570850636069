import { IPatient } from 'interfaces/patient';

export const SET_PATIENTS = '[patients] Set';
export const ADD_PATIENT = '[patients] Add';
export const EDIT_PATIENT = '[patients] Edit';
export const DELETE_PATIENT = '[patients] Delete';
export const SET_SPECIES_AND_BREEDS = '[speciesAndBreeds] Set';
export const SET_PATIENTS_LOADING = 'SET_PATIENTS_LOADING';
export const SET_PATIENT = 'SET_PATIENT';
export const SHOW_CREATE_MODAL = 'SHOW_CREATE_MODAL';
export const SHOW_UPDATE_MODAL = 'SHOW_UPDATE_MODAL';
export const SET_PATIENT_VACCINATIONS = 'SET_PATIENT_VACCINATIONS';
export const SET_PATIENT_SELECTED_VACCINATIONS = 'SET_PATIENT_SELECTED_VACCINATIONS';
export const SET_PATIENT_DOCUMENTS_LIST = 'SET_PATIENT_DOCUMENTS_LIST';
export const MERGE_PATIENT_DOCUMENTS_LIST = 'MERGE_PATIENT_DOCUMENTS_LIST';
export const SET_PATIENT_ALL_VACCINATIONS_DATA = 'SET_PATIENT_ALL_VACCINATIONS_DATA';
export const RESET = '[patients] RESET';

export interface PatientsResetAction {
  type: typeof RESET;
}

export interface SetPatientAction {
  type: typeof SET_PATIENTS;
  payload: IPatient[];
}

export interface AddPatientAction {
  type: typeof ADD_PATIENT;
  payload: IPatient;
}

export interface SetLoadingAction {
  type: typeof SET_PATIENTS_LOADING;
  payload: boolean;
}

export interface EditPatientAction {
  type: typeof EDIT_PATIENT;
  payload: IPatient;
}

export interface DeletePatientAction {
  type: typeof DELETE_PATIENT;
  id: string;
}

export interface SetSelectedPatientAction {
  type: typeof SET_PATIENT;
  payload: IPatient | null;
}

export interface ShowUpdateModalAction {
  type: typeof SHOW_UPDATE_MODAL;
  payload: boolean;
}

export interface ShowCreateModalAction {
  type: typeof SHOW_CREATE_MODAL;
  payload: boolean;
}

export interface SetSelectedPatientVaccinationAction {
  type: typeof SET_PATIENT_VACCINATIONS;
  payload: IPatient | null;
}

export interface SetSelectedPatientSelectedVaccinationAction {
  type: typeof SET_PATIENT_SELECTED_VACCINATIONS;
  payload: IPatient | null;
}

export interface SetSelectedPatientDocumentsListAction {
  type: typeof SET_PATIENT_DOCUMENTS_LIST;
  payload: any;
}

export interface MergePatientDocumentsListAction {
  type: typeof MERGE_PATIENT_DOCUMENTS_LIST;
  payload: any;
}

export interface SetSelectedPatientAllVaccinationDataAction {
  type: typeof SET_PATIENT_ALL_VACCINATIONS_DATA;
  payload: IPatient | null;
}

export type PatientsActions =
  | SetPatientAction
  | PatientsResetAction
  | AddPatientAction
  | DeletePatientAction
  | EditPatientAction
  | SetLoadingAction
  | SetSelectedPatientAction
  | ShowCreateModalAction
  | ShowUpdateModalAction
  | SetSelectedPatientVaccinationAction
  | SetSelectedPatientSelectedVaccinationAction
  | SetSelectedPatientDocumentsListAction
  | MergePatientDocumentsListAction
  | SetSelectedPatientAllVaccinationDataAction;
