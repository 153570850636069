import { Button, Col, DatePicker, Form, Input, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import * as localStorage from 'local-storage';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MultiSkillsSelect from '../../components/MultiSkillsSelect';
import VLXModal from '../../components/VLXModal';
import { useLocale } from '../../hooks/useLocale';
import { IAppState } from '../../interfaces/app-state';
import { editUser, showModal } from '../../redux/user/actions';

const EditUserData = () => {
  const dispatch = useDispatch();
  const user = useSelector<IAppState, any>((state) => state.user);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editProfileForm] = Form.useForm();
  const locale = useLocale('private.user.user-profile');
  const messages = locale.messages;

  const onSubmit = (values: any) => {
    values.skills = selectedItems;
    dispatch(editUser(values));
  };

  return (
    <>
      <Button
        className='edit-button'
        type='primary'
        block
        onClick={() => dispatch(showModal(true))}
      >
        {locale.buttons.editProfile}
      </Button>
      <VLXModal
        form={editProfileForm}
        title={locale.labels.editProfileTitle}
        open={user.showModal}
        onModalClose={() => dispatch(showModal(false))}
        width={800}
      >
        <Spin spinning={user.loading}>
          <Form
            form={editProfileForm}
            onFinish={onSubmit}
            autoComplete='off'
            layout='vertical'
            initialValues={{
              ...user,
              birth_date: dayjs(user.birth_date)
            }}
          >
            <Row gutter={[20, 0]}>
              <Col span={24} md={12}>
                <Form.Item
                  label={locale.labels.firstName}
                  name='first_name'
                  rules={[
                    { required: true, message: locale.labels.emptyFirstName },
                    {
                      pattern:
                        /^[\u0041-\u005A\u0061-\u007A\u0400-\u04FF\u0080-\u00FF\u0100-\u024F\s'\\.\-_ʼ`]{1,100}$/,
                      message: messages.nameNotValid
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={locale.labels.lastName}
                  name='last_name'
                  rules={[
                    { required: true, message: locale.labels.emptyLastName },
                    {
                      pattern:
                        /^[\u0041-\u005A\u0061-\u007A\u0400-\u04FF\u0080-\u00FF\u0100-\u024F\s'\\.\-_ʼ`]{1,100}$/,
                      message: messages.nameNotValid
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={locale.labels.dateOfBirth}
                  name='birth_date'
                  rules={[{ required: true, message: locale.labels.emptyDateOfBirth }]}
                >
                  <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
                </Form.Item>
                <Form.Item label={locale.labels.skills} name='skills'>
                  <MultiSkillsSelect
                    user={user}
                    editMode={true}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={locale.labels.address}
                  style={{ marginBottom: 0 }}
                  required={true}
                >
                  <Form.Item
                    name='country'
                    style={{
                      display: 'inline-block',
                      width: 'calc(50% - 8px)',
                      marginRight: '16px'
                    }}
                    rules={[
                      {
                        required: true,
                        message: messages.countryEmpty
                      }
                    ]}
                  >
                    <Input placeholder={locale.placeholders.country} />
                  </Form.Item>
                  <Form.Item
                    name='region'
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    rules={[
                      {
                        required: true,
                        message: messages.regionEmpty
                      }
                    ]}
                  >
                    <Input placeholder={locale.placeholders.region} />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  name='city'
                  rules={[
                    {
                      required: true,
                      message: messages.cityEmpty
                    }
                  ]}
                >
                  <Input placeholder={locale.placeholders.city} />
                </Form.Item>
                <Form.Item
                  name='address'
                  rules={[
                    {
                      required: true,
                      message: messages.addressEmpty
                    }
                  ]}
                >
                  <Input placeholder={locale.placeholders.address} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item hidden={true} name='locale' initialValue={localStorage.get('locale')}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type='primary' block htmlType='submit'>
                {locale.buttons.submit}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </VLXModal>
    </>
  );
};

export default EditUserData;
