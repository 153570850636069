import './SalesDetails.scss';

import { Card, Col, Row } from 'antd';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { sales } from 'i18n/pages/sales';
import { IAppState } from 'interfaces/app-state';
import { ISaleStockItem } from 'interfaces/sales';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSaleDetails, setSalesDetails } from 'redux/sales/actions';
import { getOpenedShift } from 'redux/shifts/actions';

import { IClient } from '../../interfaces/client.ts';
import { SaleDetailsOverview } from './components/SaleDetailsOverview';
import { SaleItemModal } from './components/SaleItemModal';
import { SaleItemsPreviewTable } from './components/SaleItemsPreviewTable';

export const SalesDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const { shopFarmacyId } = useParams();
  const locale = useI18n<typeof sales['ukUA']>(sales);

  const saleDetails = useSelector(({ sales }: IAppState) => sales.saleDetails);
  const [saleEditable, setSaleEditable] = useState<boolean>();
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [fieldsTouched, setFieldsTouched] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<{ item: ISaleStockItem; index: number }>();
  const [showSaleItemModal, setShowSaleItemModal] = useState(false);

  useEffect(() => {
    if (clinicId && shopFarmacyId) {
      dispatch(getSaleDetails(+clinicId, +shopFarmacyId));
    }
  }, []);

  useEffect(() => {
    if (clinicId) {
      dispatch(getOpenedShift(clinicId));
    }

    return () => {
      dispatch(setSalesDetails(null));
    };
  }, [clinicId]);

  const editItem = (item: ISaleStockItem, index: number): void => {
    setItemToEdit({ item, index });
    setShowSaleItemModal(true);
  };

  return (
    <>
      <Row className='sale-form__container'>
        <Col span={24} xl={8} className='sale-form__container__left-side'>
          <Card className='ant-card-bordered personal-info-card with-shadow'>
            <SaleDetailsOverview
              saleDetails={saleDetails}
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
              locale={locale}
              setSaleEditable={setSaleEditable}
              saleEditable={saleEditable}
              setFieldsTouched={setFieldsTouched}
              fieldsTouched={fieldsTouched}
            />
          </Card>
        </Col>
        <Col span={24} xl={16} className='sale-form__container__right-side'>
          {saleDetails && (
            <SaleItemsPreviewTable
              saleDetails={saleDetails}
              editItem={editItem}
              locale={locale}
              saleEditable={saleEditable}
            />
          )}
          {(saleEditable || !saleDetails) && (
            <SaleItemModal
              locale={locale}
              selectedClient={selectedClient}
              saleDetails={saleDetails}
              setFieldsTouched={setFieldsTouched}
            />
          )}
        </Col>
      </Row>
      {showSaleItemModal && (
        <SaleItemModal
          setShowSaleItemModal={setShowSaleItemModal}
          locale={locale}
          selectedClient={selectedClient}
          itemToEdit={itemToEdit}
          saleDetails={saleDetails}
          setFieldsTouched={setFieldsTouched}
        />
      )}
    </>
  );
};
