import './AddService.scss';

import { AutoComplete, Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import { formatFloat } from 'helpers/NumbersFormatter';
import useClinicId from 'hooks/useClinicId';
import { measurementsLocale } from 'i18n/measurements';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/dictionaries/dictionary/actions';
import { indexServiceGroups } from 'redux/dictionaries/service-groups/actions';
import { getEquipment } from 'redux/inventory/equipment/actions';
import { getStock } from 'redux/inventory/stock/actions';
import { getTools } from 'redux/inventory/tools/actions';
import { createService, updateService } from 'redux/services/actions';

import AddDictionary from '../addDictionary/AddDictionary';

const { Option } = Select;

const AddServiceForm = ({
  form,
  initialValues,
  setEditMode = null,
  formEditMode,
  children,
  onFieldsChange,
  locale,
  callBackAfterCreate
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const [selectedHours, setSelectedHours] = useState(null);
  const [selectedMinutes, setSelectedMinutes] = useState(null);
  const [duration, setDuration] = useState(null);
  const stock = useSelector<IAppState, any>((state) => state.stock.data);
  const equipment = useSelector<IAppState, any>((state) => state.equipment.data);
  const tools = useSelector<IAppState, any>((state) => state.tools.data);
  const commonState = useSelector<IAppState, any>((state) => state.common);
  const serviceGroups = useSelector<IAppState, any>((state) => state.serviceGroups.data);
  const serviceLoading = useSelector<IAppState, any>((state) => state.serviceGroups.loading);
  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [minutesDropdownOpen, setMinutesDropdownOpen] = useState(false);
  const [groupSearchValue, setGroupSearchValue] = useState('');

  useEffect(() => {
    if (!commonState.backendError) {
      if (setEditMode != null) setEditMode(false);
      form.resetFields();
      dispatch(indexServiceGroups(clinicId, 0));
    }

    if (clinicId) {
      dispatch(getStock(clinicId, 0));
      dispatch(getEquipment(clinicId, 0));
      dispatch(getTools(clinicId, 0));
    }
  }, []);

  useEffect(() => {
    if (!commonState.backendError) {
      if (setEditMode != null) setEditMode(false);
      form.resetFields();
      if (initialValues?.duration) {
        const val = initialValues.duration;
        const minutes = val % 60;
        const hours = (val - minutes) / 60;
        setSelectedHours(hours.toString());
        setSelectedMinutes(minutes.toString());
      }
      if (initialValues?.stock) {
        form.setFieldValue('stock', initialValues?.stock);
      }
    }
  }, [initialValues]);

  const onSubmit = (data: any) => {
    const apiData = data;
    if (data?.tools?.length) {
      apiData.tools = data?.tools.map((el) => {
        return { id: el };
      });
    }
    if (data?.equipment?.length) {
      apiData.equipment = data?.equipment.map((el) => {
        return { id: el };
      });
    }
    apiData.duration = duration;
    apiData.stock = data.stock.filter((el) => el.id);
    apiData.stock = apiData.stock.map((el) => {
      return { ...el, quantity: Number(formatFloat(el.quantity)) };
    });
    if (formEditMode) {
      apiData.price = Number(formatFloat(apiData?.price)).toFixed(2);
      dispatch(updateService(clinicId, apiData, initialValues.id));
    } else {
      dispatch(createService(clinicId, apiData, callBackAfterCreate));
    }
  };

  useEffect(() => {
    setDuration(Number(selectedHours) * 60 + Number(selectedMinutes));
  }, [selectedHours, selectedMinutes]);

  const getServicesList = () => {
    const addedStockItems = form.getFieldValue('stock');
    return stock.map((item) => {
      return (
        <Option
          className='services-stock-list-select'
          disabled={addedStockItems.some((el) => el?.id === item?.id)}
          key={item.name}
          title={`${item.name} (${item.manufacturer})`}
          value={item.id}
        >
          {' '}
          {`${item.name}(${item.manufacturer})`}
        </Option>
      );
    });
  };

  const checkIfSomeStockItemsAvaliable = () => {
    const addedStockItems = form.getFieldValue('stock').filter((el) => el?.id);
    if (addedStockItems.length === stock.length) {
      return true;
    }
    return false;
  };

  const equipmentList = equipment.map((item) => {
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item.name}
      </Option>
    );
  });

  const toolsList = tools.map((item) => {
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item.name}
      </Option>
    );
  });

  const getStockMeasureLabel = (key: number): string => {
    const stockValues = form.getFieldValue('stock');
    if (stockValues[key]?.id) {
      let res = stock.find((el) => el.id === stockValues[key].id);
      return measurementsLocale[localeCode][res?.unit_of_measurement];
    }
    return '';
  };

  const getInitValues = () => {
    const data = { ...initialValues };
    if (initialValues?.tools?.length) {
      data.tools = initialValues.tools.map((el) => el.id);
    }
    if (initialValues?.equipment?.length) {
      data.equipment = initialValues.equipment.map((el) => el.id);
    }
    delete data.stock;
    return data;
  };

  const autocompleteHours = () => {
    return Array.from({ length: 25 }, (_, index) => ({
      value: index.toString(),
      label: index
    }));
  };

  const autocompleteMinutes = () => {
    return Array.from({ length: 12 }, (_, index) => ({
      value: (index * 5).toString()
    }));
  };

  const serviceGroupsOptions = serviceGroups.map((item) => {
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item.name}
      </Option>
    );
  });

  const filterGroupOption = (input: string, option) => {
    if (option?.title.toLowerCase().includes(input.toLocaleLowerCase())) {
      return true;
    }
    return false;
  };

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={getInitValues()}
    >
      <Form.Item name='clinic_id' hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.code}
            name={'code'}
            rules={[
              {
                max: 100,
                message: locale.messages.enterCode
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.serviseName}
            name={'name'}
            rules={[
              {
                required: true,
                min: 1,
                max: 100,
                message: locale.messages.enterServiceName
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.serviseGroup}
            name={'service_group_id'}
            rules={[
              {
                required: true,
                message: locale.messages.enterServiceGroup
              }
            ]}
          >
            <Select
              showSearch
              virtual={false}
              onSearch={(val) => setGroupSearchValue(val)}
              filterOption={filterGroupOption}
              notFoundContent={
                <div onClick={() => dispatch(showModal(true))} id='addNewServiceGroupBtn'>
                  + {locale.labels.addServiceGroup}
                </div>
              }
            >
              {serviceGroupsOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.price}
            rules={[{ required: true, message: locale.messages.enterServicePrice }]}
            name='price'
          >
            <VLXInputNumber placeholder='0' type='number' precision={2} step={0.1} />
          </Form.Item>
          <p style={{ fontWeight: '600', marginBottom: 8 }}>{locale.labels.duration}</p>
          <Row
            gutter={8}
            style={{
              width: '100%',
              alignItems: 'center',
              marginBottom: 20
            }}
          >
            <Col span={5}>
              <AutoComplete
                id='duration-hours'
                options={autocompleteHours()}
                filterOption={(inputValue, option) =>
                  option.value.toString().toUpperCase().includes(inputValue.toUpperCase())
                }
                onSelect={() => {
                  setDropdownOpen(false);
                }}
                value={selectedHours}
                allowClear
                placeholder='0'
                showSearch
                onChange={(e) => {
                  setSelectedHours(e);
                  formEditMode && onFieldsChange([{ e }]);
                }}
                open={dropdownOpen}
                onSearch={(value) => {
                  setDropdownOpen(true);
                }}
                onBlur={() => {
                  setDropdownOpen(false);
                }}
                onFocus={() => {
                  setDropdownOpen(true);
                }}
              />
            </Col>
            <Col span={5}>{locale.labels.hours}</Col>
            <Col span={5}>
              <AutoComplete
                id='duration-minutes'
                options={autocompleteMinutes()}
                filterOption={(inputValue, option) =>
                  option.value.toString().toUpperCase().includes(inputValue.toUpperCase())
                }
                onSelect={() => {
                  setMinutesDropdownOpen(false);
                }}
                value={selectedMinutes}
                allowClear
                placeholder='0'
                showSearch
                onChange={(e) => {
                  setSelectedMinutes(e);
                  formEditMode && onFieldsChange([{ e }]);
                }}
                open={minutesDropdownOpen}
                onSearch={(value) => {
                  setMinutesDropdownOpen(true);
                }}
                onBlur={() => {
                  setMinutesDropdownOpen(false);
                }}
                onFocus={() => {
                  setMinutesDropdownOpen(true);
                }}
              />
            </Col>
            <Col span={5}>{locale.labels.minutes}</Col>
          </Row>
          <Form.Item label={locale.labels.description} name='description'>
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name='stock'
        initialValue={[{ id: '', quantity: '', included_in_price: false }]}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={<h5>{locale.labels.itemsAndDrugs}</h5>}
        className='add-service-form-stock-container'
      >
        <Form.List name='stock'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} className='add-service-form-stock-container__row'>
                  <Col span={24} md={10}>
                    <Form.Item
                      {...restField}
                      className='add-service-form-stock__name long-value-form-item'
                      label={locale.labels.name}
                      name={[name, 'id']}
                    >
                      <Select
                        virtual={false}
                        filterOption={(input, option) => {
                          return option?.title
                            .toString()
                            .toLowerCase()
                            .includes(input?.toLowerCase());
                        }}
                        showSearch
                      >
                        {getServicesList()}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={4} className='measurement-quantity-container'>
                    <Form.Item
                      {...restField}
                      name={[name, 'quantity']}
                      label={locale.labels.quantity}
                    >
                      <VLXInputNumber placeholder='0' type='number' precision={3} step={0.01} />
                    </Form.Item>
                    <span className='measurement-label'>{getStockMeasureLabel(key)}</span>
                  </Col>
                  <Col className='included-in-price-container'>
                    <Form.Item
                      {...restField}
                      name={[name, 'included_in_price']}
                      valuePropName='checked'
                      initialValue={
                        initialValues?.included_in_price ? initialValues.included_in_price : true
                      }
                    >
                      <Checkbox defaultChecked>{locale.labels.includedInPrice}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={1} md={1}>
                    <Button
                      onClick={() => {
                        remove(name);
                        formEditMode && onFieldsChange([{ deleteAction: 'deleteAction' }]);
                      }}
                      danger
                      type='primary'
                      shape={'circle'}
                      className={'inventory-update-buttons'}
                    >
                      <span className='icofont icofont-trash' />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Row>
                <Form.Item>
                  <Button
                    id='add-more-stock'
                    className='icofont icofont-plus'
                    type={'primary'}
                    disabled={checkIfSomeStockItemsAvaliable()}
                    style={{ borderRadius: 30 }}
                    onClick={() => add()}
                  >
                    {initialValues && form.getFieldValue('stock').length
                      ? locale.buttons.addMore
                      : locale.buttons.add}
                  </Button>
                </Form.Item>
              </Row>
            </>
          )}
        </Form.List>
      </Form.Item>
      <>
        <Col span={24} md={12}>
          <Form.Item
            className='add-equipment-form__multi-select-field'
            label={<h5>{locale.labels.equipment}</h5>}
            name='equipment'
          >
            <Select
              mode='multiple'
              filterOption={(input, option) => {
                return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
              }}
              showSearch
            >
              {equipmentList}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            className='add-tools-form__multi-select-field'
            label={<h5>{locale.labels.tools}</h5>}
            name='tools'
          >
            <Select
              mode='multiple'
              filterOption={(input, option) => {
                return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
              }}
              showSearch
            >
              {toolsList}
            </Select>
          </Form.Item>
        </Col>
      </>
      {children}
      <AddDictionary
        defaultDictionaryNameValue={groupSearchValue}
        popupTitle={locale.labels.addServiceGroup}
        actionType={'service_group'}
        dataLoading={serviceLoading}
        hideCreateBtn
        callBackAfterCreate={(data) => {
          dispatch(
            indexServiceGroups(clinicId, 0, [], () => {
              form.setFieldValue('service_group_id', data?.id);
              if (formEditMode) onFieldsChange([{ service_group_id: data?.id }]);
            })
          );
        }}
      />
    </Form>
  );
};

export default AddServiceForm;
