import './scss/StockList.scss';

import { notification, Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import StickyButton from 'components/StickyButton';
import VLXCompositeSearchWithSelect from 'components/VLXCompositeSearchWithSelect';
import { stockStatuses as stockStatusesWithFilters } from 'constants/dictionary/default/selectOptions';
import { scrollToTop } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useUserAccess } from 'hooks/useUserAccess';
import { measurementsLocale } from 'i18n/measurements';
import { stockStatuses } from 'i18n/stockStatuses';
import { IAppState } from 'interfaces/app-state';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getClinic } from 'redux/clinic/actions';
import {
  getStock,
  resetStock,
  setSelectedStock,
  setSelectedStockItems,
  showCreateModal
} from 'redux/inventory/stock/actions';
import { getClinicStockItemsById } from 'services/clinic/inventory/stockService';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';

import getExpirationStatus from './helpers/ExpirationTagSelector';
import getStatus from './helpers/TagSelector';
import { CreateStockItemModal } from './modals/CreateStockItemModal';

const getExpirationDate = (item: IStockItem): '-' | JSX.Element =>
  item.expiration_date ? getExpirationStatus(item.expiration_date) : '-';

const StockList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const { accesses } = useUserAccess();
  const navigate = useNavigate();
  const locale = useLocale('private.inventory.stock');
  const currencies = useLocale('private.currencies').labels;
  const measurements = useI18n(measurementsLocale);
  const statuses = useI18n(stockStatuses);
  const localizedStockStatuses = useLocalizedList(stockStatusesWithFilters);

  const [searchItemsArray, setSearchItemsArray] = useState([]);
  const [expanded, setExpended] = useState([]);
  const [expandedData, setExpandedData] = useState([]);
  const [expandableLoading, setExpandableLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const loading = useSelector(({ stock }: IAppState) => stock.loading);
  const showModal = useSelector(({ stock }: IAppState) => stock.showCreateModal);
  const { data, metadata } = useSelector(({ stock }: IAppState) => stock);

  const currentPage = searchParams.get('page');
  const sort_key = searchParams.get('sort_key');
  const sort_order = searchParams.get('sort_order');

  useEffect((): void => {
    if (searchParams.get('page') !== null) {
      dispatch(
        getStock(clinicId, +currentPage, searchItemsArray, 20, {
          sort_key,
          sort_order
        })
      );
    }
  }, [searchParams, searchItemsArray]);

  useEffect(() => {
    dispatch(setSelectedStock(null));
    dispatch(getClinic(clinicId));

    setSearchParams({
      page: currentPage ?? '1',
      sort_key: sort_key ?? 'name',
      sort_order: sort_order ?? 'asc'
    });

    return () => {
      dispatch(resetStock());
    };
  }, []);

  const redirectToStockDetails = (record: IStockItem): { onClick: () => void } => {
    if (accesses.stock.showItem) {
      return {
        onClick: () => {
          navigate(`/clinic/${clinicId}/inventory/stock/${record.id}`);
        }
      };
    }
  };

  const columns = useMemo(
    (): TableColumnProps<IStockItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => e.name || '-'
      },
      {
        key: 'manufacturer',
        title: locale.labels.manufacturer,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => (e.manufacturer ? ListFieldWithTooltip(e.manufacturer, 15) : '-')
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => {
          if (!e?.status) return '-';
          return getStatus({ value: e.status, label: statuses[e.status] });
        }
      },
      {
        key: 'sku',
        title: locale.labels.sku,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => e.sku || '-'
      },
      {
        key: 'barcode',
        title: locale.labels.barcode,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => e.barcode || '-'
      },
      {
        key: 'quantity',
        title: locale.labels.remainingQuantity,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) =>
          e.quantity
            ? `${e.quantity} (${e.remaining_capacity} ${measurements[e.unit_of_measurement]})`
            : '-'
      },
      {
        key: 'type',
        title: locale.labels.stockType,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => (e.type ? ListFieldWithTooltip(e.type, 15) : '-')
      },
      {
        key: 'category',
        title: locale.labels.category,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => (e.category ? ListFieldWithTooltip(e.category, 15) : '-')
      },
      {
        key: 'sub_category',
        title: locale.labels.subCategory,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => (e.sub_category ? ListFieldWithTooltip(e.sub_category, 15) : '-')
      },
      {
        key: 'ingredients',
        title: locale.labels.ingredients,
        sorter: () => null,
        onCell: redirectToStockDetails,
        render: (e) => (e.ingredients ? ListFieldWithTooltip(e.ingredients, 15) : '-')
      }
    ],
    [data]
  );

  const expandedColumns = useMemo(
    (): TableColumnProps<IStockItem>[] => [
      {
        key: 'invoice_number',
        title: locale.labels.invoiceNumber,
        render: (e) =>
          e.invoice_id
            ? ListFieldWithTooltip(
                <Link
                  className={'custom-links'}
                  to={`/clinic/${clinicId}/inventory/invoices/${e.invoice_id}`}
                >
                  {e.invoice_number}
                </Link>
              )
            : '-'
      },
      {
        key: 'purchase_date',
        title: locale.labels.purchaseDate,
        render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
      },
      {
        key: 'series',
        title: locale.labels.series,
        render: (e) => e.series || '-'
      },
      {
        key: 'expiration_date',
        title: locale.labels.expirationDate,
        render: getExpirationDate
      },
      {
        key: 'quantity',
        title: locale.labels.remainingQuantity,
        render: (e) => e.quantity || '-'
      },
      {
        key: 'purchase_price',
        title: locale.labels.purchasePrice,
        render: (e) => `${e.price_per_unit ? `${e.price_per_unit} ${currencies.uah}` : '-'}`
      },
      {
        key: 'selling_price',
        title: locale.labels.price,
        render: (e) => `${e.selling_price ? `${e.selling_price} ${currencies.uah}` : '-'}`
      },
      {
        key: 'location',
        title: locale.labels.location,
        render: (e) => (e?.location ? ListFieldWithTooltip(e.location?.details) : '-')
      }
    ],
    [expandedData]
  );

  const handleChange = (pagination, filters, sorter): void => {
    let order = '';
    if (sorter.order) {
      order = sorter.order === 'ascend' ? 'asc' : 'desc';
    }
    searchParams.set('sort_key', sorter.columnKey);
    searchParams.set('sort_order', order);
    setSearchParams(searchParams);
  };

  const onPageChange = (page: number): void => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
    scrollToTop();
  };

  const expandedRowRender = (): JSX.Element => (
    <Table
      loading={expandableLoading}
      columns={expandedColumns}
      dataSource={expandedData.filter((item) => !item.deleted)}
      pagination={false}
      size='small'
      className='stock-items-table'
      bordered
    />
  );

  return (
    <>
      <VLXCompositeSearchWithSelect
        setSearchItemsArray={setSearchItemsArray}
        selectOptions={localizedStockStatuses}
        selectPlaceholder={locale.labels.filterByStatus}
        savedFiltersName={'stockListFilters'}
        sort_key={sort_key}
        sort_order={sort_order}
        currentPage={currentPage}
        hasSortingParams
      />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header stock-list'
          rowKey='id'
          dataSource={data}
          onChange={handleChange}
          columns={columns}
          expandable={{
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandableLoading(true);
                dispatch(setSelectedStockItems(null));
                setExpandedData([]);
                getClinicStockItemsById(clinicId, record.id, 0)
                  .then((data: any) => {
                    setExpandedData(data.data);
                  })
                  .finally(() => setExpandableLoading(false));
              }
              const current = expanded ? [record.id] : [];
              setExpended(current);
            },
            expandedRowKeys: expanded,
            expandedRowRender
          }}
        />
        <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
          <Pagination
            className={'equipment-pagination'}
            defaultCurrent={1}
            current={+currentPage}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            total={metadata.total}
            onChange={onPageChange}
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => dispatch(showCreateModal(true))}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.create}
          />
          {accesses.stock.create && showModal && (
            <CreateStockItemModal
              clinicId={clinicId}
              callbackFn={() => {
                dispatch(
                  getStock(clinicId, +currentPage, searchItemsArray, 20, { sort_key, sort_order })
                );
                notification.success({ message: locale.messages.dataSavedSuccessfully });
              }}
            />
          )}
        </Row>
      </Spin>
    </>
  );
};

export default StockList;
