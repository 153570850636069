import './Footer.scss';

import React from 'react';

interface Props {
  openModal: () => void;
}

const FooterItems = ({ openModal }: Props): JSX.Element => {
  return (
    <>
      <span>Version 1.3.0</span>
      <button className='no-style ml-2' onClick={openModal}>
        <i
          className='icofont icofont-ui-settings'
          style={{ paddingLeft: '5px', fontSize: 26, color: '#0e3048' }}
        />
      </button>
    </>
  );
};

export default FooterItems;
