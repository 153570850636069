import './Vertical.scss';

import { Menu, Select } from 'antd';
import appointmentList from 'assets/img/appointmentList.svg';
import calendar from 'assets/img/calendar.svg';
import clinicDefaultLogo from 'assets/img/clinicDefaultLogo.png';
import crossPaw from 'assets/img/crossPaw.svg';
import dashboard from 'assets/img/dashboard.svg';
import dollarCoin from 'assets/img/dollarCoin.svg';
import inventoryStorage from 'assets/img/inventoryStorage.svg';
import manageAdmin from 'assets/img/manageAdmin.svg';
import people from 'assets/img/people.svg';
import pharmacy from 'assets/img/pharmacy.svg';
import schedule from 'assets/img/schedule.svg';
import syringe from 'assets/img/syringe.svg';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import { IUserAccesses } from 'interfaces/user-accesses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { setSelectedClinicId, toggleSidebar } from 'redux/settings/actions';
import { setUserClinicAccesses } from 'redux/user/actions';

import HeaderButtons from '../components/actions/HeaderButtons';
import Navbar from '../components/navbar/Navbar';

import type { MenuProps } from 'antd';
const { Option } = Select;

const VerticalLayout = (): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.side-menu');
  const [selectedItem, setSelectedItem] = useState([]);
  const [openedItem, setOpenedItem] = useState<number>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userAccess = useUserAccess();

  const selectedClinicId = useSelector(({ settings }: IAppState) => settings.selectedClinicId);
  const user = useSelector(({ user }: IAppState) => user);
  const authorized = user.id;
  const clinics = user.userAccesses;

  useEffect(() => {
    if (clinicId) {
      if (clinics?.some((el) => el.clinicId === clinicId)) {
        setDefaultSelection(clinicId);
        onSelect();
      } else {
        navigate('/');
      }
    } else {
      if (selectedClinicId) {
        setDefaultSelection(selectedClinicId);
      } else if (clinics.length > 0) {
        setDefaultSelection(clinics[0].clinicId);
        dispatch(setSelectedClinicId(clinics[0].clinicId));
      }
    }
  }, [clinicId, location, clinics, selectedClinicId]);

  useEffect(() => {
    dispatch(setUserClinicAccesses(userAccess));
  }, [clinicId, clinics]);

  const onClinicSelect = (id: number): void => {
    dispatch({ type: 'RESET_STORE' });
    navigate(`/clinic/${id}/administration/info`);
    setOpenedItem(id);
  };

  const clinicsList = clinics.map((item: IUserAccesses) => (
    <Option
      className='clinic-select-option'
      key={`${item.clinicName}${item.clinicId}`}
      title={item.clinicName}
      value={item.clinicId}
    >
      <div className='clinic-select-option__logo-container'>
        <img src={item?.clinicAvatar ? item?.clinicAvatar : clinicDefaultLogo} />
      </div>
      <div className='clinic-select-option__name-container'> {item.clinicName}</div>
    </Option>
  ));

  const clinicItems = useCallback(
    (clinicId: number) => {
      if (!openedItem || !clinics.length) return [];
      return [
        {
          text: locale.labels.administration,
          icon: <img src={manageAdmin} style={{ width: 17, height: 17, marginTop: -1 }} />,
          route: `/clinic/${clinicId}/administration/info`
        },
        {
          text: locale.labels.calendar,
          icon: <img src={calendar} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/events-calendar`
        },
        {
          text: locale.labels.appointments,
          icon: <img src={appointmentList} style={{ width: 18, height: 18, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/appointments`
        },
        {
          text: locale.labels.clients,
          icon: <img src={people} style={{ width: 18.3, height: 18, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/clients`
        },
        {
          text: locale.labels.patients,
          icon: <img src={crossPaw} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/patients`,
          disableOnSelect: true
        },
        {
          text: locale.labels.services,
          icon: <img src={syringe} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/services`
        },
        {
          text: locale.labels.inventory,
          icon: <img src={inventoryStorage} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/inventory/info`
        },
        {
          text: locale.labels.finances,
          icon: <img src={dollarCoin} style={{ width: 18, height: 19, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/finances/info`
        },
        {
          text: locale.labels.shopFarmacy,
          icon: <img src={pharmacy} style={{ width: 18.3, height: 18, marginTop: -2 }} />,
          route: `/clinic/${clinicId}/shop-farmacy`
        }
      ];
    },
    [openedItem, clinicId, locale, clinics]
  );

  const menuItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'personal',
        type: 'group' as 'group',
        label: <span className='vertical-menu__personal-title'>{locale.labels.personal}</span>,
        children: [
          {
            label: (
              <NavLink onClick={() => dispatch(toggleSidebar())} to='/dashboard'>
                {locale.labels.dashboard}
              </NavLink>
            ),
            icon: <img src={dashboard} style={{ width: 15, height: 15, marginTop: -1 }} />,
            key: '/dashboard'
          },
          {
            label: (
              <NavLink onClick={() => dispatch(toggleSidebar())} to='/my-calendar'>
                {locale.labels.myCalendar}
              </NavLink>
            ),
            key: '/my-calendar',
            icon: <img src={schedule} style={{ width: 18, height: 19, marginTop: -2 }} />
          }
        ]
      },
      {
        key: 'clinics',
        type: 'group' as 'group',
        label: (
          <div className='vertical-menu-clinic-select'>
            <span className='vertical-menu__clinics-title'>{locale.labels.clinics}</span>
            <Select
              className='clinic-select'
              onSelect={onClinicSelect}
              value={openedItem ? Number(openedItem) : null}
              placeholder={locale.placeholders.chooseClinic}
            >
              {clinicsList}
            </Select>
          </div>
        ),
        children: clinicItems(openedItem).map((e) => ({
          key: e.route,
          label: (
            <NavLink onClick={() => dispatch(toggleSidebar())} to={e.route}>
              {e.text}
            </NavLink>
          ),
          icon: e.icon
        }))
      }
    ],
    [openedItem, locale, clinics]
  );

  const setDefaultSelection = (clinicId: number): void => {
    setOpenedItem(clinicId);
    if (location.pathname === '/dashboard' || location.pathname === '/my-calendar') {
      setSelectedItem([location.pathname]);
    } else {
      setSelectedItem([clinicId, `/clinic/${clinicId}/administration/info`]);
    }
  };

  const onSelect = (): void => {
    let routerPath = '';
    if (location.pathname.includes('/')) {
      const pathName = location.pathname.split('/')[3];
      routerPath = `/clinic/${clinicId}/${pathName}${
        ['administration', 'inventory', 'finances'].includes(pathName) ? '/info' : ''
      }`;
    }
    setSelectedItem([clinicId?.toString(), routerPath]);
  };

  return (
    <div className={!authorized ? 'hide-on-hd' : ''}>
      <Navbar>
        {authorized && (
          <Menu
            className={'menu-opened vertical-menu'}
            openKeys={[String(openedItem)]}
            selectedKeys={selectedItem}
            mode='inline'
            onSelect={() => {
              if (location.pathname === '/dashboard' || location.pathname === 'my-calendar') {
                setSelectedItem([location.pathname]);
              } else {
                onSelect();
              }
            }}
            items={menuItems}
          />
        )}
        {!clinics && authorized && (
          <NavLink to='clinic-registration' className='ant-btn ant-btn-primary ant-btn-block'>
            <span>{locale.labels.createClinic}</span>
          </NavLink>
        )}
        {!authorized && (<HeaderButtons containerClass='elem-list elem-list-mobile' />)}
        {/* {user?.id && */}
        {/* <Anchor affix={false}>
            <Link href='/#tn' title="Basic demo" />
          </Anchor> */}
        {/* } */}
      </Navbar>
    </div>
  );
};

export default VerticalLayout;
