import './App.scss';

import { ConfigProvider, ConfigProviderProps } from 'antd';
import enGB from 'antd/locale/en_GB';
import ukUA from 'antd/locale/uk_UA';
import dayjs from 'dayjs';
import { IUserAccesses } from 'interfaces/user-accesses';
// import dayjs from 'dayjs';
import * as localStorage from 'local-storage';
import { ProviderDetails } from 'pages/inventory/providers/ProviderDetails';
import { ProvidersList } from 'pages/inventory/providers/ProvidersList';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom';

import Loader from './components/Loader';
import { redirectURL } from './constants/comon';
import { routesConfig } from './constants/routesConfig';
import useClinicId from './hooks/useClinicId';
import { useLocale } from './hooks/useLocale';
import { usePageData } from './hooks/usePage';
import { IPageData } from './interfaces/page';
import BaseLayout from './layout/base/BaseLayout';
import AppointmentDetails from './pages/appointments/AppointmentDetails';
import AppointmentsList from './pages/appointments/AppointmentsList';
import EventsCalendar from './pages/calendar/EventsCalendar';
import UserCalendar from './pages/calendar/UserCalendar';
import ClientDetails from './pages/clients/ClientDetails';
import ClientsList from './pages/clients/ClientsList';
import ClinicActions from './pages/clinic/ClinicActions';
import ClinicDictionaries from './pages/clinic/ClinicDictionaries';
import ClinicDocuments from './pages/clinic/ClinicDocuments';
import ClinicInfo from './pages/clinic/ClinicInfo';
import EmployeeProfilePage from './pages/clinic/employees/EmployeeProfilePage';
import EmployeesList from './pages/clinic/employees/EmployeesList';
import { GeneralSettings } from './pages/clinic/GeneralSettings';
import InvitationsList from './pages/clinic/InvitationsList';
import RolesList from './pages/clinic/RolesList';
import RoomsList from './pages/clinic/RoomsList';
import ClinicRegistration from './pages/clinicRegistration/ClinicRegistration';
import DashboardPage from './pages/dashboard/DashboardPage';
import BillsDetails from './pages/finances/bills/BillsDetails';
import BillsList from './pages/finances/bills/BillsList';
import FinancesInfo from './pages/finances/dashboard/FinancesInfo';
import FinancesActions from './pages/finances/FinancesActions';
import FinancialAccountsDetails from './pages/finances/financialAccounts/FinancialAccountsDetails';
import FinancialAccountsList from './pages/finances/financialAccounts/FinancialAccountsList';
import PaymentsDetails from './pages/finances/payments/PaymentsDetails';
import PaymentsList from './pages/finances/payments/PaymentsList';
import ShiftDetails from './pages/finances/shifts/ShiftsDetails';
import ShiftsList from './pages/finances/shifts/ShiftsList';
import HomeLanding from './pages/HomeLanding';
import AuditDetails from './pages/inventory/audit/AuditDetails/AuditDetails';
import AuditList from './pages/inventory/audit/AuditList/AuditList';
import InventoryInfo from './pages/inventory/dashboard/InventoryInfo';
import EquipmentList from './pages/inventory/equipment/EquipmentList';
import ViewEditEquipment from './pages/inventory/equipment/ViewEditEquipment';
import InventoryActions from './pages/inventory/InventoryActions';
import InvoiceDetails from './pages/inventory/invoices/InvoiceDetails/InvoiceDetails';
import InvoiceList from './pages/inventory/invoices/InvoiceList';
import StockDetails from './pages/inventory/stock/StockDetails/StockDetails';
import StockList from './pages/inventory/stock/StockList';
import ToolsList from './pages/inventory/tools/ToolsList';
import ViewEditTool from './pages/inventory/tools/ViewEditTool';
import PatientDetails from './pages/patients/PatientDetatils';
import { PatientsList } from './pages/patients/PatientsList';
import { SalesDetails } from './pages/sales/SalesDetails';
import SalesList from './pages/sales/SalesList';
import ServicesDetatils from './pages/services/ServicesDetatils';
import ServicesList from './pages/services/ServicesList';
import { Forbidden } from './pages/sessions/403';
import { NotFound } from './pages/sessions/404';
import AcceptInvitation from './pages/sessions/AcceptInvitation';
import EmailConfirmation from './pages/sessions/EmailConfirmation';
import SetNewPassword from './pages/sessions/SetNewPassword';
import UserProfile from './pages/UserProfile';
import { setLocaleCode } from './redux/localeCode/actions';
import { AppState } from './redux/store';
import { clearUser, getUser } from './redux/user/actions';
import * as config from './config/config-manager.ts';

type Locale = ConfigProviderProps['locale'];

const antdLocaleList: { [key: string]: Locale } = {
  enGB,
  ukUA
};

const locales: Record<string, () => Promise<any>> = {
  ukUA: async () => import('dayjs/locale/uk'),
  enGB: async () => import('dayjs/locale/en')
};

const loadLocale = async (language: string): Promise<void> => {
  locales[language]?.().then(() => dayjs.locale(language));
};

interface IRouteProps {
  children: JSX.Element;
  permission?: boolean;
  pageData?: IPageData;
  publicPage?: boolean;
}

const RouteWrapper = ({
  children,
  pageData,
  permission = true,
  publicPage
}: IRouteProps): JSX.Element => {
  const redirect = useSelector<AppState, boolean>((state) => state.user.redirect);
  const userId = useSelector<AppState, number>((state) => state.user.id);

  usePageData({
    title: '',
    breadcrumbs: [],
    ...pageData,
    fulFilled: true
  });

  if (!userId && !publicPage) {
    const location = window.location;
    const url = redirect ? `/?${redirectURL}=${location.pathname}` : '/';
    return <Navigate to={url} />;
  }

  if (!permission) return <Forbidden />;

  return children;
};

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const userId = useSelector<AppState, number>((state) => state.user.id);
  const userAccess = useSelector<AppState, IUserAccesses>((state) => state.user.clinicAccesses);
  const userLocale = useSelector<AppState, string>((state) => state.user.locale);
  const localeCode: string = useSelector<AppState, string>(({ localeCode }) => localeCode);
  const location = window.location;
  const breadcrumbsLocalized = useLocale('public.modals.breadcrumbs');
  const clinicId = useClinicId(location);

  const token: string | undefined = localStorage.get('auth_token');

  const antdLocale = antdLocaleList[localeCode];

  useEffect(() => {
    if (token && !userId) dispatch(getUser());
  }, [userId, dispatch, token]);

  useEffect(() => {
    loadLocale(localeCode);
  }, [localeCode]);

  useEffect(() => {
    if (userLocale) {
      localStorage.set('locale', userLocale);
      dispatch(setLocaleCode(userLocale));
      return;
    }
    const loc = localStorage.get('locale');
    if (loc) dispatch(setLocaleCode(loc));
  }, [userLocale, dispatch]);

  useEffect((): void => {
    const checkForToken = (): void => {
      const token = localStorage.get('auth_token');

      if (!token) dispatch(clearUser());
    };

    localStorage.on('auth_token', checkForToken);

    return localStorage.off('auth_token', checkForToken);
  }, []);

  const routes = useMemo(
    () => routesConfig(clinicId, userAccess.clinicName, breadcrumbsLocalized),
    [userAccess.clinicName]
  );

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route path='/' element={<BaseLayout />}>
            <Route
              index
              element={
                <RouteWrapper publicPage>
                  <HomeLanding />
                </RouteWrapper>
              }
            />
            <Route
              path='/dashboard'
              element={
                <RouteWrapper permission={!!userId}>
                  <DashboardPage />
                </RouteWrapper>
              }
            />
            <Route
              path='/my-calendar'
              element={
                <RouteWrapper permission={!!userId}>
                  <UserCalendar />
                </RouteWrapper>
              }
            />
            <Route
              path='/confirm-email/:token'
              element={
                <RouteWrapper publicPage>
                  <EmailConfirmation />
                </RouteWrapper>
              }
            />
            <Route
              path='/accept-invitation/:token'
              element={
                <RouteWrapper publicPage>
                  <AcceptInvitation />
                </RouteWrapper>
              }
            />
            <Route
              path='/set-new-password/:token'
              element={
                <RouteWrapper publicPage>
                  <SetNewPassword />
                </RouteWrapper>
              }
            />
            { !config.disabledForProd && <Route
              path='/clinic-registration'
              element={
                <RouteWrapper publicPage>
                  <ClinicRegistration />
                </RouteWrapper>
              }
            /> }
            <Route
              path='/user-profile'
              element={
                <RouteWrapper>
                  <UserProfile />
                </RouteWrapper>
              }
            />
            <Route path='/clinic/:clinicId'>
              <Route element={<ClinicActions />}>
                <Route
                  path='/clinic/:clinicId/administration/info'
                  index
                  element={
                    <RouteWrapper
                      permission={userAccess.accesses.clinics.showItem}
                      {...routes.clinicInfo}
                    >
                      <ClinicInfo />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/administration/employees'
                  element={
                    <RouteWrapper
                      {...routes.employeesList}
                      permission={userAccess.accesses.employees.showList}
                    >
                      <EmployeesList />
                    </RouteWrapper>
                  }
                />
                { !config.disabledForProd && <Route
                  path='/clinic/:clinicId/administration/invitations'
                  element={
                    <RouteWrapper
                      {...routes.invitationsList}
                      permission={userAccess.accesses.invitations.showList}
                    >
                      <InvitationsList />
                    </RouteWrapper>
                  }
                /> }
                <Route
                  path='/clinic/:clinicId/administration/rooms'
                  element={
                    <RouteWrapper
                      {...routes.roomsList}
                      permission={userAccess.accesses.rooms.showList}
                    >
                      <RoomsList />
                    </RouteWrapper>
                  }
                />
                { !config.disabledForProd && <Route
                  path='/clinic/:clinicId/administration/roles'
                  element={
                    <RouteWrapper
                      {...routes.rolesList}
                      permission={userAccess.accesses.roles.showList}
                    >
                      <RolesList />
                    </RouteWrapper>
                  }
                /> }
                <Route
                  path='/clinic/:clinicId/administration/documents'
                  element={
                    <RouteWrapper {...routes.clinicDocuments} permission={true}>
                      <ClinicDocuments />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/administration/dictionaries'
                  element={
                    <RouteWrapper {...routes.clinicDictionaries} permission={true}>
                      <ClinicDictionaries />
                    </RouteWrapper>
                  }
                />
              </Route>
              <Route element={<InventoryActions />}>
                <Route
                  path='/clinic/:clinicId/inventory/info'
                  index
                  element={
                    <RouteWrapper
                      permission={userAccess.accesses.clinics.showItem}
                      {...routes.clinicInfo}
                    >
                      <InventoryInfo />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/inventory/equipment'
                  index
                  element={
                    <RouteWrapper
                      permission={userAccess.accesses.equipment.showList}
                      {...routes.equipmentList}
                    >
                      <EquipmentList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/inventory/tools'
                  element={
                    <RouteWrapper
                      {...routes.toolsList}
                      permission={userAccess.accesses.tools.showList}
                    >
                      <ToolsList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/inventory/stock'
                  element={
                    <RouteWrapper
                      {...routes.stockList}
                      permission={userAccess.accesses.stock.showList}
                    >
                      <StockList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/inventory/audit'
                  element={
                    <RouteWrapper
                      {...routes.auditList}
                      permission={userAccess.accesses.audit.showList}
                    >
                      <AuditList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/inventory/providers'
                  element={
                    <RouteWrapper
                      {...routes.providersList}
                      permission={userAccess.accesses.audit.showList}
                    >
                      <ProvidersList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/inventory/invoices'
                  element={
                    <RouteWrapper {...routes.invoiceList} permission={true}>
                      <InvoiceList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/inventory/generalSettings'
                  element={
                    <RouteWrapper
                      {...routes.generalSettings}
                      // permission={userAccess.accesses.employees.showList}
                    >
                      <GeneralSettings />
                    </RouteWrapper>
                  }
                />
              </Route>
              <Route
                path='/clinic/:clinicId/inventory/invoices/:invoiceId'
                index
                element={
                  <RouteWrapper
                    permission={userAccess.accesses.invoices.showItem}
                    {...routes.invoiceDetails}
                  >
                    <InvoiceDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/inventory/audit/:auditId'
                element={
                  <RouteWrapper
                    {...routes.auditDetails}
                    permission={userAccess.accesses.audit.showItem}
                  >
                    <AuditDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/inventory/equipment/:equipmentId'
                index
                element={
                  <RouteWrapper
                    permission={userAccess.accesses.equipment.showItem}
                    {...routes.equipmentDetails}
                  >
                    <ViewEditEquipment />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/inventory/tools/:toolId'
                index
                element={
                  <RouteWrapper
                    permission={userAccess.accesses.tools.showItem}
                    {...routes.toolDetails}
                  >
                    <ViewEditTool />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/inventory/stock/:stockId'
                index
                element={
                  <RouteWrapper
                    permission={userAccess.accesses.stock.showItem}
                    {...routes.stockDetails}
                  >
                    <StockDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/administration/employees/:employeeId'
                element={
                  <RouteWrapper
                    {...routes.employeeProfile}
                    permission={userAccess.accesses.employees.showItem}
                  >
                    <EmployeeProfilePage />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/appointments'
                element={
                  <RouteWrapper
                    {...routes.appointmentsList}
                    permission={userAccess.accesses.appointments.showList}
                  >
                    <AppointmentsList />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/appointments/:appointmentId'
                element={
                  <RouteWrapper
                    {...routes.appointmentDetails}
                    permission={userAccess.accesses.appointments.showItem}
                  >
                    <AppointmentDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/services'
                element={
                  <RouteWrapper
                    {...routes.servicesList}
                    permission={userAccess.accesses.services.showList}
                  >
                    <ServicesList />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/services/:serviceId'
                element={
                  <RouteWrapper
                    {...routes.servicesDetails}
                    permission={userAccess.accesses.patients.showItem}
                  >
                    <ServicesDetatils />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/patients'
                element={
                  <RouteWrapper
                    {...routes.patientsList}
                    permission={userAccess.accesses.patients.showList}
                  >
                    <PatientsList />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/inventory/providers/:providerId'
                element={
                  <RouteWrapper
                    {...routes.providersDetails}
                    permission={userAccess.accesses.providers.showItem}
                  >
                    <ProviderDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/patients/:patientId'
                element={
                  <RouteWrapper
                    {...routes.patientsDetails}
                    permission={userAccess.accesses.patients.showItem}
                  >
                    <PatientDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/clients'
                element={
                  <RouteWrapper
                    {...routes.clientsList}
                    permission={userAccess.accesses.clients.showList}
                  >
                    <ClientsList />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/events-calendar'
                element={
                  <RouteWrapper {...routes.events} permission={userAccess.accesses.events.showList}>
                    <EventsCalendar />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/clients/:clientId'
                element={
                  <RouteWrapper
                    {...routes.clientDetails}
                    permission={userAccess.accesses.clients.showItem}
                  >
                    <ClientDetails />
                  </RouteWrapper>
                }
              />
              <Route element={<FinancesActions />}>
                <Route
                  path='/clinic/:clinicId/finances/info'
                  index
                  element={
                    <RouteWrapper
                      permission={userAccess.accesses.clinics.showItem}
                      {...routes.financesInfo}
                    >
                      <FinancesInfo />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/finances/bills'
                  index
                  element={
                    <RouteWrapper
                      permission={true}
                      // permission={userAccess.accesses.finances.showItem}
                      {...routes.billsList}
                    >
                      <BillsList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/finances/shifts'
                  index
                  element={
                    <RouteWrapper
                      permission={true}
                      // permission={userAccess.accesses.finances.showItem}
                      {...routes.shiftsList}
                    >
                      <ShiftsList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/finances/payments'
                  index
                  element={
                    <RouteWrapper
                      permission={true}
                      // permission={userAccess.accesses.finances.showItem}
                      {...routes.paymentsList}
                    >
                      <PaymentsList />
                    </RouteWrapper>
                  }
                />
                <Route
                  path='/clinic/:clinicId/finances/financial-accounts'
                  index
                  element={
                    <RouteWrapper
                      permission={true}
                      // permission={userAccess.accesses.finances.showItem}
                      {...routes.financialAccountsList}
                    >
                      <FinancialAccountsList />
                    </RouteWrapper>
                  }
                />
              </Route>
              <Route
                path='/clinic/:clinicId/finances/shifts/:shiftId'
                index
                element={
                  <RouteWrapper
                    permission={true}
                    // permission={userAccess.accesses.finances.showItem}
                    {...routes.shiftDetails}
                  >
                    <ShiftDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/finances/financial-accounts/:financialAccountId'
                index
                element={
                  <RouteWrapper
                    {...routes.financialAccountsDetails}
                    permission={true}
                    // permission={userAccess.accesses.finances.showItem}
                  >
                    <FinancialAccountsDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/finances/bills/:billId'
                index
                element={
                  <RouteWrapper
                    {...routes.billsDetails}
                    permission={true}
                    // permission={userAccess.accesses.finances.showItem}
                  >
                    <BillsDetails />
                  </RouteWrapper>
                }
              />
              <Route
                path='/clinic/:clinicId/finances/payments/:paymentId'
                index
                element={
                  <RouteWrapper
                    {...routes.paymentsDetails}
                    permission={true}
                    // permission={userAccess.accesses.finances.showItem}
                  >
                    <PaymentsDetails />
                  </RouteWrapper>
                }
              />
            </Route>
            <Route
              path='/clinic/:clinicId/shop-farmacy'
              element={
                <RouteWrapper
                  {...routes.shopFarmacyList}
                  permission={userAccess.accesses.clients.showList}
                >
                  <SalesList />
                </RouteWrapper>
              }
            />
            <Route
              path='/clinic/:clinicId/shop-farmacy/new'
              element={
                <RouteWrapper
                  {...routes.shopFarmacyDetails}
                  permission={userAccess.accesses.clients.showItem}
                >
                  <SalesDetails />
                </RouteWrapper>
              }
            />
            <Route
              path='/clinic/:clinicId/shop-farmacy/:shopFarmacyId'
              element={
                <RouteWrapper
                  {...routes.shopFarmacyDetails}
                  permission={userAccess.accesses.clients.showItem}
                >
                  <SalesDetails />
                </RouteWrapper>
              }
            />
            <Route path='/403' element={<Forbidden />} />
            <Route path='*' element={<NotFound />} />
          </Route>
        )
      ),
    [userAccess.accesses, userId]
  );

  if (token && !userId) return <Loader />;

  return (
    <ConfigProvider locale={antdLocale}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default App;
