import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useUserAccess } from 'hooks/useUserAccess';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';

import { ReassignPriceForm } from '../forms/ReassignPriceForm';

type Props = {
  locale: ILocale;
  setShowReassignPriceModal: (show: boolean) => void;
};

export const ReassignPriceModal = ({ locale, setShowReassignPriceModal }: Props): JSX.Element => {
  const userAccess = useUserAccess();
  const [form] = Form.useForm();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    setShowCancelModal(false);
    setShowReassignPriceModal(false);
  };

  return (
    <VLXModal
      title={locale.labels.reassignPrices}
      centered
      open
      width={1100}
      form={form}
      onModalClose={() => setShowReassignPriceModal(false)}
    >
      <ReassignPriceForm
        form={form}
        locale={locale}
        setShowReassignPriceModal={setShowReassignPriceModal}
      >
        <ViewDetailsButtonsGroup
          accessGroup={userAccess.accesses.invitations}
          fixed
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              setShowReassignPriceModal(false);
            }
          }}
          onDeleteClick={() => {
            setShowCancelModal(false);
          }}
        />
      </ReassignPriceForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
