import { Select, Spin } from 'antd';
import * as config from 'config/config-manager.ts';
import { useLocale } from 'hooks/useLocale.tsx';
import { IAppState } from 'interfaces/app-state.ts';
import { ISearchCriteria } from 'interfaces/search-criteria.ts';
import { ISortCriteria } from 'interfaces/sort-criteria.ts';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStock } from 'redux/inventory/stock/actions.ts';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem.ts';

const { Option } = Select;

type Props = {
  onChange?: (value?) => void;
  clinicId: number;
  selectedStockId?: number;
  locale: any;
  trackOutOfStock?: boolean;
};

export const StockSelect = ({
  onChange,
  clinicId,
  selectedStockId,
  locale,
  trackOutOfStock = true,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const commonLocaleProps = useLocale('private.select-with-required-search');

  const stockList = useSelector(({ stock }: IAppState) => stock.data);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect((): void => {
    if (selectedStockId) {
      performSearch(searchByIdObject(selectedStockId));
    }
  }, [selectedStockId]);

  const sortObject: ISortCriteria = {
    sort_key: 'name',
    sort_order: 'asc'
  };

  const searchObject = (values: string[]): ISearchCriteria[] => {
    return values.map((value) => ({
      name: 'composite',
      value,
      isArrayValue: true
    }));
  };

  const searchByIdObject = (id: number): ISearchCriteria[] => [
    {
      name: 'id',
      value: id.toString()
    }
  ];

  const performSearch = (searchCriteria: ISearchCriteria[]): void => {
    dispatch(getStock(clinicId, 0, searchCriteria, null, sortObject, () => setLoading(false)));
  };

  const onStockSearch = (value: string): void => {
    if (value.length < config.selectSearchMinSymbols) return;

    const searchValues: string[] = value.split(' ')
      .filter((item: string) => item !== '');

    setLoading(true);
    performSearch(searchObject(searchValues));
  };

  const stockSelectLabel = (item: IStockItem): string => {
    const itemManufacturer = item?.manufacturer;
    const manufacturer = itemManufacturer ? ` (${itemManufacturer})` : '';
    const isOutOfStock = item.remaining_capacity === 0 ? `| ${locale.labels.outOfStock}` : '';
    return `${item?.name} ${manufacturer} ${isOutOfStock}`;
  };

  const stockListOptions: JSX.Element[] = useMemo(
    (): JSX.Element[] =>
      stockList
        ?.sort((a, b) => b.remaining_capacity - a.remaining_capacity)
        ?.map((item) => (
          <Option
            className='item-form-select'
            disabled={trackOutOfStock && item.remaining_capacity <= 0}
            key={item.id}
            title={item.name + item.manufacturer}
            value={item.id}
          >
            {stockSelectLabel(item)}
          </Option>
        )),
    [stockList]
  );

  return (
    <Select
      placeholder={commonLocaleProps.placeholders.inputMinForSearch}
      virtual={false}
      onChange={onChange}
      notFoundContent={loading ? <Spin size='small' /> : null}
      value={selectedStockId}
      id={'stock-name'}
      filterOption={false}
      showSearch
      onSearch={debounce(onStockSearch, config.selectSearchDelay)}
    >
      {stockListOptions}
    </Select>
  );
};
