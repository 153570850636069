import './AppointmentServices.scss';

import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addAppointmentDetailsHealthRecordInventory } from 'redux/appointments/actions';

import AppointmentInventoryFormItem from './AppointmentServicesStockItems';

const AppointmentServicesItemsForm = ({
  form,
  serviceData,
  children,
  onFieldsChange,
  healthRecordId,
  disabled
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const params = useParams() as any;
  const currencies = useLocale('private.currencies').labels;
  const locale = useLocale('private.appointments.event');

  const serviceId = serviceData?.id;
  const [addedItems, setAddedItems] = useState(
    serviceData?.stock?.length ? serviceData?.stock.map((el, index) => index) : []
  );

  const onSubmit = (data: any): void => {
    const res = {
      service_id: serviceId,
      price: Number(data.price).toFixed(2),
      total_price: Number(data.total_price).toFixed(2),
      service_count: Number(data.service_count),
      items: data?.stock.map((el) => {
        return {
          stock_id: el?.stock.id,
          location_id: el?.stock_location_id,
          price_id: el?.stock_price_id,
          quantity: Number(el?.quantity.toString().replace(',', '.')).toFixed(3),
          price_per_item: el?.price
            ? Number(el?.price.toString().replace(',', '.')).toFixed(2)
            : '0',
          included_in_service_price: el?.included_in_service_price
        };
      })
    };
    dispatch(
      addAppointmentDetailsHealthRecordInventory(
        clinicId,
        params.appointmentId,
        healthRecordId,
        res
      )
    );
  };

  function selectItemsMessage() {
    return disabled || serviceData?.stock_saved
      ? locale.messages.noStockUsed
      : locale.messages.addItemsOrSave;
  }

  const onServicePriceChange = (value: number) => {
    const serviceCount = form.getFieldValue('service_count');
    const price = Number(value * serviceCount).toFixed(2);
    form.setFieldValue('total_price', price);
  };

  const onServiceQuantityChange = (value: number) => {
    const pricePerServise = form.getFieldValue('price');
    const price = Number(value * pricePerServise).toFixed(2);
    form.setFieldValue('total_price', price);
  };

  return (
    <Form
      key={serviceData?.id + serviceData.name}
      onFieldsChange={onFieldsChange}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
    >
      <Row gutter={[20, 10]}>
        <Col span={8}>
          <Form.Item
            key='servicePrice'
            rules={[
              {
                required: true
              }
            ]}
            name='price'
            label={`${locale.labels.servicePrice} (${currencies.uah})`}
            initialValue={serviceData?.price}
          >
            <VLXInputNumber disabled={disabled} precision={3} step={0.01} onChange={onServicePriceChange} min={0.01}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            key='service_count'
            rules={[
              {
                required: true
              },
              {
                pattern: /^[1-9]\d*$/,
                message: locale.messages.onlyNumberAllowed
              }
            ]}
            name='service_count'
            label={locale.labels.count}
            initialValue={serviceData?.service_count || 1}
          >
            <VLXInputNumber disabled={disabled} precision={3} step={1} min={1} onChange={onServiceQuantityChange} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            key='servicePrice'
            rules={[
              {
                required: true
              }
            ]}
            name='total_price'
            label={`${locale.labels.sum} (${currencies.uah})`}
            initialValue={serviceData?.total_price || serviceData?.price}
          >
            <Input disabled type={'number'} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Typography.Title style={{ marginBottom: '20px' }} level={5}>
        {locale.labels.stockItems}
      </Typography.Title>
      {addedItems?.length <= 0 ? (
        <Typography.Text type='secondary'>{selectItemsMessage()}</Typography.Text>
      ) : null}
      <Form.List name='stock' initialValue={serviceData?.stock}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <AppointmentInventoryFormItem
                key={`${key}${name}`}
                name={name}
                initialData={serviceData?.stock}
                restField={restField}
                remove={remove}
                locale={locale}
                clinicId={clinicId}
                form={form}
                disabled={disabled}
                setAddedItems={setAddedItems}
                addedItems={addedItems}
              />
            ))}
            <Row>
              <Form.Item key='add-more-stock'>
                {!disabled && (
                  <Button
                    id='add-more-stock'
                    className='icofont icofont-plus'
                    type={'primary'}
                    style={{ borderRadius: 30, marginTop: '10px' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    {locale.buttons.addNewItem}
                  </Button>
                )}
              </Form.Item>
            </Row>
          </>
        )}
      </Form.List>
      {children}
    </Form>
  );
};

export default AppointmentServicesItemsForm;
