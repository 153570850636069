import './PatientList.scss';

import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { Form, Pagination, Row, Spin, Table, TableColumnProps, Tooltip, Typography } from 'antd';
import tomb from 'assets/img/tomb.svg';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import VLXListCompositeSearch from 'components/VLXListCompositSearch';
import { animalIdUrl } from 'constants/comon';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { UseSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import { IPatient } from 'interfaces/patient';
import AddNewPatient from 'layout/modals/editPatient/AddNewPatient';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setSelectedClient } from 'redux/clients/actions';
import { getPatients, resetPatients, setSelectedPatient } from 'redux/patients/actions';
import { localizedPetData } from 'utils/localized-pet-data';

import { getAnimalAge } from './helpers/PatientHelper';

const AnimalIcon = ({ sex }: { sex: string }): JSX.Element => {
  switch (sex) {
    case 'male':
      return <ManOutlined />;
    case 'female':
      return <WomanOutlined />;
    default:
      return <>-</>;
  }
};

export const PatientsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();

  const [addPatientForm] = Form.useForm();

  const locale = useLocale('private.patients.patients-list');
  const localePage = useLocale('private.patients.patient-page');

  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);
  const loading = useSelector(({ patients }: IAppState) => patients.loading);
  const {
    data,
    metadata
  } = useSelector(({ patients }: IAppState) => patients);

  const {
    handleChange,
    onPageChange,
    getResults
  } = UseSearch({
    action: getPatients,
    clinicId,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc'
  });

  useEffect(() => {
    dispatch(setSelectedClient(null));
    dispatch(setSelectedPatient(null));

    return () => {
      dispatch(resetPatients());
    };
  }, []);

  const redirectToPatientDetails = ({ id }: IPatient): { onClick: () => void } => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/patients/${id}`);
    }
  });

  const getAge = (e: IPatient): JSX.Element =>
    e?.date_of_death ? (
      <Typography id='patient-death-icon'>
        <Tooltip
          trigger={'hover'}
          placement='topLeft'
          title={getAnimalAge(localePage, e?.birth_date, e?.date_of_death)}
        >
          {'\u00A0'}
          <img src={tomb} style={{
            width: 22,
            height: 22
          }} />
        </Tooltip>
      </Typography>
    ) : (
      <Typography id='list-pet-age'>
        {e?.birth_date ? getAnimalAge(localePage, e?.birth_date) : '-'}
      </Typography>
    );

  const getAnimalData = (data: IPatient): string => {
    const {
      species,
      breed
    } = localizedPetData(data, localeCode);

    if (species && breed) return `${species} (${breed})`;

    return species || '-';
  };

  const ownerPhoneNumber = (p: IPatient): JSX.Element => {
    if (p?.owner.phone_number.length < 9) return <span>-</span>;
    return (
      <Link to={`tel:${p?.owner.phone_number}`} className='custom-links d-flex align-baseline nowrap'>
        <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
        {ListFieldWithTooltip(`+${p?.owner.phone_number}`)}
      </Link>
    );
  };

  const columns = useMemo(
    (): TableColumnProps<IPatient>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: () => null,
        render: (name, e) => name,
        onCell: redirectToPatientDetails
      },
      {
        key: 'species',
        title: locale.labels.species,
        sorter: () => null,
        render: (e) => getAnimalData(e),
        onCell: redirectToPatientDetails
      },
      {
        key: 'blood_type',
        title: locale.labels.bloodType,
        sorter: () => null,
        onCell: redirectToPatientDetails,
        render: (e) => e.blood_type || '-'
      },
      {
        key: 'birth_date',
        title: locale.labels.age,
        sorter: () => null,
        onCell: redirectToPatientDetails,
        render: (e) => getAge(e)
      },
      {
        key: 'sex',
        title: locale.labels.sex,
        sorter: () => null,
        render: (e) => <AnimalIcon sex={e.sex} />,
        onCell: redirectToPatientDetails
      },
      {
        key: 'chip_number',
        dataIndex: 'chip_number',
        title: locale.labels.chip,
        sorter: () => null,
        render: (chip_number, e) =>
          chip_number
            ? ListFieldWithTooltip(
              <Link className={'custom-links'} to={animalIdUrl + chip_number}>
                {chip_number}
              </Link>
            )
            : '-'
      },
      {
        key: 'owner_name',
        title: locale.labels.owner,
        sorter: () => null,
        render: (e) => (
          <>
            <Link to={`/clinic/${clinicId}/clients/${e.owner.id}`} className={'custom-links'}>
              {ListFieldWithTooltip(e?.owner.name, 30)}
            </Link>
            {blacklistedStatus(e.owner)}
          </>
        )
      },
      {
        key: 'owner_phone_number',
        title: locale.labels.contact,
        sorter: () => null,
        render: (e) => (ownerPhoneNumber(e))
      }
    ],
    [data]
  );

  return (
    <>
      <VLXListCompositeSearch />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header patients-list-table'
          rowKey={(row) => `${row.id}_${crypto.randomUUID()}`}
          dataSource={data}
          onChange={handleChange}
          columns={columns}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            defaultCurrent={1}
            pageSize={20}
            current={metadata.page}
            total={metadata.total}
            showSizeChanger={false}
            showLessItems
            onChange={onPageChange}
          />
          <AddNewPatient
            form={addPatientForm}
            locale={localePage}
            initialValues={{
              vaccination_notification: true,
              appointments_notification: true
            }}
            callBackAfterCreate={getResults}
          />
        </Row>
      </Spin>
    </>
  );
};
