import React, { useState } from 'react';
import {
  Button,
  Input,
  Form,
  Spin,
  Modal
} from 'antd';
import MessageModal from '../components/messageModal/MessageModal';
import { changePassword } from '../../services/clinic/users/userService';
import { useLocale } from '../../hooks/useLocale';

const ChangeUserPassword = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const locale = useLocale('private.user.user-profile');
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    setLoading(true);
    changePassword(values)
      .then(() => {
        setLoading(false);
        setVisible(false);
        MessageModal('success', locale.messages.passwordChanged);
      })
      .catch(() => {
        setLoading(false);
        form.setFields([
          {
            name: 'current_password',
            errors: [locale.messages.wrongPassword]
          }
        ]);
      });
  };

  return (
    <>
      <Button className='edit-button' type='primary' block onClick={() => setVisible(true)}>
        {locale.buttons.changePassword}
      </Button>
      <Modal
        title={locale.labels.changePasswordTitle}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={300}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            onFinish={onSubmit}
            autoComplete='off'
            layout='vertical'
          >
            <Form.Item
              label={locale.labels.oldPassword}
              name='current_password'
              rules={[{ required: true, message: locale.messages.emptyField }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={locale.labels.newPassword}
              name='new_password'
              rules={[{ required: true, message: locale.messages.emptyField },
                {
                  required: true,
                  pattern: (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s).{8,}$/),
                  message: locale.messages.inputPassword
                }]
              }
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={locale.labels.repeatNewPassword}
              name='new_password_confirmation'
              dependencies={['new_password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: locale.messages.emptyField
                },
                ({ getFieldValue }) => ({
                  async validator (_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                      return await Promise.resolve();
                    }
                    return await Promise.reject(new Error(locale.messages.passwordDidNotMatch));
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button block type='primary' htmlType='submit'>
                {locale.buttons.submit}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ChangeUserPassword;
