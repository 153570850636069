import './ClientDetails.scss';

import { Button, Card, Col, Collapse, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import userAvatar from '../../assets/img/user-avatar.svg';
import Avatar from '../../components/Avatar';
import { getSocialMediaData } from '../../constants/dictionary/default/socialMediaOptions';
import { blacklistedStatus } from '../../helpers/ViewClientHelper';
import { useLocale } from '../../hooks/useLocale';
import { IAppState } from '../../interfaces/app-state';
import AddClient from '../../layout/modals/addClient/AddClient';
import { setAvatar, showModal } from '../../redux/clients/actions';
import { paymentStatus } from './ClientsBase';

const ClientDetailsOverview = ({ clinicId, clientId, data, editAccess, locale }: any) => {
  const dispatch = useDispatch();
  const currencies = useLocale('private.currencies').labels;
  const userLoading = useSelector<IAppState, any>((state) => state.user.loading);
  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);

  const handleSetAvatar = (data, callBackFnc): void => {
    const avatarData = new FormData();
    avatarData.append('avatar', data);
    dispatch(
      setAvatar(clinicId, clientId, avatarData, () => {
        setChangeAvatarModalVisible(false);
        if (callBackFnc) callBackFnc();
      })
    );
  };

  const getClientName = () => {
    const name = data?.first_name && data?.first_name !== null ? data?.first_name : '';
    const lastName = data?.last_name && data?.last_name !== null ? data?.last_name : '';
    return name + ' ' + lastName;
  };

  return (
    <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container'>
      <Row justify='center' style={{ marginTop: 10 }}>
        <Avatar
          loading={userLoading}
          editAccess={editAccess}
          onImageChange={handleSetAvatar}
          src={data?.avatar ? data?.avatar : userAvatar}
          setChangeAvatarModalVisible={setChangeAvatarModalVisible}
          changeAvatarModalVisible={changeAvatarModalVisible}
        />
      </Row>
      <Col>
        <Row justify='center' style={{ alignItems: 'center' }}>
          <Typography.Title
            level={4}
            id='client-name'
            style={{
              marginBottom: '10px',
              marginTop: '10px'
            }}
          >
            {getClientName()}
          </Typography.Title>
          {blacklistedStatus(data, 20)}
        </Row>
        <Row justify='center' style={{ marginBottom: '10px' }}>
          {paymentStatus(data?.billed_payed_amounts, locale, currencies)}
        </Row>
        <Row
          justify='center'
          style={{
            marginBottom: '30px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {data?.private_notes && (
            <Collapse
              size={'small'}
              style={{ backgroundColor: 'white' }}
              items={[
                {
                  key: 1,
                  label: (
                    <>
                      <span style={{ marginRight: '5px' }}>{locale.labels.specialNotes}</span>
                      <span
                        className='icofont-warning'
                        style={{
                          color: 'orange',
                          fontSize: '16px'
                        }}
                      ></span>
                    </>
                  ),
                  children: <p>{data?.private_notes}</p>
                }
              ]}
            ></Collapse>
          )}
        </Row>
        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-birthday-cake' />
          </Col>
          <Col id='client-birthdate' style={{ fontSize: 16 }}>
            {data?.date_of_birth ? dayjs(data?.date_of_birth).format('DD.MM.YYYY') : '-'}
          </Col>
        </Row>
        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-ui-touch-phone info-icon' />
          </Col>
          <Col id='client-phone'>
            {data?.phone_number ? (
              <a
                href={`tel:${data?.country_code}${data?.phone_number}`}
                className='custom-links d-flex align-baseline nowrap'
                style={{ fontSize: 16 }}
              >
                {data?.phone_number ? `+${data?.country_code}${data?.phone_number}` : '-'}
              </a>
            ) : (
              '-'
            )}
          </Col>
        </Row>
        <Row align='middle' className='m-b-10 client-email-row'>
          <Col className='icon-style'>
            <span className='icofont-ui-message info-icon' />
          </Col>
          <Col id='client-email'>
            {data?.email ? (
              <a
                href={`mailto:${data?.email}`}
                className='custom-links d-flex align-baseline nowrap'
                style={{ fontSize: 16 }}
              >
                {data?.email}
              </a>
            ) : (
              '-'
            )}
          </Col>
        </Row>
        <Row align='middle' className='m-b-10'>
          <Col className='icon-style'>
            <span className='icofont-location-pin info-icon' />
          </Col>
          {data && (
            <Col id='client-address'>
              {['country', 'region', 'city', 'address']
                .map((k) => data[k])
                .filter((value) => value !== null && value !== '')
                .join(', ') || '-'}
            </Col>
          )}
        </Row>
        {data?.social_media_links &&
          JSON.parse(data?.social_media_links)?.map((link, index) => {
            const media = getSocialMediaData(link.type);
            return (
              <Row key={index} align='middle'>
                <Col className='icon-style'>{media?.icon}</Col>
                <Col id='client-email'>
                  <a href={`${media?.baseUrl}${link?.description}`} style={{ fontSize: 15 }}>
                    {link?.description}
                  </a>
                </Col>
              </Row>
            );
          })}
        <AddClient initialValues={data} hideDefaultBtn editMode />
        <Row style={{ justifyContent: 'center' }}>
          <Button
            id='edit-client'
            className='icofont icofont-pencil-alt-2'
            style={{
              borderRadius: 30,
              marginTop: 20,
              fontWeight: 'bold'
            }}
            onClick={() => dispatch(showModal(true))}
            type='primary'
          >
            {locale.buttons.editClient}
          </Button>
        </Row>
      </Col>
    </Card>
  );
};

export default ClientDetailsOverview;
