import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialDataForAppointment, showModal } from 'redux/appointments/actions';

import AddAppointmentForm from './AddAppointmentForm';

const AddAppointment = ({ hideDefaultCreatePetFormBtn, calendarView = false, searchItemsArray }: any) => {
  const dispatch = useDispatch();
  const appointments = useSelector<IAppState, any>((state) => state.appointments);
  const initialDataForAppointment = useSelector<IAppState, any>(
    (state) => state.appointments.initialDataForAppointment
  );
  const [addAppointmentForm] = Form.useForm();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const locale = useLocale('private.appointments.event');

  const onConfirmCancelClick = () => {
    addAppointmentForm.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideDefaultCreatePetFormBtn ? (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addAppointment}
        />
      ) : (
        hideDefaultCreatePetFormBtn
      )}
      <VLXModal
        title={locale.labels.createNewAppointment}
        centered={true}
        form={addAppointmentForm}
        open={appointments.showModal}
        onModalClose={() => {
          dispatch(showModal(false));
          dispatch(setInitialDataForAppointment(null));
        }}
        width={800}
      >
        {appointments.showModal && (
          <AddAppointmentForm
            form={addAppointmentForm}
            initialValues={initialDataForAppointment}
            locale={locale}
            calendarView={calendarView}
            searchItemsArray={searchItemsArray}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.patients}
              editMode={true}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (addAppointmentForm.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </AddAppointmentForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddAppointment;
