import { Form, Modal } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import { ILocale } from 'interfaces/locale';
import { ISaleDetails, ISaleStockItem } from 'interfaces/sales';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSale, updateSale } from 'redux/sales/actions';

import { formPayloadItems } from '../utils/formPayloadItems';
import { SaleItemForm } from './SaleItemForm';
import { IClient } from '../../../interfaces/client.ts';

type Props = {
  locale: ILocale;
  itemToEdit?: { item: ISaleStockItem; index: number };
  saleDetails: ISaleDetails;
  setShowSaleItemModal?: React.Dispatch<boolean>;
  selectedClient: IClient;
  setFieldsTouched: (val: boolean) => void;
};

export const SaleItemModal = ({
  locale,
  itemToEdit,
  saleDetails,
  selectedClient,
  setShowSaleItemModal,
  setFieldsTouched
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId } = useParams();
  const [form] = Form.useForm();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [saleDetails]);

  const onSubmit = (data: ISaleStockItem): void => {
    const items = (saleDetails?.stock || []).map((item, index: number) =>
      formPayloadItems(index === itemToEdit?.index ? data : item)
    );

    const payload = {
      client_id: selectedClient?.id || null,
      vat_included: false,
      items: itemToEdit ? items : items.concat(formPayloadItems(data)),
      fiscal: true,
      discount_percentage: saleDetails?.stock_discount.percentage || 0
    };

    if (saleDetails?.id) {
      dispatch(updateSale(+clinicId, saleDetails?.id, payload));
    } else {
      dispatch(
        createSale(+clinicId, payload, (data) => {
          if (data?.bill_id) {
            const newUrl = `/clinic/${clinicId}/shop-farmacy/${data.id}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
          }
        })
      );
    }
    form.resetFields();
    setShowSaleItemModal?.(false);
  };

  const onCancel = (): void => {
    if (form.isFieldsTouched()) {
      setShowCancelModal(true);
    } else {
      setShowSaleItemModal?.(false);
    }
  };

  const FormContent = useCallback(
    () => (
      <Form
        key={'SaleItemForm'}
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={itemToEdit?.item}
        className='sale-form'
      >
        <SaleItemForm
          locale={locale}
          form={form}
          itemToEdit={itemToEdit?.item}
          onCancel={onCancel}
          setFieldsTouched={setFieldsTouched}
        ></SaleItemForm>
      </Form>
    ),
    [saleDetails, selectedClient]
  );

  const ModalWithForm = useCallback(
    () => (
      <Modal
        forceRender
        zIndex={1000}
        title={locale.labels.editStock}
        centered
        open
        footer={null}
        onCancel={onCancel}
        width={800}
        destroyOnClose
      >
        <FormContent />
      </Modal>
    ),
    [saleDetails, selectedClient]
  );

  return (
    <>
      {itemToEdit ? (
        <ModalWithForm />
      ) : (
        <>
          <h5 style={{ fontWeight: 600, fontSize: 16 }}>{locale.labels.addStock}</h5>
          <FormContent />
        </>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={() => {
          setShowSaleItemModal?.(false);
          setShowCancelModal(false);
          form.resetFields();
        }}
        cancelText={itemToEdit ? null : locale.messages.clearSalesForm}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
