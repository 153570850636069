import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAppointmentDetailsPatients } from 'redux/appointments/actions';

import AppointmentServicesItemsForm from './AppointmentServicesItemsForm';

const AppointmentServicesContainer = ({
  serviceData,
  healthRecordId,
  disabled,
  servicesStockStatus,
  setServicesStockStatus,
  clinicId,
  appointmentId
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();

  const [editMode, setEditMode] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [formTouched, setFormTouched] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const array = [];
    const currentStatusObject = servicesStockStatus.find((el: any) => el.id === serviceData?.id);
    currentStatusObject.status = serviceData?.stock_saved && !formTouched;
    array.push(...servicesStockStatus.filter((el: any) => el.id !== serviceData?.id));
    array.push(currentStatusObject);
    setServicesStockStatus(array);
    setEditMode(!serviceData?.stock_saved || formTouched);
  }, [formTouched]);

  useEffect(() => {
    setFormTouched(false);
  }, [serviceData]);

  const onConfirmCancelClick = () => {
    form.resetFields();
    setShowCancelModal(false);
    setFormTouched(false);
    setEditMode(false);
    dispatch(getAppointmentDetailsPatients(clinicId, appointmentId));
  };

  const onFieldsChange = (e: any) => {
    setFormTouched(true);
    setEditMode(true);
  };

  const onSaveModalClick = () => {
    if (!form.getFieldsError().some((el) => el.errors.length > 0)) {
      setEditMode(false);
      setFormTouched(false);
    }
  };

  return (
    <>
      <AppointmentServicesItemsForm
        form={form}
        disabled={disabled}
        serviceData={serviceData}
        healthRecordId={healthRecordId}
        onFieldsChange={onFieldsChange}
        editMode={editMode}
      >
        {!disabled && (
          <ViewDetailsButtonsGroup
            key={serviceData?.id}
            fixed
            onSaveModalClick={onSaveModalClick}
            editMode={editMode}
            onEditClick={() => setEditMode(true)}
            accessGroup={userAccess.accesses.appointments}
            hideDeleteBtn={true}
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                setEditMode(false);
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
            }}
          />
        )}
      </AppointmentServicesItemsForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};

export default AppointmentServicesContainer;
