import 'assets/sass/components/_table.scss';

import { Col, Form, Row, Spin, Typography } from 'antd';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import { IClinic } from 'interfaces/clinic';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editClinic, getClinic } from 'redux/clinic/actions';

export const GeneralSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const [form] = Form.useForm();
  const locale = useLocale('private.administration.general-settings');
  const userAccess = useUserAccess();

  const clinic = useSelector<IAppState, IClinic>(({ clinic }) => clinic);
  const loading = useSelector<IAppState, boolean>((state) => state.clinic.loading);
  const { markup_package, markup_unit } = useSelector(({ clinic }: IAppState) => clinic);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (clinicId) {
      dispatch(getClinic(clinicId, () => form.resetFields()));
    }
  }, [clinicId]);

  useEffect(() => {
    form.resetFields();
  }, [markup_package, markup_unit]);

  const onSubmit = (values: any): void => {
    if (form.isFieldsTouched()) {
      const apiData = {
        ...clinic,
        markup_package: Number(values.markup_package),
        markup_unit: Number(values.markup_unit)
      };
      dispatch(editClinic(clinicId, apiData));
    }
    setEditMode(false);
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout='vertical'
        onFinish={onSubmit}
        form={form}
        initialValues={{ markup_package, markup_unit }}
        onFieldsChange={() => {
          setEditMode(true);
        }}
      >
        <Typography.Title level={4}>{locale.labels.defaultMarkups}</Typography.Title>
        <Row>
          <Col span={6} style={{ minWidth: '270px' }}>
            <Form.Item
              label={locale.labels.perPackage}
              name='markup_package'
              rules={[
                {
                  required: true,
                  message: locale.messages.canNotBeEmpty
                }
              ]}
            >
              <VLXInputNumber placeholder='0' type='number' precision={0} step={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ minWidth: '270px' }}>
            <Form.Item
              label={locale.labels.perUnit}
              name='markup_unit'
              rules={[
                {
                  required: true,
                  message: locale.messages.canNotBeEmpty
                }
              ]}
            >
              <VLXInputNumber placeholder='0' type='number' precision={0} step={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} style={{ minWidth: '270px' }}>
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.clinics}
              editMode={editMode}
              hideDeleteBtn={true}
              onEditClick={() => setEditMode(true)}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  form.resetFields();
                }
                setEditMode(false);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
