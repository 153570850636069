import { Col, DatePicker, Form, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { isMobile } from 'react-device-detect';

import { useLocale } from '../hooks/useLocale';

const { TimePicker } = DatePicker;

dayjs.extend(utc);
dayjs.extend(timezone);

const DateRangePicker = ({
  disableTimeEditing,
  startDate,
  endDate,
  duration,
  setStartDate,
  setEndDate,
  setDuration
}: any) => {
  const locale = useLocale('private.appointments.event');

  function onDurationChange(value): void {
    setDuration(value);
    setEndDate(startDate.add(value.hour(), 'h').add(value.minute(), 'm'));
  }

  function onStartDateSelect(value): void {
    const date: Dayjs = startDate.year(value.year()).month(value.month()).date(value.date());
    setStartDate(date);
    if (duration !== null) {
      const endDateTime: Dayjs = date.add(duration.hour(), 'h').add(duration.minute(), 'm');
      setEndDate(endDateTime);
    }
  }

  function onStartTimeSelect(value): void {
    const date: Dayjs = startDate.hour(value.hour()).minute(value.minute());
    setStartDate(date);
    if (duration !== null) {
      const endDateTime: Dayjs = date.add(duration.hour(), 'h').add(duration.minute(), 'm');
      setEndDate(endDateTime);
    }
  }

  return (
    <>
      <Row
        gutter={8}
        style={{
          width: '100%',
          alignItems: 'flex-end'
        }}
      >
        <Col>
          <Form.Item
            label={locale.labels.startTime}
            name='date_from'
            rules={[
              {
                required: true,
                message: locale.messages.startDateNotValid
              }
            ]}
          >
            <DatePicker
              inputReadOnly={isMobile}
              className='mobile-custom-dropdown'
              disabled={disableTimeEditing}
              allowClear={false}
              onChange={onStartDateSelect}
              value={startDate}
              format={'DD.MM.YYYY'}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name='time_from'
            rules={[
              {
                required: true,
                message: locale.messages.startTimeNotValid
              }
            ]}
          >
            <TimePicker
              inputReadOnly={isMobile}
              className='mobile-custom-dropdown'
              disabled={disableTimeEditing}
              allowClear={false}
              onChange={onStartTimeSelect}
              value={startDate}
              changeOnBlur
              minuteStep={5}
              format={'HH:mm'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={8}
        style={{
          width: '100%',
          alignItems: 'flex-end'
        }}
      >
        <Col>
          <Form.Item
            label={locale.labels.duration}
            name='duration'
            rules={[
              {
                required: true,
                message: locale.messages.durationNotValid
              }
            ]}
          >
            <TimePicker
              className='mobile-custom-dropdown'
              disabled={disableTimeEditing}
              changeOnBlur
              allowClear={false}
              inputReadOnly={isMobile}
              showNow={false}
              onChange={onDurationChange}
              placeholder={locale.labels.duration}
              minuteStep={5}
              format={'HH:mm'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={8}
        style={{
          width: '100%',
          alignItems: 'flex-end'
        }}
      >
        <Col>
          <Form.Item
            label={locale.labels.endTime}
            hidden={!endDate}
            rules={[
              {
                required: true,
                message: locale.labels.timeRangeNotValid
              }
            ]}
          >
            <Row
              gutter={8}
              style={{
                alignItems: 'flex-end'
              }}
            >
              <Col>
                <DatePicker
                  allowClear={false}
                  format={'DD.MM.YYYY'}
                  value={endDate}
                  disabled={true}
                />
              </Col>
              <Col>
                <TimePicker allowClear={false} format={'HH:mm'} value={endDate} disabled={true} />
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default DateRangePicker;
