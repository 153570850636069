import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import useClinicId from '../hooks/useClinicId';
import { useLocale } from '../hooks/useLocale';
import useLinkParams from '../hooks/useSearchParams';

const { Search } = Input;

const VLXCompositeSearch = ({
  action,
  setSearchItemsArray,
  onKeyDown,
  customPageNumber = null
}: any) => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const filterParams = useLinkParams('filter');
  const locale = useLocale('private.composite-search');
  const [searchValue, setSearchValue] = useState<string>(filterParams);

  useEffect(() => {
    if (clinicId) onSearch(filterParams);
  }, [clinicId, filterParams]);

  function pushHistoryState(filterValue) {
    if (window.history.pushState) {
      if (filterValue === '') {
        const newUrl = window.location.pathname;
        window.history.pushState({ path: newUrl }, '', newUrl);
        return;
      }
      const newUrl = `${window.location.pathname}?filter=${filterValue}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }

  const onSearch = (value: any) => {
    const searchArray = [];
    const finalValue = value || '';

    finalValue
      .toString()
      .split(' ')
      .forEach((criteria) => {
        if (criteria && criteria !== '') {
          searchArray.push({
            name: 'composite',
            value: criteria,
            isArrayValue: true
          });
        }
      });

    setSearchItemsArray?.(searchArray);
    setSearchValue(finalValue);
    pushHistoryState(finalValue);
    dispatch(action(clinicId, customPageNumber !== null ? customPageNumber : 1, searchArray));
  };

  return (
    <Search
      placeholder={locale.placeholders.searchCriteria}
      onKeyDown={onKeyDown}
      className='search-field'
      onSearch={onSearch}
      enterButton
      allowClear
      defaultValue={searchValue}
    />
  );
};

export default VLXCompositeSearch;
