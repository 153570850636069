import { AxiosResponse } from 'axios';
import { ISortObject } from 'services/interfaces/ISortObject.ts';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { CreateUpdatePaymentRequest } from './models/CreateUpdatePaymentRequest';
import { IndexPaymentsResponse } from './models/IndexPaymentsResponse';
import { ShowPaymentResponse } from './models/ShowPaymentResponse';

function url(clinic_id) {
  return `/v1/clinics/${clinic_id}/payments`;
}

export const createClinicPayment = async (clinicId: number, data: CreateUpdatePaymentRequest) =>
  baseClient.post(url(clinicId), data).then((response: AxiosResponse<ShowPaymentResponse>) => {
    return response.data;
  });

export const updateClinicPayment = async (
  clinicId: number,
  paymentId: number,
  data: CreateUpdatePaymentRequest
) =>
  baseClient
    .put(`${url(clinicId)}/${paymentId}`, data)
    .then((response: AxiosResponse<ShowPaymentResponse>) => {
      return response.data;
    });

export const showClinicPayment = async (clinicId: number, paymentId: number) =>
  baseClient
    .get(`${url(clinicId)}/${paymentId}`)
    .then((response: AxiosResponse<ShowPaymentResponse>) => {
      return response.data;
    });

export const destroyClinicPayment = async (clinicId: number, paymentId: number) =>
  baseClient.delete(`${url(clinicId)}/${paymentId}`).then((response: any) => {
    return response.data;
  });

export const indexClinicPayments = async (clinicId: number, page: number, searchArray?: any[], sortObject?: ISortObject) =>
  baseClient
    .get(`${url(clinicId)}?page=${page}${generateSearchQuery(searchArray)}${sorting(sortObject)}`)
    .then((response: AxiosResponse<IndexPaymentsResponse>) => {
      return response.data;
    });
