import './BaseLayout.scss';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Layout, Row, Select } from 'antd';
import { useGetNetworkStatus } from 'hooks/useGetNetworkStatus';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import clinicDefaultLogo from '../../assets/img/clinicDefaultLogo.png';
import LogoMobile from '../../assets/img/logo-mobile.svg';
import LogoSvg from '../../assets/img/logo.svg';
import useClinicId from '../../hooks/useClinicId';
import { IAppState } from '../../interfaces/app-state';
import { setSelectedClinicId, toggleSidebar } from '../../redux/settings/actions';
import Actions from '../components/actions/Actions';
import FooterItems from '../components/footer/Footer';
import Logo from '../components/logo/Logo';
import VerticalLayout from '../vertical/Vertical';

const { Header, Content, Footer } = Layout;
const { Option } = Select;

const DAY_IN_MS = 1000 * 60 * 60 * 24;

const BaseLayout = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const clinicId = useClinicId();

  useGetNetworkStatus();

  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [openedMenuItem, setOpenedMenuItem] = useState<number>();

  const settings = useSelector(({ settings }: IAppState) => settings);
  const pageData = useSelector(({ pageData }: IAppState) => pageData);
  const user = useSelector(({ user }: IAppState) => user);
  const authorized = user.id;
  const clinics = user.userAccesses;
  const selectedClinicId = useSelector(({ settings }: IAppState) => settings.selectedClinicId);

  useEffect(() => {
    if (shouldRefresh) return;

    const id = setTimeout(() => setShouldRefresh(true), DAY_IN_MS);

    return () => {
      clearTimeout(id);
    };
  }, [shouldRefresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (shouldRefresh) {
      window.location.reload();
      setShouldRefresh(false);
    }
  }, [location]);

  const toggleSettings = () => setShowSettings(!showSettings);

  const onClinicSelect = (clinicId: number): void => {
    dispatch({ type: 'RESET_STORE' });
    navigate(`clinic/${clinicId}/administration/info`);
    dispatch(setSelectedClinicId(clinicId));
  };

  const clinicsList = useMemo(
    (): JSX.Element[] =>
      clinics.map((item) => (
        <Option
          className='clinic-select-option clinic-select-option__in-header'
          key={`${item.clinicName}${item.clinicId}`}
          title={item.clinicName}
          value={item.clinicId}
        >
          <div className='clinic-select-option__logo-container'>
            <img src={item?.clinicAvatar ? item?.clinicAvatar : clinicDefaultLogo} />
          </div>
          <div className='clinic-select-option__name-container'> {item.clinicName}</div>
        </Option>
      )),
    [clinics]
  );

  useEffect(() => {
    if (clinicId) {
      if (clinics?.some((el) => el.clinicId === clinicId)) {
        setOpenedMenuItem(clinicId);
        if (selectedClinicId !== clinicId) {
          dispatch(setSelectedClinicId(clinicId));
        }
      }
    } else {
      if (selectedClinicId) {
        setOpenedMenuItem(selectedClinicId);
      } else if (clinics.length > 0) {
        setOpenedMenuItem(clinics[0].clinicId);
        dispatch(setSelectedClinicId(clinics[0].clinicId));
      }
    }
  }, [clinicId, location, clinics, selectedClinicId]);

  return (
    <Layout style={{ minHeight: '100%' }}>
      <Header>
        <Row
          align='middle'
          className={
            authorized ? 'header-container header-container__authorized' : 'header-container'
          }
        >
          <Col span={'auto'} sm={authorized ? 8 : 11} lg={0}>
            <Button
              type='link'
              size='large'
              style={{ color: 'white' }}
              icon={settings.sidebarOpened ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              onClick={() => dispatch(toggleSidebar())}
            />
          </Col>
          <Row align={'middle'} className='header-container__left-side'>
            <Col className={'header-left-col'}>
              <Logo src={LogoSvg} className='logo-desktop' />
              <Logo src={LogoMobile} className='logo-mobile' />
              {/* {authorized ? <></> : <HorizontalLayout />} */}
            </Col>
            {!!authorized && clinics?.length > 0 && (
              <>
                <div className='header-pipe'>|</div>
                <Select className='clinic-select' onSelect={onClinicSelect} value={openedMenuItem}>
                  {clinicsList}
                </Select>
              </>
            )}
          </Row>
          <Col
            style={{ minWidth: 40 }}
            span={'auto'}
            sm={authorized ? 8 : 10}
            md={authorized ? 8 : 11}
            lg={authorized ? 12 : 16}
            xl={14}
            className={'header-actions-col'}
          >
            <Actions />
          </Col>
        </Row>
      </Header>
      <Layout style={{ alignItems: 'stretch' }}>
        <VerticalLayout />
        <Content className={!authorized ? 'vlx-content-area-unauthorized' : 'vlx-content-area'}>
          {pageData?.title && (
            <Breadcrumb
              separator='>'
              style={{ marginBottom: '10px', marginTop: '5px' }}
              items={[
                ...pageData.breadcrumbs.map((item) => ({
                  title: <Link to={item.route}>{item.title}</Link>
                })),
                { title: pageData.title }
              ]}
            ></Breadcrumb>
          )}
          <Outlet />
        </Content>
      </Layout>
      <Footer>
        <FooterItems openModal={toggleSettings} />
      </Footer>
      {/* <div className={`app-container ${!authorized && 'boxed'} ${scrolled && 'scrolled'}`}>
            <div id='main' style={{ margin: '-20px 0 20px' }}/>
          </div>
        {contentOverlay}
        <Modal
          open={showSettings}
          onCancel={toggleSettings}
          footer={null}
          title={
            <h3 className='m-0' style={{ opacity: 0.8 }}>
              Settings
            </h3>
          }
        >
          <SettingsForm
            settings={settings}
            onResetSettings={onResetSettings}
            onUpdateSetting={onUpdateSettings}
          />
        </Modal>
      </div> */}
    </Layout>
  );
};

export default BaseLayout;
