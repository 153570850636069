import { AxiosResponse } from 'axios';
import { ITranferAccount } from 'interfaces/Account.ts';

import { ISearchCriteria } from '../../../interfaces/search-criteria.ts';
import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { ISortObject } from '../../interfaces/ISortObject.ts';
import { CreateUpdateAccountRequest } from './models/CreateUpdateAccountRequest';
import { IndexAccountsResponse } from './models/IndexAccountsResponse';
import { ShowAccountResponse } from './models/ShowAccountResponse';

function url(clinic_id) {
  return `/v1/clinics/${clinic_id}/accounts`;
}

export const createClinicAccount = async (clinicId: number, data: CreateUpdateAccountRequest) =>
  baseClient.post(url(clinicId), data).then((response: AxiosResponse<ShowAccountResponse>) => {
    return response.data;
  });

export const updateClinicAccount = async (
  clinicId: number,
  accountId: number,
  data: CreateUpdateAccountRequest
) =>
  baseClient
    .put(`${url(clinicId)}/${accountId}`, data)
    .then((response: AxiosResponse<ShowAccountResponse>) => {
      return response.data;
    });

export const showClinicAccount = async (clinicId: number, accountId: number) =>
  baseClient
    .get(`${url(clinicId)}/${accountId}`)
    .then((response: AxiosResponse<ShowAccountResponse>) => {
      return response.data;
    });

export const showClinicAccountPayments = async (clinicId: number, accountId: number, sortObject?: ISortObject) =>
  baseClient.get(`${url(clinicId)}/${accountId}/payments?page=0&sort_key=${sortObject.sort_key}&sort_order=${sortObject.sort_order}`).then((response: AxiosResponse<any>) => {
    return response.data.data;
  });

export const destroyClinicAccount = async (clinicId: number, accountId: number) =>
  baseClient.delete(`${url(clinicId)}/${accountId}`).then((response: any) => {
    return response.data;
  });

export const indexClinicAccounts = async (clinicId: number, page?: number, searchArray?: ISearchCriteria[], sortObject?: ISortObject) =>
  baseClient
    .get(`${url(clinicId)}?page=${page}${generateSearchQuery(searchArray)}${sorting(sortObject)}`)
    .then((response: AxiosResponse<IndexAccountsResponse>) => {
      return response.data;
    });

export const transferClinicAccounts = async (clinicId: number, accountId: number, data: ITranferAccount) =>
  baseClient
    .post(`${url(clinicId)}/${accountId}/transfers`, data)
    .then((response: AxiosResponse<IndexAccountsResponse>) => {
      return response.data;
    });
