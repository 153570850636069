import './scss/HealthRecordsHistory.scss';

import { Spin, Timeline } from 'antd';
import calendar from 'assets/img/calendarWhite.svg';
import pharmacy from 'assets/img/pharmacyWhite.svg';
import { money } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import { IClientHistory } from 'interfaces/client';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getClinicClientHistory } from 'services/clinic/clients/clientsService';

import { appointmentStatuses } from '../constants/dictionary/default/selectOptions.ts';
import useLocalizedList from '../hooks/useLocalizedList.tsx';
import { renderAppointmentStatus } from '../pages/appointments/AppointmentsBaseHelper.tsx';

type Props = {
  clinicId: number;
  clientId?: number;
};

const ClientHistory = ({ clinicId, clientId }: Props): JSX.Element => {
  const locale = useLocale('private.appointments.event');
  const currencies = useLocale('private.currencies').labels;
  const localizedStatuses = useLocalizedList(appointmentStatuses);

  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getClinicClientHistory(clinicId, clientId, {sort_key: 'event_time', sort_order: 'desc'})
      .then(({ data }) => { setHistoryData(data); setLoading(false); })
      .catch((e: Error) => setLoading(false));
  }, []);

  const getItemIcon = (type: string): ReactNode => {
    switch (type) {
      case 'payment':
        return <i style={{ color: 'white' }} className="icofont-money"></i>;
      case 'sale':
        return <img src={pharmacy} />;
      default:
        return <img src={calendar} />;
    }
  };

  const renderHistoryLink = (el: IClientHistory): string => {
    switch (el.type) {
      case 'payment':
        return `/clinic/${clinicId}/finances/payments/${el?.entity_id}`;
      case 'sale':
        return `/clinic/${clinicId}/shop-farmacy/${el?.entity_id}`;
      default:
        return `/clinic/${clinicId}/appointments/${el?.entity_id}`;
    };
  };

  const renderHistoryType = (historyItem: IClientHistory): JSX.Element => {
    switch (historyItem.type) {
      case 'payment':
        return <span>{locale.labels.payment}</span>;
      case 'sale':
        return <span>{locale.labels.sale}</span>;
      default:
        return <>
          <span>{locale.labels.appointment}</span>
          {renderAppointmentStatus(historyItem.status, localizedStatuses)}
        </>;
    }
  };

  return (
    <Spin spinning={loading}>
      {!historyData?.length && (
        <div className='history-container__no-data'>
          <p>{locale.labels.noData}</p>
        </div>
      )}
      <div className='history-container'>
        <Timeline
          items={historyData?.map((el: IClientHistory) => {
            return {
              dot: (
                <div className='history-container__icon-container'>
                  {getItemIcon(el.type)}
                </div>
              ),
              children: (
                <>
                  <Link
                    style={{ paddingTop: 4, display: 'block' }}
                    to={renderHistoryLink(el)}
                    className='history-container__services-title custom-links d-flex align-baseline'
                  >
                    {renderHistoryType(el)}
                  </Link>
                  <p className='history-container__time'>
                    {moment(el?.event_time).format('HH:mm DD MMM, YYYY')}
                  </p>
                  {el.type !== 'payment' && <p>
                    <strong>{locale.labels.sum}: </strong>
                    {`${money(el.total_billed_amount)} ${currencies.uah}`}
                  </p>}
                  <p>
                    <strong>{locale.labels.payed}: </strong>
                    {`${money(el.total_paid_amount)} ${currencies.uah}`}
                  </p>
                </>
              )
            };
          })}
        />
      </div>
    </Spin>
  );
};

export default ClientHistory;
