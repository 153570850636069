import { Tag } from 'antd';

export const defineStatus = (status: string, locale): JSX.Element | '-' => {
  if (!status) return '-';

  const color = {
    surplus: 'yellow',
    adequate: 'green',
    shortage: 'red'
  }[status];

  return (
    <Tag id='appointment-status' color={color}>
      {locale.labels[status]}
    </Tag>
  );
};
