import { Spin } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPatientDetails, removePatient } from 'redux/patients/actions';

import PatientForm from './ViewAndEditPatientForm';

const AddPatient = ({
  patientId,
  clinicId,
  initialValues,
  form,
  formEditMode,
  getClientPetsAfterSuccess,
  closeModal,
  fixedBtns
}: any) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const loading = useSelector<IAppState, any>((state) => state.patients.loading);
  const navigate = useNavigate();

  const toggleModal = () => {
    if (closeModal) closeModal();
  };

  const onConfirmCancelClick = () => {
    setShowCancelModal(false);
    form.resetFields();
    dispatch(getPatientDetails(clinicId, patientId));
    toggleModal();
  };

  const onConfirmDeleteClick = () => {
    dispatch(removePatient(clinicId, patientId));
    navigate(`/clinic/${clinicId}/patients`);
  };

  return (
    <>
      <Spin spinning={loading}>
        <PatientForm
          form={form}
          initialValues={initialValues}
          formEditMode={formEditMode}
          getClientPetsAfterSuccess={getClientPetsAfterSuccess}
        >
          <ViewDetailsButtonsGroup
            accessGroup={userAccess.accesses.patients}
            editMode
            fixed={fixedBtns}
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowDeleteModal(false);
                setShowCancelModal(true);
              } else {
                toggleModal();
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
              setShowDeleteModal(true);
            }}
          />
        </PatientForm>
      </Spin>
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};

export default AddPatient;
