import './AddFinancialAccount.scss';

import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  financialAccountsTypesOptions,
  financialAcountsOptions,
} from '../../../../constants/dictionary/default/selectOptions';
import useClinicId from '../../../../hooks/useClinicId';
import useLocalizedList from '../../../../hooks/useLocalizedList';
import { createAccount, updateAccount } from '../../../../redux/finances/financialAccounts/actions';

const { TextArea } = Input;

const AddfinancialAccountForm = ({
  form,
  initialValues,
  formEditMode,
  children,
  onFieldsChange,
  locale,
  editMode
}: any) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(
    initialValues?.account_type
  );

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onSubmit = (data: any) => {
    const apiData = data;
    apiData.account_number = data.account_number ? data.account_number : null;
    if (formEditMode) {
      dispatch(updateAccount(clinicId, initialValues.id, apiData));
    } else {
      dispatch(createAccount(clinicId, apiData));
    }
  };

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={initialValues}
    >
      <Form.Item name='clinic_id' hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.name} name={'name'} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.accountType}
            rules={[{ required: true }]}
            name='account_type'
          >
            <Select
              value={selectedAccountType}
              onChange={(e) => setSelectedAccountType(e)}
              options={useLocalizedList(financialAccountsTypesOptions)}
            />
          </Form.Item>
          {selectedAccountType === 'bank' && (
            <Form.Item
              label={locale.labels.number}
              rules={[{ required: true }]}
              name='account_number'
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name='status'
            rules={[{ required: true }]}
            label={locale.labels.status}
            initialValue={initialValues?.status ? initialValues?.status : 'active'}
          >
            <Select filterOption={false} options={useLocalizedList(financialAcountsOptions)} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.description}
            name='description'
            rules={[{ max: 255, message: locale.errors.enterMaxFieldValueLength_1_255 }]}
          >
            <TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label={locale.labels.notes}
            name='notes'
            rules={[{ max: 255, message: locale.errors.enterMaxFieldValueLength_1_255 }]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      {children}
    </Form>
  );
};

export default AddfinancialAccountForm;
