import './AppointmentDetails.scss';

import { Col, Form, FormInstance, Row, Select } from 'antd';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAppointmentDetailsPatientHealthRecords,
  putAppointmentDetailsPatientHealthRecords
} from 'redux/appointments/actions';
import { getServices } from 'redux/services/actions';

import { IAppointment } from '../../interfaces/appointment.ts';
import { IHealthRecord } from '../../interfaces/health-record.ts';
import { ILocale } from '../../interfaces/locale.ts';

const { Option } = Select;

type Props = {
  form: FormInstance;
  initialValues: IHealthRecord;
  locale: ILocale;
  patientId: number;
  onFieldsChange: () => void;
  appointment: IAppointment;
  disabled: boolean;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  children: React.ReactNode;
};

export const AppointmentDetailsPatientTabServicesForm = ({
  form,
  initialValues,
  children,
  locale,
  patientId,
  onFieldsChange,
  appointment,
  disabled,
  setEditMode
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const services = useSelector<IAppState, any>((state) => state.services.data);

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    setEditMode(!initialValues?.services?.length);
  }, [initialValues]);

  const onSubmit = (data: IHealthRecord): void => {
    const apiData: IHealthRecord = { ...data };
    apiData.anamnesis = initialValues?.anamnesis;
    apiData.diagnosis = initialValues?.diagnosis;
    apiData.complaints = initialValues?.complaints;
    // TODO: refactor this
    if(initialValues?.date_of_next_visit) {
      apiData.date_of_next_visit = dayjs(initialValues?.date_of_next_visit).format('DD.MM.YYYY');
    } else {
      apiData.date_of_next_visit = null;
    }
    apiData.provisional_diagnosis = initialValues?.provisional_diagnosis;
    apiData.no_diagnosis_required = initialValues ? initialValues.no_diagnosis_required : true;
    apiData.patient_state = initialValues?.patient_state;
    apiData.recommendations = initialValues?.recommendations;
    apiData.notes = initialValues?.notes;
    apiData.clinic_id = clinicId;
    if (!initialValues?.id) {
      dispatch(
        addAppointmentDetailsPatientHealthRecords(clinicId, appointment?.id, patientId, apiData)
      );
    } else {
      dispatch(
        putAppointmentDetailsPatientHealthRecords(
          clinicId,
          patientId,
          initialValues.id,
          appointment?.id,
          apiData
        )
      );
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [initialValues, patientId]);

  useEffect(() => {
    if (clinicId) {
      dispatch(getServices(clinicId, 0));
    }
  }, [clinicId]);

  const servicesList = useMemo(
    () =>
      services
        .filter((s) => !s.deleted || initialValues?.services.some((ser) => ser.id === s.id))
        .map((item) => (
          <Option key={item.name} title={item.name} value={item.id}>
            {item.name}
          </Option>
        )),
    [services]
  );

  const mapValues = () => {
    const data = { services_ids: [] };
    if (!initialValues?.services?.length && appointment?.services) {
      data.services_ids = appointment?.services.map((el: any) => el.id);
    } else if (initialValues?.services?.length > 0) {
      data.services_ids = initialValues.services.map((el: any) => el.id);
    }
    return data;
  };

  return (
    <Form
      onFieldsChange={onFieldsChange}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={mapValues()}
      disabled={disabled}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            className='add-appointment-form__multi-select-field'
            label={locale.labels.servicesProvided}
            rules={[{ required: true }]}
            name='services_ids'
          >
            <Select
              mode='multiple'
              filterOption={(input, option) => {
                return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
              }}
              showSearch
            >
              {servicesList}
            </Select>
          </Form.Item>
        </Col>
        <Col className={'appointment-services-buttons'} span={4} md={4}>
          {children}
        </Col>
      </Row>
    </Form>
  );
};
