import './AddBill.scss';

import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import VLXInputNumber from '../../../../components/VLXInputNumber';
import { billType, yesNoBooleanOptions } from '../../../../constants/dictionary/default/selectOptions';
import useClinicId from '../../../../hooks/useClinicId';
import useLocalizedList from '../../../../hooks/useLocalizedList';
import { IAppState } from '../../../../interfaces/app-state';
import { indexBillPaymentTypes } from '../../../../redux/dictionaries/bill-payment-types/actions';
import { showModal } from '../../../../redux/dictionaries/dictionary/actions';
import { createBill, updateBill } from '../../../../redux/finances/bills/actions';
import AddDictionary from '../../addDictionary/AddDictionary';

const { TextArea } = Input;
const { Option } = Select;

const EditBillFormAppointmentView = ({
  form,
  initialValues,
  formEditMode,
  children,
  onFieldsChange,
  locale,
  setFormIsTouched
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const billPaymentTypes = useSelector<IAppState, any>((state) => state.billPaymentTypes.data);
  const [stockTotalPrice, setStockTotalPrice] = useState(
    (
      Number(initialValues?.total_stock_price) - Number(initialValues?.stock_discount?.amount)
    ).toFixed(2) || 0
  );
  const [servicesTotalPrice, setServicesTotalPrice] = useState(
    (
      Number(initialValues?.total_services_price) - Number(initialValues?.services_discount?.amount)
    ).toFixed(2) || 0
  );
  const [billCategorySearchValue, setBillCategoryearchValue] = useState('');

  useEffect(() => {
    form.resetFields();
    dispatch(indexBillPaymentTypes(clinicId, 0));
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onServiceDiscountPercentageChange = (val) => {
    if (val === null) {
      form.setFieldValue(['services_discount', 'amount'], null);
      setServicesTotalPrice(Number(initialValues?.total_services_price).toFixed(2));
      form.setFieldValue(
        'final_amount',
        Number(initialValues?.total_services_price) + Number(stockTotalPrice)
      );
      return;
    }
    const summ = initialValues?.total_services_price;
    const res = summ * val * 0.01;
    const total = (summ - res).toFixed(2);
    form.setFieldValue(['services_discount', 'amount'], res.toFixed(2));
    setServicesTotalPrice(total);
    form.setFieldValue('final_amount', Number(total) + Number(stockTotalPrice));
  };

  const onServiceDiscountAmountChange = (val) => {
    if (val === null) {
      form.setFieldValue(['services_discount', 'percentage'], null);
      setServicesTotalPrice(Number(initialValues?.total_services_price).toFixed(2));
      form.setFieldValue(
        'final_amount',
        Number(initialValues?.total_services_price) + Number(stockTotalPrice)
      );
      return;
    }
    const summ = initialValues?.total_services_price;
    const res = (val / summ) * 100;
    const total = (summ - val).toFixed(2);
    form.setFieldValue(['services_discount', 'percentage'], Number(res).toFixed(0));
    setServicesTotalPrice(total);
    form.setFieldValue('final_amount', (Number(total) + Number(stockTotalPrice)).toFixed(2));
  };

  const onStockDiscountPercentageChange = (val) => {
    if (val === null) {
      form.setFieldValue(['stock_discount', 'amount'], null);
      setStockTotalPrice(Number(initialValues?.total_stock_price).toFixed(2));
      form.setFieldValue(
        'final_amount',
        (Number(initialValues?.total_stock_price) + Number(servicesTotalPrice)).toFixed(2)
      );
      return;
    }
    const summ = initialValues?.total_stock_price;
    const res = summ * val * 0.01;
    const total = (summ - res).toFixed(2);
    form.setFieldValue(['stock_discount', 'amount'], res.toFixed(2));
    setStockTotalPrice(total);
    form.setFieldValue('final_amount', (Number(total) + Number(servicesTotalPrice)).toFixed(2));
  };

  const onStockDiscountAmountChange = (val) => {
    if (val === null) {
      form.setFieldValue(['stock_discount', 'percentage'], null);
      setStockTotalPrice(Number(initialValues?.total_stock_price).toFixed(2));
      form.setFieldValue(
        'amount',
        Number(initialValues?.total_stock_price) + Number(servicesTotalPrice)
      );
      return;
    }
    const summ = initialValues?.total_stock_price;
    const res = (val / summ) * 100;
    const total = (summ - val).toFixed(2);
    form.setFieldValue(['stock_discount', 'percentage'], Number(res).toFixed(0));
    setStockTotalPrice(total);
    form.setFieldValue('amount', (Number(total) + Number(servicesTotalPrice)).toFixed(2));
  };

  const onAmountChange = (val) => {
    form.setFieldValue(['stock_discount', 'amount'], null);
    form.setFieldValue(['stock_discount', 'percentage'], null);
    form.setFieldValue(['final_amount'], Number(val).toFixed(2));
    form.setFields([
      {
        name: ['stock_discount', 'amount'],
        errors: []
      },
      {
        name: ['stock_discount', 'percentage'],
        errors: []
      }
    ]);
  };

  const onSubmit = (data: any) => {
    const apiData = { ...data };
    if (data?.final_amount || data?.final_amount === 0) {
      apiData.final_amount = Number(data.final_amount).toFixed(2);
    }
    if (data?.final_amount || data?.final_amount === 0) {
      apiData.amount = Number(data.final_amount).toFixed(2);
    }
    // apiData.fiscal = data?.fiscal;
    apiData.services_discount.amount = data?.services_discount?.amount
      ? Number(data.services_discount.amount).toFixed(2)
      : null;
    apiData.services_discount.percentage = data?.services_discount?.percentage
      ? Number(data.services_discount.percentage)
      : null;
    apiData.stock_discount.amount = data?.stock_discount?.amount
      ? Number(data.stock_discount.amount).toFixed(2)
      : null;
    apiData.stock_discount.percentage = data?.stock_discount?.percentage
      ? Number(data.stock_discount.percentage)
      : null;
    if (data.date) {
      apiData.date = dayjs(data.date).format('DD.MM.YYYYThh:mm:ss');
    }
    if (formEditMode) {
      if (initialValues?.client?.id) {
        apiData.client_id = initialValues?.client?.id;
      }
      dispatch(updateBill(clinicId, initialValues.id, apiData));
    } else {
      dispatch(createBill(clinicId, apiData));
    }
  };

  const yesNoBooleanLocalizedOptions = useLocalizedList(yesNoBooleanOptions);

  const getInitValues = () => {
    const data = { ...initialValues };
    if (initialValues?.date) {
      data.date = dayjs(data.date);
    }
    return data;
  };

  const billCategories = billPaymentTypes.map((item) => {
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item.name}
      </Option>
    );
  });

  const filterBillCategoryOption = (input: string, option) => {
    if (option?.title.toLowerCase().includes(input.toLocaleLowerCase())) {
      return true;
    }
    return false;
  };

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={getInitValues()}
    >
      <Form.Item name='clinic_id' hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.name} name={'name'} rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={locale.labels.amount} rules={[{ required: true }]} name='amount'>
            <VLXInputNumber
              placeholder='0'
              type='number'
              disabled
              precision={2}
              step={0.1}
              onChange={(value) => {
                form.setFieldsValue({ amount: value });
                onAmountChange(value);
              }}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name='vat_included'
            label={locale.labels.vatIncluded}
          >
            <Select filterOption={false} options={useLocalizedList(yesNoBooleanLocalizedOptions)} />
          </Form.Item>
          <Form.Item
            name='bill_type'
            hidden={formEditMode}
            rules={[{ required: true }]}
            label={locale.labels.billType}
          >
            <Select
              disabled={formEditMode}
              filterOption={false}
              options={useLocalizedList(billType)}
            />
          </Form.Item>
          <Form.Item name='bill_payment_type_id' label={locale.labels.category}>
            <Select
              showSearch
              onSearch={(val) => setBillCategoryearchValue(val)}
              filterOption={filterBillCategoryOption}
              notFoundContent={
                <div onClick={() => dispatch(showModal(true))} id='addNewServiceGroupBtn'>
                  + {locale.labels.addBillCategory}
                </div>
              }
            >
              {billCategories}
            </Select>
          </Form.Item>
          {/* <Form.Item
            className={'fiscal-checkbox-form-item'}
            name='fiscal'
            label={locale.labels.fiscal}
            valuePropName='checked'
            initialValue={initialValues?.fiscal || false}
          >
            <Checkbox />
          </Form.Item> */}
        </Col>
        <Col span={24} md={12}>
          {initialValues?.appointment && (
            <>
              <h5 style={{ marginTop: 0, fontSize: 16, fontWeight: 600 }}>
                {locale.labels.services}
              </h5>
              <Row>
                <Col span={24} md={12} style={{ padding: '0 8px' }}>
                  <Form.Item
                    name={['services_discount', 'percentage']}
                    label={locale.labels.percentage}
                    initialValue={Number(initialValues?.services_discount?.percentage)}
                  >
                    <VLXInputNumber
                      id='services_discount_percentage'
                      placeholder='0'
                      max={100}
                      disabled={
                        initialValues?.total_services_price === 0 ||
                        initialValues?.payment_status === 'paid'
                      }
                      type='number'
                      precision={0}
                      onChange={(value) => onServiceDiscountPercentageChange(value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12} style={{ padding: '0 8px' }}>
                  <Form.Item
                    initialValue={Number(initialValues?.services_discount?.amount).toFixed(2)}
                    name={['services_discount', 'amount']}
                    label={locale.labels.discount}
                  >
                    <VLXInputNumber
                      id='services_discount_amount'
                      placeholder='0'
                      type='number'
                      disabled={
                        initialValues?.total_services_price === 0 ||
                        initialValues?.payment_status === 'paid'
                      }
                      max={Number(initialValues?.total_services_price)?.toFixed(2) || 0}
                      precision={2}
                      step={0.1}
                      onChange={(value) => onServiceDiscountAmountChange(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {initialValues?.appointment && (
            <h5 style={{ marginTop: 10, fontSize: 16, fontWeight: 600 }}>{locale.labels.stock}</h5>
          )}
          <Row>
            <Col span={24} md={12} style={{ padding: '0 8px' }}>
              <Form.Item
                name={['stock_discount', 'percentage']}
                label={locale.labels.percentage}
                initialValue={Number(initialValues?.stock_discount?.percentage)}
              >
                <VLXInputNumber
                  id='stock_discount_percentage'
                  placeholder='0'
                  max={100}
                  type='number'
                  precision={0}
                  onChange={(value) => onStockDiscountPercentageChange(value)}
                  disabled={
                    initialValues?.total_stock_price === 0 ||
                    initialValues?.payment_status === 'paid'
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12} style={{ padding: '0 8px' }}>
              <Form.Item
                name={['stock_discount', 'amount']}
                label={locale.labels.discount}
                initialValue={Number(initialValues?.stock_discount?.amount).toFixed(2)}
              >
                <VLXInputNumber
                  id='stock_discount_amount'
                  placeholder='0'
                  type='number'
                  max={Number(initialValues?.total_stock_price)?.toFixed(2) || 0}
                  precision={2}
                  step={0.1}
                  onChange={(value) => onStockDiscountAmountChange(value)}
                  disabled={
                    initialValues?.total_stock_price === 0 ||
                    initialValues?.payment_status === 'paid'
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name={'date'}
            initialValue={initialValues?.date ? initialValues?.date : dayjs()}
            label={locale.labels.date}
          >
            <DatePicker format='YYYY-MM-DD' />
          </Form.Item>
          <Form.Item label={locale.labels.finalAmount} name={'final_amount'}>
            <VLXInputNumber placeholder='0' disabled type='number' precision={2} step={0.1} />
          </Form.Item>
          <Form.Item
            label={locale.labels.description}
            name='description'
            rules={[{ max: 255, message: locale.errors.enterMaxFieldValueLength_255 }]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      {children}
      <AddDictionary
        defaultDictionaryNameValue={billCategorySearchValue}
        popupTitle={locale.labels.addBillCategory}
        actionType={'bill_category'}
        hideCreateBtn
        callBackAfterCreate={(data) => {
          dispatch(
            indexBillPaymentTypes(clinicId, 0, [], () => {
              form.setFieldValue('bill_payment_type_id', data?.id);
              onFieldsChange([{ bill_payment_type_id: data?.id }]);
              setFormIsTouched(true);
            })
          );
        }}
      />
    </Form>
  );
};

export default EditBillFormAppointmentView;
