import { Spin } from 'antd';
import { Form } from 'antd/lib';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toogleShowTransferModal } from 'redux/finances/financialAccounts/actions';

import { TransferAccountsForm } from './TransferAccountsForm';

type Props = {
  currentAccountId: number;
};

export const TransferAccounts = ({
  currentAccountId
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const locale = useLocale('private.finances.bills');
  const financialLocale = useLocale('private.finances.financial-accounts');
  const [form] = Form.useForm();

  const financialAccounts = useSelector(({ financialAccounts }: IAppState) => financialAccounts);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(toogleShowTransferModal(false));
    setShowCancelModal(false);
  };

  return (
    <VLXModal
      title={financialLocale.buttons.transferBetweenAccounts}
      centered
      form={form}
      open={financialAccounts.showTransferModal}
      onModalClose={() => dispatch(toogleShowTransferModal(false))}
    >
      <Spin spinning={financialAccounts?.loading}>
        {financialAccounts.showTransferModal && (
          <TransferAccountsForm
            locale={locale}
            financialLocale={financialLocale}
            form={form}
            currentAccountId={currentAccountId}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.financialAccounts}
              editMode
              hideDeleteBtn
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(toogleShowTransferModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </TransferAccountsForm>
        )}
      </Spin>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};

