import './scss/Provider.scss';

import { Button, Card, Col, Menu, MenuProps, Row, Spin } from 'antd';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router-dom';
import {
  getProviderById,
  getProviders,
  setSelectedProviderInvoicesData,
  setShowEditProviderModal,
} from 'redux/inventory/providers/actions';
import { ProvidersListState } from 'redux/inventory/providers/reducer';

import { EditProvider } from './modal/EditProvider';
import { ProviderHistoryTab } from './ProviderHistoryTab';
import { getMailView, getPhoneView } from './utils/utils';

 export const ProviderDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, providerId }: Readonly<Params<string>> = useParams();
  const userAccess = useUserAccess();
  const locale = useLocale('private.inventory.providers'); 

  const [currentTab, setCurrentTab] = useState('history');
  
  const { selectedProviderData } = useSelector<IAppState, ProvidersListState>(({ providers }) => providers);
  const { loading } = useSelector<IAppState, ProvidersListState>(({ providers }) => providers);

  useEffect((): void => {
    if (clinicId) {
      dispatch(getProviders(Number(clinicId), 1));
    }
  }, [clinicId]);

  useEffect(() => {
    dispatch(getProviderById(Number(clinicId), Number(providerId)));

    return () => {
      dispatch(setSelectedProviderInvoicesData(null));
    };
  }, []);

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const changeTab: MenuProps['onClick'] = (e) => {
    setCurrentTab(e.key);
  };

  return (
    <Spin spinning={loading}> 
      {!!selectedProviderData && (
        <> 
          <Row className='patient-details'>
            <Col span={24} md={11} lg={8} style={{ padding: '0 12px' }}>
              <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container stock-details-info-container provider-info-container'>
                    <Row align='middle' className='provider-info-container__row'>
                      <Col className='provider-info-container__col-left'>
                        <span className='icofont-briefcase-1 info-icon' />
                      </Col>
                      <Col className='provider-info-container__col-right'>
                        {selectedProviderData.name}
                      </Col>
                    </Row>

                    <Row align='middle' className='provider-info-container__row'>
                      <Col className='provider-info-container__col-left'>
                        <span className='icofont-ui-touch-phone info-icon' />
                      </Col>
                      <Col className='provider-info-container__col-right'>
                         {getPhoneView(selectedProviderData.phone_numbers)}
                      </Col>
                    </Row>

                    <Row align='middle' className='provider-info-container__row'>
                      <Col className='provider-info-container__col-left'>
                        <span className='icofont-ui-message info-icon' />
                      </Col>
                      <Col className='provider-info-container__col-right'>
                         {getMailView(selectedProviderData.emails)}
                      </Col>
                    </Row>

                    {selectedProviderData.address && <Row align='middle' className='provider-info-container__row'>
                      <Col className='provider-info-container__col-left'>
                        <span className='icofont-location-pin info-icon' />
                      </Col>
                      <Col className='provider-info-container__col-right'>
                         {selectedProviderData.address}
                      </Col>
                    </Row>}

                    {selectedProviderData.comments && <Row align='middle' className='provider-info-container__row'>
                      <Col className='provider-info-container__col-left'>
                        <span className='icofont-comment info-icon' />
                      </Col>
                      <Col className='provider-info-container__col-right'>
                        {selectedProviderData.comments}
                      </Col>
                    </Row>}
 
                  <div className='provider-info-container__edit-button-container'>
                    <Button
                    type='primary'
                    style={{ borderRadius: 30 }}
                    onClick={() => dispatch(setShowEditProviderModal(true))}
                  >
                    <span
                      className='icofont icofont-edit-alt mr-2 sticky-btn-icon'
                      style={{ fontSize: '1.1em' }}
                    />
                    <span>{locale.buttons.edit}</span>
                  </Button>
                  </div> 
              </Card>
            </Col>
            <Col span={24} md={13} lg={16} style={{ padding: '0 12px' }}>
              <Card className='ant-card-bordered personal-info-card with-shadow'>
                <Menu
                  onClick={changeTab}
                  selectedKeys={[currentTab]}
                  mode='horizontal'
                  items={items}
                  style={{ marginBottom: 20 }}
                />
                {currentTab === 'history' && <ProviderHistoryTab />}
              </Card>
            </Col>
          </Row>
      {userAccess.accesses.providers.edit && <EditProvider initialValues={selectedProviderData}/>}
      </>)}
    </Spin>
  );
}; 