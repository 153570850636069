import './scss/EquipmentList.scss';

import { Form, Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import SearchFiltersHorizontal from 'components/SearchFiltersHorizontal';
import { deviceStatuses } from 'constants/dictionary/default/inventoryOptions';
import { scrollToTop } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEquipment, setSelectedDevice } from 'redux/inventory/equipment/actions';
import { IEquipmentItem } from 'services/interfaces/clinics/inventory/equipment/equipment-index-response';

import AddEquipmentModal from './AddEquipmentModal';
import { deviceStatus } from './helpers/TagSelector';

function getSearchItems(
  placeholders: Record<string, string>
): { name: string; placeholder: string }[] {
  return [
    {
      name: 'name',
      placeholder: placeholders.name
    },
    {
      name: 'serial_number',
      placeholder: placeholders.serialNumber
    },
    {
      name: 'location',
      placeholder: placeholders.location
    },
    {
      name: 'first_name',
      placeholder: placeholders.responsibleFirstName
    },
    {
      name: 'last_name',
      placeholder: placeholders.responsibleLastName
    },
    {
      name: 'device_status',
      placeholder: placeholders.deviceStatus
    },
    {
      name: 'manufacturer',
      placeholder: placeholders.manufacturer
    }
  ];
}

const EquipmentList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const userAccess = useUserAccess();
  const locale = useLocale('private.inventory.technical-equipment');

  const [form] = Form.useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchItemsArray, setSearchItemsArray] = useState([]);
  const { data, metadata } = useSelector(({ equipment }: IAppState) => equipment);
  const loading = useSelector(({ equipment }: IAppState) => equipment.loading);

  const searchItems = useMemo(() => getSearchItems(locale.placeholders), [locale]);

  const onFinish = () => {
    const searchArray = [];
    const object = form.getFieldsValue();
    Object.entries(object).forEach(([key, value]) => {
      if (value && value !== '') {
        searchArray.push({
          name: key,
          value
        });
      }
    });
    setCurrentPage(1);
    setSearchItemsArray(searchArray);
    dispatch(getEquipment(clinicId, 1, searchArray));
  };

  const redirectToEquipmentDetails = (record: any) => {
    if (userAccess.accesses.equipment.showItem) {
      return {
        onClick: () => {
          navigate(`/clinic/${clinicId}/inventory/equipment/${record.id}`);
        }
      };
    }
  };

  const localizedDeviceStatuses = useLocalizedList(deviceStatuses);

  const getDeviceStatus = (e: IEquipmentItem): JSX.Element | string => {
    const res = localizedDeviceStatuses.find((el) => el.value === e);
    return res ? deviceStatus(res) : '';
  };

  const columns = useMemo(
    (): TableColumnProps<IEquipmentItem>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (name, record) => name,
        onCell: redirectToEquipmentDetails
      },
      {
        key: 'device_status',
        dataIndex: 'device_status',
        title: locale.labels.deviceStatus,
        sorter: (a, b) => (a.device_status > b.device_status ? 1 : -1),
        onCell: redirectToEquipmentDetails,
        render: (device_status) => getDeviceStatus(device_status)
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: locale.labels.location,
        sorter: (a, b) => (a.location > b.location ? 1 : -1),
        onCell: redirectToEquipmentDetails,
        render: (location) => location || '-'
      }
    ],
    [data]
  );

  const onPageChange = (page: number): void => {
    dispatch(getEquipment(clinicId, page, searchItemsArray));
    scrollToTop();
  };

  useEffect((): void => {
    if (clinicId) dispatch(getEquipment(clinicId, 1));
  }, [clinicId]);

  useEffect((): void => {
    dispatch(setSelectedDevice(null));
  }, []);

  return (
    <>
      <SearchFiltersHorizontal onFinish={onFinish} formInstance={form} object={searchItems} />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header equipment-list'
          rowKey='id'
          dataSource={data}
          columns={columns}
        />
        <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
          <Pagination
            className={'equipment-pagination'}
            defaultCurrent={1}
            current={+currentPage}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            total={metadata.total}
            onChange={(current, pageSize) => {
              setCurrentPage(current);
              onPageChange(current);
            }}
          />
          {userAccess.accesses.equipment.create && <AddEquipmentModal clinicId={clinicId} />}
        </Row>
      </Spin>
    </>
  );
};

export default EquipmentList;
