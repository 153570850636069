import '../Finances.scss';

import { Form, Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import VLXListCompositeSearch from 'components/VLXListCompositSearch';
import { billTypes, paymentStatuses } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { UseSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import AddBill from 'layout/modals/finances/addBill/AddBill';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBills, resetBills, setSelectedBill } from 'redux/finances/bills/actions';
import { IBillsListState } from 'redux/finances/bills/reducer';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';

import FinanceIcon from '../helpers/FinanceIcon';
import getPaymentStatus from '../helpers/PaymentStatusTagSelector';
import { getPaymentName } from '../payments/utils/utils';

const BillsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const [addBillForm] = Form.useForm();
  const locale = useLocale('private.finances.bills');
  const currencies = useLocale('private.currencies').labels;

  const loading = useSelector<IAppState, boolean>(({ bills }) => bills.loading);
  const { data, metadata } = useSelector<IAppState, IBillsListState>(({ bills }) => bills);

  const { handleChange, onPageChange, getResults } = UseSearch({
    action: getBills,
    clinicId,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc'
  });

  useEffect(() => {
    dispatch(setSelectedBill(null));

    return () => {
      dispatch(resetBills());
    };
  }, []);

  const redirectToBillDetails = (record: IBillDetails): { onClick: () => void } => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/finances/bills/${record.id}`);
    }
  });

  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);
  const localizedBillTypes = useLocalizedList(billTypes);

  const getStatus = (e: IBillDetails): JSX.Element | '' => {
    const res = localizedPaymentStatuses.find((el) => el.value === e);
    return res ? getPaymentStatus(res) : '';
  };

  const columns = useMemo(
    (): TableColumnProps<IBillDetails>[] => [
      {
        key: 'bill_type',
        dataIndex: 'bill_type',
        title: '',
        sorter: () => null,
        render: (bill_type) => <FinanceIcon type={bill_type} />,
        onCell: redirectToBillDetails
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        render: (name, e) => getPaymentName(e, locale, localizedBillTypes),
        onCell: redirectToBillDetails
      },
      {
        key: 'id',
        dataIndex: 'id',
        title: locale.labels.id,
        sorter: () => null,
        render: (name, e) => e.id,
        onCell: redirectToBillDetails
      },
      {
        key: 'category',
        dataIndex: 'category',
        title: locale.labels.category,
        sorter: () => null,
        render: (category) => category ?? '-',
        onCell: redirectToBillDetails
      },
      {
        key: 'date',
        dataIndex: 'date',
        title: locale.labels.date,
        sorter: () => null,
        render: (date) => `${date ? dayjs(date).local().format('DD.MM.YYYY hh:mm') : '-'}`,
        onCell: redirectToBillDetails
      },
      {
        key: 'final_amount',
        dataIndex: 'final_amount',
        title: locale.labels.amount,
        sorter: () => null,
        render: (total_paid_amount, e) =>
          total_paid_amount !== null ? `${money(total_paid_amount)} ${currencies.uah}` : '-',
        onCell: redirectToBillDetails
      },
      {
        key: 'status',
        title: locale.labels.paymentStatus,
        sorter: () => null,
        render: (e) => getStatus(e.payment_status),
        onCell: redirectToBillDetails
      },
      {
        key: 'remaining_amount',
        dataIndex: 'remaining_amount',
        title: locale.labels.remainingAmount,
        sorter: () => null,
        render: (remaining_amount, e) =>
          remaining_amount !== null ? `${money(remaining_amount)} ${currencies.uah}` : '-',
        onCell: redirectToBillDetails
      }
      // {
      //   key: 'fiscal',
      //   dataIndex: 'fiscal',
      //   title: locale.labels.fiscal,
      //   sorter: () => null,
      //   render: (fiscal, e) => (!fiscal ? locale.labels.no : locale.labels.yes),
      //   onCell: redirectToBillDetails
      // }
    ],
    [data]
  );

  return (
    <>
      <VLXListCompositeSearch />
      <Spin spinning={loading} className='bills-list-page-spin-container'>
        <Table
          pagination={false}
          className='accent-header bills-list-table'
          rowKey='id'
          onChange={handleChange}
          dataSource={data.filter((item) => item.final_amount !== null)}
          columns={columns}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            defaultCurrent={1}
            pageSize={20}
            current={metadata.page}
            total={metadata.total}
            onChange={onPageChange}
            showSizeChanger={false}
            showLessItems
          />
          <AddBill form={addBillForm} callbackAfterCreateFunc={getResults} />
        </Row>
      </Spin>
    </>
  );
};

export default BillsList;
