import '../Finances.scss';
import './ShiftsList.scss';

import { Pagination, Row, Spin, Table, TableColumnProps, Tag } from 'antd';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getShifts, setSelectedShift } from 'redux/shifts/actions';
import { AppState } from 'redux/store';
import { ShiftsData } from 'services/clinic/shifts/models/IndexShiftsResponse';

import { currentEarnings, getShiftDuration, userData } from './ShiftsBaseHelper';

const ShiftsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const locale = useLocale('private.finances.shifts');
  const currencies = useLocale('private.currencies').labels;

  const { data, metadata } = useSelector(({ shifts }: IAppState) => shifts);
  const loading = useSelector(({ shifts }: AppState) => shifts.loading);

  const redirectToDetails = (record: ShiftsData): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/finances/shifts/${record.id}`);
    }
  });

  const columns = useMemo(
    (): TableColumnProps<ShiftsData>[] => [
      {
        key: 'opened_at',
        dataIndex: 'opened_at',
        title: locale.labels.openedAt,
        sorter: (a, b) => (dayjs(a.opened_at).local() > dayjs(b.opened_at).local() ? 1 : -1),
        render: (opened_at, e) => dayjs(opened_at).local().format('DD.MM.YYYY HH:mm')
      },
      {
        key: 'opened_by',
        dataIndex: 'opened_by',
        title: locale.labels.openedBy,
        sorter: (a, b) => (a.opened_by.name > b.opened_by.name ? 1 : -1),
        render: (opened_by, e) => userData(opened_by)
      },
      {
        key: 'open',
        dataIndex: 'open',
        title: locale.labels.status,
        sorter: (a, b) => (a.opened_at > b.opened_at ? 1 : -1),
        render: (open) =>
          open ? (
            <Tag color='green' style={{ fontWeight: 600 }}>
              {locale.labels.opened}
            </Tag>
          ) : (
            <Tag color='red' style={{ fontWeight: 600 }}>
              {locale.labels.closed}
            </Tag>
          )
      },
      {
        key: 'closed_at',
        dataIndex: 'closed_at',
        title: locale.labels.closedAt,
        sorter: (a, b) => (dayjs(a.closed_at).local() > dayjs(b.closed_at).local() ? 1 : -1),
        render: (closed_at, e) =>
          closed_at ? dayjs(closed_at).local().format('DD.MM.YYYY HH:mm') : '-'
      },
      {
        key: 'closed_by',
        dataIndex: 'closed_by',
        title: locale.labels.closedBy,
        sorter: (a, b) => (a.closed_by.name > b.closed_by.name ? 1 : -1),
        render: (closed_by) => userData(closed_by)
      },
      {
        key: 'total_diff',
        dataIndex: 'total_diff',
        title: locale.labels.totalDiff,
        sorter: (a, b) => (a.total_diff > b.total_diff ? 1 : -1),
        render: (total_diff) => currentEarnings(total_diff, currencies)
      },
      {
        key: 'duration',
        dataIndex: 'duration',
        title: locale.labels.duration,
        sorter: (a, b) => (a.total_diff > b.total_diff ? 1 : -1),
        render: (duration, e) => getShiftDuration(e, locale)
      }
    ],
    [data]
  );

  const onPageChange = (page: number): void => {
    dispatch(getShifts(clinicId, page));
  };

  useEffect((): void => {
    if (clinicId) dispatch(getShifts(clinicId, 1));
    dispatch(setSelectedShift(null));
  }, [clinicId]);

  return (
    <Spin spinning={loading} className='bills-list-page-spin-container'>
      <>
        <Table
          pagination={false}
          className='accent-header'
          rowKey='id'
          dataSource={data}
          columns={columns}
          onRow={redirectToDetails}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            defaultCurrent={1}
            pageSize={20}
            current={metadata.page}
            total={metadata.total}
            onChange={onPageChange}
            showSizeChanger={false}
            showLessItems
          />
        </Row>
      </>
    </Spin>
  );
};

export default ShiftsList;
