import React, { useState } from 'react';
import {
  Button,
  Input,
  Form,
  Spin
} from 'antd';
import MessageModal from '../messageModal/MessageModal';
import { forgotPassword } from '../../../services/authService';
import { useLocale } from '../../../hooks/useLocale';

const ForgotPassword = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const locale = useLocale('public.modals.forgot-password');

  const onSubmit = (values: any) => {
    setLoading(true);
    forgotPassword(values)
      .then(() => {
        setLoading(false);
        closeModal();
        MessageModal('success', locale.messages.success);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label={locale.labels.email}
          name="email"
          rules={[{ required: true, message: locale.messages.emailEmpty }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            {locale.buttons.submit}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ForgotPassword;
