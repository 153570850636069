import './AuditForm.scss';

import { DatePicker, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { CreateAuditRequest } from 'services/interfaces/clinics/inventory/audit/CreateAuditRequest';

const { TextArea } = Input;

type Props = React.PropsWithChildren<{
  onFieldsChange?: (changedFields) => void;
  onSubmit: (data: CreateAuditRequest) => void;
  form: FormInstance;
  initialValues?: any;
}>;

const AuditForm = ({
  onFieldsChange,
  onSubmit,
  form,
  initialValues,
  children
}: Props): JSX.Element => {
  const locale = useLocale('private.inventory.audit');

  return (
    <Form
      key={'auditCreateEditForm'}
      onFieldsChange={onFieldsChange}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={initialValues}
    >
      <Form.Item
        name='name'
        label={locale.labels.name}
        rules={[
          {
            required: true
          },
          { min: 2, message: locale.errors.enterMaxFieldValueLength_2_100 },
          { max: 100, message: locale.errors.enterMaxFieldValueLength_2_100 }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='date' label={locale.labels.date}>
        <DatePicker />
      </Form.Item>
      <Form.Item
        label={locale.labels.description}
        name='description'
        rules={[
          { min: 2, message: locale.errors.enterMaxFieldValueLength_2_300 },
          { max: 300, message: locale.errors.enterMaxFieldValueLength_2_300 }
        ]}
      >
        <TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label={locale.labels.notes}
        name='notes'
        rules={[
          { min: 2, message: locale.errors.enterMaxFieldValueLength_2_300 },
          { max: 300, message: locale.errors.enterMaxFieldValueLength_2_300 }
        ]}
      >
        <TextArea rows={2} />
      </Form.Item>
      {children}
    </Form>
  );
};

export default AuditForm;
