import { Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { ITranferAccount } from 'interfaces/Account';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts, getAccountsDetailsPayments, transferAccount } from 'redux/finances/financialAccounts/actions';
import { ShowAccountResponse } from 'services/clinic/accounts/models/ShowAccountResponse';

const { TextArea } = Input;
const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
  currentAccountId: number;
  financialLocale: ILocale;
}>;

export const TransferAccountsForm = ({
  form,
  children,
  locale,
  currentAccountId,
  financialLocale
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const accountsData = useSelector<IAppState, ShowAccountResponse[]>((state) => state.financialAccounts.data);
  const currencies = useLocale('private.currencies').labels;

  const [accountIdFrom, setAccountIdFrom] = useState(currentAccountId);

  useEffect(() => {
    form.resetFields();
      dispatch(getAccounts(clinicId, 0));
  }, []);

  const onSubmit = (data: ITranferAccount) => {
    dispatch(transferAccount(clinicId, accountIdFrom, data, () => {
      dispatch(getAccountsDetailsPayments(clinicId, currentAccountId, {sort_key: 'created_at', sort_order: 'desc'}));
    }));
  };

  const accountsList = useMemo(
    () => accountsData.map((item) => {
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item?.name} ({item?.current_balance} {currencies.uah})
      </Option>
    );
  }),
    [accountsData]
  );

  const filteredaccountsList = useMemo(
    () => accountsData.filter(el => +el.id !== accountIdFrom).map((item) => {
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item?.name} ({item?.current_balance} {currencies.uah})
      </Option>
    );
  }),
    [accountsData, accountIdFrom]
  );

  const getMaxAmmount = (): number => accountsData.find(el => +el.id === accountIdFrom)?.current_balance;

  const onAccountIdFromCahnge = (val: number): void => {
    setAccountIdFrom(val);
    const amount = form.getFieldValue('amount');
    const newSelectedAccount = accountsData.find(el => +el.id === val);
    if (amount) {
        form.setFields([
          {
            name: 'amount',
            errors: newSelectedAccount && newSelectedAccount.current_balance < amount ? [financialLocale.errors.summError] : []
          }
        ]);
    };
  };

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
    >
      <Form.Item
        rules={[{ required: true }]}
        className='add-equipment-form__multi-select-field'
        label={locale.labels.fromAccount}
      >
        <Select
          style={{ overflow: 'hidden' }}
          filterOption={(input, option) => {
            return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
          }}
          showSearch
          onChange={onAccountIdFromCahnge}
          value={accountIdFrom}
        >
          {accountsList}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        className='add-equipment-form__multi-select-field'
        label={locale.labels.toAccount}
        name='account_to_id'
      >
        <Select
          style={{ overflow: 'hidden' }}
          filterOption={(input, option) => {
            return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
          }}
          showSearch
        >
          {filteredaccountsList}
        </Select>
      </Form.Item>
      <Form.Item
        label={locale.labels.sum}
        rules={[
          { required: true},
          {
          validator: async (_, value) => {
            if (value > getMaxAmmount()) {
              return Promise.reject(financialLocale.errors.summError);
            } else {
              return Promise.resolve();
            }
          }
        }
        ]}
        name='amount'
      >
        <VLXInputNumber placeholder='0' type='number' precision={2} step={0.1}/>
      </Form.Item>
      <Form.Item
        label={locale.labels.description}
        name='description'
        rules={[{ max: 255, message: locale.errors.enterMaxFieldValueLength_255 }]}
      >
        <TextArea rows={2} />
      </Form.Item>
      {children}
    </Form>
  );
};

