import './scss/BillPrint.scss';

import { Col, Table, TableColumnProps } from 'antd';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import { measurementsLocale } from 'i18n/measurements';
import { IAppState } from 'interfaces/app-state';
import { IService } from 'interfaces/services';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';

import { useLocale } from '../../../../hooks/useLocale';

interface Props {
  data: IBillDetails;
  billId: number;
}

export const InvoicePrintInfo = ({ data, billId }: Props): JSX.Element => {
  const locale = useLocale('private.finances.bills');
  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);
  const currencies = useLocale('private.currencies').labels;

  const servicesColumns = useMemo(
    (): TableColumnProps<IService>[] => [
      {
        key: 'name',
        title: locale.labels.services,
        render: (e) => e.name
      },
      {
        key: 'price_per_service',
        title: locale.labels.price,
        render: (e) =>
          `${e.price_per_service ? `${money(e.price_per_service)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'service_count',
        title: locale.labels.qty,
        render: (e) => e.service_count ?? '-'
      },
      {
        key: 'total_price',
        title: locale.labels.sum,
        render: (e) => `${e.total_price ? `${money(e.total_price)} ${currencies.uah}` : '-'}`
      }
    ],
    [data]
  );

  const stockColumns = useMemo(
    (): TableColumnProps<IStockItem>[] => [
      {
        key: 'name',
        title: locale.labels.stock,
        render: (e) => `${e.name} (${e.manufacturer})`
      },
      {
        key: 'unit_of_measurement',
        title: locale.labels.qtyItem,
        render: (e) => measurementsLocale[localeCode][e.unit_of_measurement]
      },
      {
        key: 'price_per_unit',
        title: locale.labels.price,
        render: (e) => `${e.price_per_unit ? `${money(e.price_per_unit)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'quantity',
        title: locale.labels.qty,
        render: (e) => e.quantity ?? '-'
      },
      {
        key: 'price',
        title: locale.labels.sum,
        render: (e) => `${money(e.price)} ${currencies.uah}`
      }
    ],
    [data]
  );

  const getServiceGeneralPrice = () => {
    let res = 0;
    if (data.total_services_price && data.services_discount.amount !== null) {
      res = data.total_services_price - data.services_discount.amount;
    }
    return money(res);
  };

  const getStockGeneralPrice = () => {
    let res = 0;
    if (data.total_stock_price && data.stock_discount.amount !== null) {
      res = data.total_stock_price - data.stock_discount.amount;
    }
    return money(res);
  };

  return (
    <div className='invoice-print-info'>
      <h5 className='invoice-print-info__header'>
        {locale.labels.invoice} №{billId} {locale.labels.from}{' '}
        {dayjs(data?.created_at).format('DD.MM.YYYY')}
      </h5>

      {data?.appointment?.appointment_consolidated?.services.length > 0 && (
        <div style={{ marginBottom: 40 }}>
          <Table
            pagination={false}
            rowKey='id'
            className='simple-view-table'
            dataSource={data?.appointment?.appointment_consolidated?.services}
            columns={servicesColumns}
          />
          <Col className='invoice-print-info__sum-container'>
            <Col>
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.sum}: </span>
                <span id='total_paid'>
                  {money(data?.total_services_price || 0)} {currencies.uah}
                </span>
              </>
            </Col>
            <Col>
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.discount}: </span>
                <span id='total_paid'>
                  {money(data?.services_discount?.amount || 0)}{' '}
                  {!!data?.services_discount?.amount && currencies.uah}{' '}
                  {data?.services_discount?.percentage
                    ? `(${data?.services_discount?.percentage}%)`
                    : ''}
                </span>
              </>
            </Col>
            <Col>
              <span style={{ fontWeight: 600 }}>{locale.labels.sumWithDiscount}: </span>
              <span id='total_paid'>
                {getServiceGeneralPrice()} {currencies.uah}
              </span>
            </Col>
          </Col>
        </div>
      )}

      {data?.appointment?.appointment_consolidated?.stock.length > 0 && data?.appointment?.appointment_consolidated?.stock.some(el => el.quantity) && (
        <div style={{ marginBottom: 40 }}>
          <Table
            pagination={false}
            rowKey='id'
            className='simple-view-table'
            dataSource={data?.appointment?.appointment_consolidated?.stock?.filter(el => el.quantity)}
            columns={stockColumns}
          />
          <Col className='invoice-print-info__sum-container'>
            <Col>
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.sum}: </span>
                <span id='total_paid'>
                  {money(data?.total_stock_price || 0)} {currencies.uah}
                </span>
              </>
            </Col>
            <Col>
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.discount}: </span>
                <span id='total_paid'>
                  {money(data?.stock_discount?.amount || 0)}{' '}
                  {!!data?.stock_discount?.amount && currencies.uah}{' '}
                  {data?.stock_discount?.percentage ? `(${data?.stock_discount?.percentage}%)` : ''}
                </span>
              </>
            </Col>
            <Col>
              <span style={{ fontWeight: 600 }}>{locale.labels.sumWithDiscount}: </span>
              <span id='total_paid'>
                {getStockGeneralPrice()} {currencies.uah}
              </span>
            </Col>
          </Col>
        </div>
      )}
    </div>
  );
};
