import { IPatient, IPatientsList } from '../../interfaces/patient';
import {
  ADD_PATIENT,
  DELETE_PATIENT,
  EDIT_PATIENT,
  MERGE_PATIENT_DOCUMENTS_LIST,
  PatientsActions,
  RESET,
  SET_PATIENT,
  SET_PATIENT_ALL_VACCINATIONS_DATA,
  SET_PATIENT_DOCUMENTS_LIST,
  SET_PATIENT_SELECTED_VACCINATIONS,
  SET_PATIENT_VACCINATIONS,
  SET_PATIENTS,
  SET_PATIENTS_LOADING,
  SHOW_CREATE_MODAL,
  SHOW_UPDATE_MODAL
} from './types';

const initialState: IPatientsList = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: true,
  selectedPatient: null,
  showCreateModal: false,
  showUpdateModal: false,
  selectedPatientVaccinations: null,
  selectedPatientSelectedVaccination: null,
  selectedPatientDocuments: [],
  selectedPatientAllVaccinationsData: null
};

export function patientsReducer(state: IPatientsList = initialState, action: PatientsActions): any {
  switch (action.type) {
    case SET_PATIENTS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case EDIT_PATIENT: {
      const editedPatients = state.data.map((el) =>
        el.id !== action.payload.id ? el : action.payload
      );

      return {
        ...state,
        data: editedPatients
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_PATIENTS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case ADD_PATIENT: {
      const id = getNewId(state.data);
      return {
        ...state,
        data: [
          {
            ...action.payload,
            id
          },
          ...state.data
        ]
      };
    }

    case DELETE_PATIENT: {
      const patients = state.data.filter((el) => el.id !== +action.id);
      return {
        ...state,
        data: [...patients]
      };
    }

    case SHOW_CREATE_MODAL: {
      return {
        ...state,
        showCreateModal: action.payload
      };
    }

    case SHOW_UPDATE_MODAL: {
      return {
        ...state,
        showUpdateModal: action.payload
      };
    }

    case SET_PATIENT: {
      return {
        ...state,
        selectedPatient: action.payload
      };
    }

    case SET_PATIENT_VACCINATIONS: {
      return {
        ...state,
        selectedPatientVaccinations: action.payload
      };
    }

    case SET_PATIENT_SELECTED_VACCINATIONS: {
      return {
        ...state,
        selectedPatientVaccinations: action.payload
      };
    }

    case SET_PATIENT_DOCUMENTS_LIST: {
      return {
        ...state,
        selectedPatientDocuments: action.payload
      };
    }

    case MERGE_PATIENT_DOCUMENTS_LIST: {
      return {
        ...state,
        selectedPatientDocuments: [action.payload, ...state.selectedPatientDocuments]
      };
    }

    case SET_PATIENT_ALL_VACCINATIONS_DATA: {
      return {
        ...state,
        selectedPatientAllVaccinationsData: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

function getNewId(patients: IPatient[]) {
  return patients
    .map((patient) => patient.id)
    .reduce((largest, id) => Math.max(largest, id + 1), -1)
    .toString();
}
