import React from 'react';
import { InputNumber } from 'antd';

class VLXInputNumber extends React.Component<any, any> {
  customParser = (value) => {
    return value ? value.toString().replace(/,/g, '.') : '';
  };

  customFormatter = (value) => {
    if (value === undefined || value === null) {
      return '';
    }
    return value.toString().replace(',', '.');
  };

  render () {
    return (
      <InputNumber
        {...this.props}
        className={!this.props.disabled ? 'vlx-number-input' : 'vlx-number-input-disabled'}
        formatter={this.customFormatter}
        parser={this.customParser}
        type={'text'}
        inputMode={'decimal'}
      />
    );
  }
}

export default VLXInputNumber;
