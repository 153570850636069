import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import animalsList from 'constants/dictionary/animals';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { IPatient } from 'interfaces/patient';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewExistingPatientToAppointment } from 'redux/appointments/actions';
import { getClientPatients } from 'redux/clients/actions';

const { Option } = Select;

const AddAppointmentPatientForm = ({
  form,
  children,
  locale,
  clientId,
  appointmentId,
  appointmentPatients
}: any) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const appointments = useSelector<IAppState, any>((state) => state.appointments);
  // TODO createNewClient is always false, remove from conditions or fix the logic
  const [createNewClient] = useState(false);
  const [createNewPatient, setCreateNewPatient] = useState(false);

  const clientPatients = useSelector<IAppState, any>((state) => state.clients.clientPatients);

  const onSubmit = (data: any) => {
    if (data?.patients?.length) {
      data.patients = data.patients.filter((el) => el.name);
    }
    const oldPatients = appointmentPatients?.map((el) => el.id);
    const res = {
      client_id: clientId,
      patients: data.patients,
      patients_ids: [...oldPatients, ...data.patients_ids]
    };

    dispatch(addNewExistingPatientToAppointment(clinicId, appointmentId, res));
  };

  useEffect(() => form.resetFields(), []);

  useEffect(() => {
    if (clientId) {
      dispatch(getClientPatients(clinicId, clientId));
    }
  }, [clientId]);

  const localizedAnimalsList = useLocalizedList(animalsList);

  const onPatientSearch = (value: string) => {
    dispatch(getClientPatients(clinicId, clientId, `[composite]=${value}`));
  };

  const getNewPatientsList = (): IPatient[] => { 
    if (clientPatients) {
      return clientPatients.filter((el) => !appointmentPatients.some((item) => item.id === el.id));
    }  
    return [];
  };

  const patientsList = getNewPatientsList().map(
    (item): JSX.Element => (
      <Option key={`patient_${item.id}`} value={item.id}>
        {item.name}
      </Option>
    )
  );

  return (
    <Spin spinning={appointments.loading}>
      <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
        <Form.Item name='clinic_id' initialValue={clinicId} hidden>
          <Input />
        </Form.Item>
        {!createNewClient && (
          <Row>
            <Col md={16} span={24}>
              <Form.Item name='patients_ids' initialValue={[]} hidden>
                <Input />
              </Form.Item>
              <Form.Item className='add-new-patient__owner-select' name={['patients_ids']}>
                <Select
                  filterOption={false}
                  className='patient-select'
                  showSearch
                  mode='multiple'
                  onSearch={onPatientSearch}
                  placeholder={locale.placeholders.searchPatient}
                >
                  {patientsList}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          {!createNewPatient && (
            <Button
              type={'primary'}
              style={{ borderRadius: 30, marginLeft: 10 }}
              onClick={() => {
                setCreateNewPatient(true);
                if (!form.getFieldValue('patients').length)
                  form.setFieldValue('patients', [{ name: '', species: '' }]);
              }}
            >
              {locale.buttons.addNew}
            </Button>
          )}
        </Row>
        <Form.Item name='patients' initialValue={[{ name: '', species: '' }]} hidden>
          <Input />
        </Form.Item>
        {createNewPatient && (
          <Form.List name='patients'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={[20, 10]}>
                    <Col span={22} md={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        rules={[{ required: true, message: locale.messages.animalNameNotValid }]}
                      >
                        <Input placeholder={locale.labels.name} />
                      </Form.Item>
                    </Col>
                    <Col
                      span={(createNewClient && key > 0) || !createNewClient ? 22 : 24}
                      md={(createNewClient && key > 0) || !createNewClient ? 11 : 12}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'species']}
                        rules={[{ required: true, message: locale.messages.animalTypeNotValid }]}
                      >
                        <Select filterOption={false} options={localizedAnimalsList} />
                      </Form.Item>
                    </Col>
                    {((createNewClient && key > 0) || !createNewClient) && (
                      <Col span={1} md={1}>
                        <Button
                          onClick={() => {
                            remove(name);
                          }}
                          danger
                          type='primary'
                          shape={'circle'}
                          className={'inventory-update-buttons'}
                        >
                          <span className='icofont icofont-trash' />
                        </Button>
                      </Col>
                    )}
                  </Row>
                ))}
                <Row>
                  <Form.Item>
                    <Button
                      className='icofont icofont-plus'
                      type={'primary'}
                      style={{ borderRadius: 30 }}
                      onClick={() => add()}
                    >
                      {locale.buttons.addMore}
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
        )}
        {children}
      </Form>
    </Spin>
  );
};

export default AddAppointmentPatientForm;
