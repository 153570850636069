import React from 'react';

export function showMoreField(field, data, setDataFn) {
  const language = localStorage.getItem('locale');

  const toggleShowMoreDescription = () => {
    setDataFn(!data);
  };
  const localizedShowMore = (locale) => {
    switch (locale) {
      case '"enGB"':
        return 'show more';
      case '"ukUA"':
        return 'показати більше';
    }
  };
  const localizedShowLess = (locale) => {
    switch (locale) {
      case '"enGB"':
        return 'show less';
      case '"ukUA"':
        return 'приховати';
    }
  };

  return (
    <>
      {field && data ? field : `${field ? field.substring(0, 250) : '-'}`}
      {field?.length > 250 && (
        <span style={{ color: '#49afb3', cursor: 'pointer' }} onClick={toggleShowMoreDescription}>
          {data ? ` ...${localizedShowLess(language)}` : ` ...${localizedShowMore(language)}`}
        </span>
      )}
    </>
  );
}
