import './Dictionary.scss';

import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  List,
  Pagination,
  Popconfirm,
  Row,
  Skeleton,
  Tooltip
} from 'antd';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ShowDictionaryRecordResponse } from 'services/clinic/dictionaries/models/ShowDictionaryRecordResponse';

const {
  TextArea,
  Search
} = Input;

const Dictionary = ({
  dictionary,
  dictionaryName,
  createAction,
  updateAction,
  indexAction,
  destroyAction,
  id
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const [form] = Form.useForm();
  const [editModeEntityId, setEditModeEntityId] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchItemsArray, setSearchItemsArray] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const locale = useLocale('private.administration.dictionaries');
  const pageSize = 10;

  useEffect(() => {
    form.resetFields();
  }, [dictionary]);

  useEffect(() => {
    if (dictionary.metadata.last_page < currentPage) {
      setCurrentPage(dictionary.metadata.last_page);
    }
  }, [dictionary.metadata]);

  function onEditClick (item): void {
    setEditModeEntityId(item.id);
  }

  function onCreateClick (item): void {
    setCreateMode(true);
  }

  function onCancelClick (): void {
    setEditModeEntityId(null);
  }

  function onDeleteClick (item): void {
    dispatch(destroyAction(clinicId, item.id, currentPage, searchItemsArray));
  }

  function onCreateCancelClick (): void {
    form.resetFields();
    setCreateMode(false);
  }

  function onCreateSaveClick (): void {
    form.validateFields().then((values) => {
      dispatch(createAction(clinicId, values, currentPage, searchItemsArray));
      setCreateMode(false);
    });
  }

  function onSaveClick (index: number): void {
    form.validateFields().then(() => {
      const data = form.getFieldsValue()[index];
      dispatch(updateAction(clinicId, editModeEntityId, data, currentPage, searchItemsArray));
      setEditModeEntityId(null);
    });
  }

  function getActions (item: ShowDictionaryRecordResponse, index: number): JSX.Element {
    if (editModeEntityId !== item?.id) {
      return (
        <>
          {!item.default && <div>
             <a href='#' key='dictionary-item-edit' onClick={() => onEditClick(item)}>
              {locale.labels.edit}
            </a>
            <Divider type='vertical' />
            {item.can_delete ? (
              <Popconfirm
                style={{ maxWidth: '150px' }}
                title={locale.messages.deleteConfirmation}
                onConfirm={() => onDeleteClick(item)}
                cancelText={locale.labels.no}
                okText={locale.labels.yes}
              >
                <a href='#' key='dictionary-item-delete'>
                  {locale.labels.delete}
                </a>
              </Popconfirm>
            ) : (
              <Tooltip title={locale.messages.cannotDelete}>
                <a href='#' key='dictionary-item-delete' style={{ color: 'gray' }}>
                  {locale.labels.delete}
                </a>
              </Tooltip>
            )}
          </div>}
        </>
      );
    }

    return (
      <div>
        <a key='dictionary-item-save' onClick={() => onSaveClick(index)}>
          {locale.labels.save}
        </a>
        <Divider type='vertical' />
        <a key='dictionary-item-cancel' onClick={() => onCancelClick()}>
          {locale.labels.cancel}
        </a>
      </div>
    );
  }

  const onPageChange = (page: number): void => {
    setCurrentPage(page);
    dispatch(indexAction(clinicId, page, searchItemsArray, null, pageSize));
  };

  const onSearch = (value: any): void => {
    const searchArray = [];
    const finalValue = value || '';

    finalValue
      .toString()
      .split(' ')
      .filter(c => c !== '')
      .forEach((criteria) => {
        if (criteria) {
          searchArray.push({
            name: 'composite',
            value: criteria,
            isArrayValue: true
          });
        }
      });

    setSearchItemsArray(searchArray);
    dispatch(indexAction(clinicId, 1, searchArray, null, pageSize));
  };

  return (
    <Collapse
      onChange={(items: string | string[]) => {
        if (items.length === 1) {
          dispatch(indexAction(clinicId, 1, [], null, pageSize));
        }
      }}
      items={[
        {
          key: 1,
          id,
          label: dictionaryName,
          children: (
            <Form layout='vertical' form={form}>
              <Search
                placeholder={locale.placeholders.specifySearchCriteria}
                className='dictionary-search-field'
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                enterButton
                allowClear
              />
              <List
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  border: '1px solid #f0f0f0'
                }}
                size='small'
                loading={dictionary.loading}
                itemLayout='horizontal'
                dataSource={dictionary.data}
                renderItem={(item: ShowDictionaryRecordResponse, index) => (
                  <List.Item
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Skeleton avatar title={false} loading={dictionary.loading} active>
                      <Row style={{ width: '100%' }}>
                        {editModeEntityId !== item?.id ? (
                          <List.Item.Meta
                            title={<span>{item?.name}</span>}
                            description={item?.description}
                          />
                        ) : (
                          <List.Item.Meta
                            title={
                              <>
                                <Form.Item
                                  name={[index, 'name']}
                                  initialValue={item?.name}
                                  rules={[
                                    {
                                      required: true,
                                      message: locale.messages.specifyName
                                    }
                                  ]}
                                >
                                  <Input placeholder={locale.labels.name} />
                                </Form.Item>
                                <Form.Item
                                  name={[index, 'description']}
                                  initialValue={item?.description}
                                >
                                  <TextArea placeholder={locale.labels.description} rows={3} />
                                </Form.Item>
                              </>
                            }
                          />
                        )}
                      </Row>
                      <Row style={{ width: '100%' }} justify={'end'}>
                        {getActions(item, index)}
                      </Row>
                    </Skeleton>
                  </List.Item>
                )}
              />
              <Row
                style={{
                  display: 'flex'
                }}
              >
                <Col span={24}>
                  {createMode && (
                    <Row
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Form.Item
                        label={locale.labels.name}
                        name='name'
                        rules={[
                          {
                            required: true,
                            message: locale.messages.specifyName
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label={locale.labels.description} name='description'>
                        <TextArea rows={3} />
                      </Form.Item>
                    </Row>
                  )}
                </Col>
                <Pagination
                  defaultCurrent={+currentPage}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  showLessItems
                  hideOnSinglePage
                  current={dictionary.metadata.page}
                  total={dictionary.metadata.total}
                  onChange={onPageChange}
                />
                <Row justify={'end'} style={{ width: '100%' }}>
                  {!createMode ? (
                    <Button
                      id='add'
                      onClick={onCreateClick}
                      shape={'circle'}
                      className='icofont icofont-plus'
                      type={'primary'}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse'
                      }}
                    >
                      <Button
                        id='cancel-create-dictionary-item'
                        onClick={onCreateCancelClick}
                        shape={'circle'}
                        danger
                        className='icofont icofont-ban'
                        style={{ marginLeft: '5px' }}
                        type={'primary'}
                      />
                      <Button
                        id='save-new-dictionary-item'
                        onClick={onCreateSaveClick}
                        shape={'circle'}
                        className='icofont icofont-save'
                        type={'primary'}
                      />
                    </div>
                  )}
                </Row>
              </Row>
            </Form>
          )
        }
      ]}
    ></Collapse>
  );
};

export default Dictionary;
