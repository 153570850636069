import './ClinicRegistration.scss';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, Spin, Typography } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { companyTypes } from 'constants/dictionary/default/selectOptions';
import { getIcon, socialMediaLinks } from 'constants/dictionary/default/socialMediaOptions';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'redux/user/actions';
import { createClinic } from 'services/clinic/clinicsService';

// import PricingPage from './PricingPage';

const { Title } = Typography;
const { Option } = Select;

const ClinicRegistration = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [subscriptionId, setSubscriptionId] = useState<number>(null);
  const [form] = Form.useForm();
  const userAccess = useUserAccess();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const locale = useLocale('private.administration.general');

  const onSubmit = (values: any) => {
    values.social_media_links = values.social_media_links
      ? JSON.stringify(values.social_media_links)
      : null;
    values.additional_contacts = values.additional_contacts
      ? JSON.stringify(values.additional_contacts)
      : null;
    setLoading(true);
    createClinic(values)
      .then((data) => {
        dispatch(getUser());
        Modal.confirm({
          title: locale.labels.clinicSuccessfullyCreated,
          icon: <CheckCircleOutlined />,
          okText: locale.buttons.clinicInfo,
          cancelText: locale.buttons.homePage,
          onOk: () => {
            navigate(`/clinic/${data.clinic_id}/administration/info`);
          },
          onCancel: () => {
            navigate('/');
          }
        });
      })
      .finally(() => setLoading(false));
  };

  function onConfirmCancelClick(): void {
    form.resetFields();
    setShowCancelModal(false);
  }

  function socialMediaList(): JSX.Element[] {
    return socialMediaLinks.map((link, index) => {
      return (
        <Option key={index} value={link.value}>
          {link.value}
          {getIcon(link.key === 'other' ? 'page' : link.key, 16)}
        </Option>
      );
    });
  }

  return (
    <>
      <Title level={4}>{locale.labels.clinicRegistration}</Title>
      <Spin spinning={loading}>
        <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
          {/* {!subscriptionId ? (
            <PricingPage setSubscriptionId={setSubscriptionId} />
          ) : ( */}
          <div style={{ maxWidth: 690, margin: '0 auto' }}>
            <Row gutter={[20, 0]}>
              <Col span={24} md={12}>
                <Form.Item name='subscription_id' initialValue={1} hidden>
                  <Input />
                </Form.Item>
                <Form.Item
                  label={locale.labels.clinicName}
                  name='name'
                  rules={[{ required: true, message: locale.messages.nameEmpty }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label={locale.labels.type} name='company_type'>
                  <Select options={useLocalizedList(companyTypes)} />
                </Form.Item>
                <PhoneFieldFormItem
                  formItemPhonePrefix={['country_code']}
                  formItemPhoneNumber={['phone_number']}
                  localization={locale}
                />
                <Form.Item
                  label={locale.labels.eMail}
                  name='email'
                  rules={[
                    { required: true, message: locale.messages.eMailEmpty },
                    {
                      pattern: /^(?=.{1,256}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: locale.messages.emailNotValid
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={locale.labels.address}
                  name='location'
                  rules={[{ required: true, message: locale.messages.addressEmpty }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={locale.labels.workingHours}
                  name='working_hours'
                  rules={[{ required: true, message: locale.messages.workingHoursEmpty }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item label={locale.labels.description} name='description'>
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Row style={{ paddingLeft: '10px' }}>
                <Typography.Title level={5}>{locale.labels.socialMedia}</Typography.Title>
              </Row>
              <Row style={{ width: '100%', paddingLeft: '10px' }}>
                <Form.List name='social_media_links'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }) => (
                        <Row
                          key={key}
                          gutter={[20, 10]}
                          style={{ width: '100%' }}
                          className={'additional-links'}
                        >
                          <Col span={24} xs={24} md={11}>
                            <Form.Item
                              {...restField}
                              name={[name, 'type']}
                              rules={[
                                {
                                  required: true,
                                  message: locale.messages.selectSocialNetwork
                                }
                              ]}
                            >
                              <Select
                                placeholder={locale.placeholders.selectSocialMedia}
                                filterOption={(input, option) => {
                                  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                                showSearch
                              >
                                {socialMediaList()}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24} xs={24} md={11}>
                            <Form.Item
                              {...restField}
                              name={[name, 'description']}
                              rules={[
                                {
                                  required: true,
                                  message: locale.messages.specifyLink
                                }
                              ]}
                            >
                              <Input placeholder={locale.placeholders.linkToProfile} />
                            </Form.Item>
                          </Col>
                          <Button
                            onClick={() => {
                              remove(name);
                            }}
                            danger
                            type='primary'
                            shape={'circle'}
                            className={'inventory-update-buttons'}
                          >
                            <span className='icofont icofont-trash' />
                          </Button>
                        </Row>
                      ))}
                      <Row>
                        <Form.Item>
                          <Button
                            className='icofont icofont-plus'
                            type={'primary'}
                            style={{
                              borderRadius: 30,
                              fontWeight: 'bold'
                            }}
                            onClick={() => add()}
                          >
                            {fields.length
                              ? locale.buttons.addMore
                              : locale.buttons.addSocialMediaLinks}
                          </Button>
                        </Form.Item>
                      </Row>
                    </>
                  )}
                </Form.List>
              </Row>
              <Row style={{ paddingLeft: '10px' }}>
                <Typography.Title level={5}>{locale.labels.additionalContacts}</Typography.Title>
              </Row>
              <Row style={{ width: '100%', paddingLeft: '10px' }}>
                <Form.List name='additional_contacts'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }) => (
                        <Row
                          key={key}
                          gutter={[20, 10]}
                          style={{ width: '100%' }}
                          className={'additional-links'}
                        >
                          <Col span={24} xs={24} md={11}>
                            <Form.Item
                              {...restField}
                              name={[name, 'phone_number']}
                              rules={[
                                {
                                  required: true,
                                  message: locale.messages.specifyPhoneNumber
                                }
                              ]}
                            >
                              <Input placeholder={locale.placeholders.phoneNumber} />
                            </Form.Item>
                          </Col>
                          <Col span={24} xs={24} md={11}>
                            <Form.Item
                              {...restField}
                              name={[name, 'description']}
                              rules={[
                                {
                                  required: true,
                                  message: locale.messages.specifyDescription
                                }
                              ]}
                            >
                              <Input placeholder={locale.placeholders.description} />
                            </Form.Item>
                          </Col>
                          <Button
                            onClick={() => {
                              remove(name);
                            }}
                            danger
                            type='primary'
                            shape={'circle'}
                            className={'inventory-update-buttons'}
                          >
                            <span className='icofont icofont-trash' />
                          </Button>
                        </Row>
                      ))}
                      <Row>
                        <Form.Item>
                          <Button
                            className='icofont icofont-plus'
                            type={'primary'}
                            style={{
                              borderRadius: 30,
                              fontWeight: 'bold'
                            }}
                            onClick={() => add()}
                          >
                            {fields.length
                              ? locale.buttons.addMore
                              : locale.buttons.addAdditionalContacts}
                          </Button>
                        </Form.Item>
                      </Row>
                    </>
                  )}
                </Form.List>
              </Row>
            </Row>
            <ViewDetailsButtonsGroup
              fixed
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                }
              }}
              accessGroup={userAccess.accesses.clinics}
              editMode
              showCreateBtn
              hideDeleteBtn
            />
          </div>
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </Form>
      </Spin>
    </>
  );
};

export default ClinicRegistration;
