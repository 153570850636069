import './scss/HealthRecordsHistory.scss';

import { Spin, Timeline } from 'antd';
import calendar from 'assets/img/calendarWhite.svg';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPatientHealthRecord, setSelectedPatientHealsRecordsData } from 'redux/appointments/actions';
import { getServices } from 'utils/string-from-entities-list';

type Props = {
  clinicId: number;
  selectedPatientId: number;
};

const HealthRecordsHistory = ({ clinicId, selectedPatientId }: Props): JSX.Element => {
  const locale = useLocale('private.appointments.event');
  const dispatch = useDispatch();

  const data = useSelector(
    ({ appointments }: IAppState) => appointments.selectedPatientHealthRecordsData
  );

  useEffect(() => {
    dispatch(getPatientHealthRecord(clinicId, selectedPatientId));

    return () => {
      dispatch(setSelectedPatientHealsRecordsData(null));
    };
  }, [selectedPatientId]);

  const getParsedJsonValue = (jsonStr: string | null): string => {
    try {
      const parsedStr = JSON.parse(jsonStr);
      return parsedStr?.join(', ');
    } catch {
      return jsonStr;
    }
  };

  return (
    <Spin spinning={!data}>
      {data && !data?.length && (
        <div className='history-container__no-data'>
          <p>{locale.labels.noData}</p>
        </div>
      )}

      <div className='history-container'>
        <Timeline
          items={data?.map((el) => {
            return {
              dot: (
                <div className='history-container__icon-container'>
                  {' '}
                  <img src={calendar} />
                </div>
              ),
              children: (
                <>
                  {clinicId === el?.clinic_id && <p>
                    <Link
                      to={`/clinic/${clinicId}/appointments/${el?.appointment_id}?patientId=${el?.patient_id}&tab=details`}
                      className='history-container__services-title custom-links d-flex align-baseline'
                    >
                      {getServices(el?.services)}
                    </Link>
                  </p>}
                  <p>
                    <strong>{locale.labels.appoinmentPurpose}: </strong>
                    {el?.complaints}
                  </p>
                  <p className='history-container__time'>
                    {moment(el?.created_at).format('HH:mm DD MMM, YYYY')}
                  </p>
                  {
                    <p>
                      <strong>{locale.labels.diagnosis}: </strong>
                      {!el?.no_diagnosis_required
                        ? getParsedJsonValue(el?.diagnosis)
                        : locale.labels.noDiagnosisRequired}
                    </p>
                  }
                  {!el?.no_diagnosis_required && (
                    <p>
                      <strong>{locale.labels.preliminaryDiagnosis}: </strong>
                      {getParsedJsonValue(el?.provisional_diagnosis)}
                    </p>
                  )}
                  <p>
                    <strong>{locale.labels.recomendations}: </strong>
                    {el?.recommendations ?? locale.labels.noRecommendations}
                  </p>
                </>
              )
            };
          })}
        />
      </div>
    </Spin>
  );
};

export default HealthRecordsHistory;
