import { Spin } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/finances/bills/actions';

import AddBillForm from './AddBillForm';

const AddBill = ({
  hideDefaultCreateFormBtn,
  initialValues,
  form,
  dataLoading,
  callbackAfterCreateFunc
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const locale = useLocale('private.finances.bills');

  const bills = useSelector(({ bills }: IAppState) => bills);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideDefaultCreateFormBtn && (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addBill}
          dataLoading={dataLoading}
        />
      )}
      <VLXModal
        title={locale.labels.createBill}
        centered={true}
        form={form}
        open={bills.showModal}
        onModalClose={() => dispatch(showModal(false))}
        width={800}
      >
        <Spin spinning={bills?.loading}>
          {bills.showModal && (
            <AddBillForm
              locale={locale}
              form={form}
              initialValues={initialValues}
              callbackAfterCreateFunc={callbackAfterCreateFunc}
            >
              <ViewDetailsButtonsGroup
                fixed
                accessGroup={userAccess.accesses.services} // HERE ACCESS CHANGE
                editMode={true}
                hideDeleteBtn={true}
                onCancelClick={() => {
                  if (form.isFieldsTouched()) {
                    setShowCancelModal(true);
                  } else {
                    dispatch(showModal(false));
                  }
                }}
                onDeleteClick={() => {
                  setShowCancelModal(false);
                }}
              />
            </AddBillForm>
          )}
        </Spin>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddBill;
