import { useSelector } from 'react-redux';

import { IAppState } from '../interfaces/app-state';
import { IAccesses, IUserAccesses } from '../interfaces/user-accesses';
import useClinicId from './useClinicId';

const emptyAccesses: IAccesses = {
  showList: false,
  showItem: false,
  create: false,
  edit: false,
  delete: false
};

const emptyObject: IUserAccesses = {
  clinicId: null,
  clinicAvatar: null,
  clinicName: null,
  accesses: {
    appointments: emptyAccesses,
    clinics: emptyAccesses,
    clinicDocuments: emptyAccesses,
    clientDocuments: emptyAccesses,
    patientDocuments: emptyAccesses,
    appointmentDocuments: emptyAccesses,
    employees: emptyAccesses,
    events: emptyAccesses,
    invitations: emptyAccesses,
    patients: emptyAccesses,
    patientHealthRecords: emptyAccesses,
    roles: emptyAccesses,
    rooms: emptyAccesses,
    clients: emptyAccesses,
    equipment: emptyAccesses,
    tools: emptyAccesses,
    services: emptyAccesses,
    invoices: emptyAccesses,
    invoiceInventoryItems: emptyAccesses,
    stock: emptyAccesses,
    financialAccounts: emptyAccesses,
    financialAccountPayments: emptyAccesses,
    audit: emptyAccesses,
    shifts: emptyAccesses,
    sales: emptyAccesses,
    bills: emptyAccesses,
    payments: emptyAccesses,
    skills: emptyAccesses,
    providers: emptyAccesses
  }
};

export function useUserAccess(): IUserAccesses {
  const clinicId = useClinicId();
  const accesses = useSelector<IAppState, any>((state) => state.user.userAccesses);
  if (!clinicId || accesses.length === 0) return emptyObject;
  const clinicScopes = accesses.find((scopes) => scopes.clinicId === clinicId);
  if (!clinicScopes) return emptyObject;

  return clinicScopes;
}
