import { Form, Spin } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCreateModal } from 'redux/inventory/equipment/actions';
import { EquipmentListState } from 'redux/inventory/equipment/reducer';

import AddEquipmentForm from './AddEquipmentForm';

const AddEquipmentModal = ({ clinicId }: { clinicId: number }): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const [addEquipmentForm] = Form.useForm();
  const locale = useLocale('private.inventory.technical-equipment');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const equipment = useSelector<IAppState, EquipmentListState>(({ equipment }) => equipment);

  const onCancel = (): void => {
    dispatch(showCreateModal(false));
  };

  const onConfirmCancelClick = (): void => {
    addEquipmentForm.resetFields();
    dispatch(showCreateModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      <StickyButton
        offsetBottom={10}
        onClick={() => dispatch(showCreateModal(true))}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.create}
      />
      <VLXModal
        form={addEquipmentForm}
        title={locale.labels.createNewEquipment}
        open={equipment.showCreateModal}
        onModalClose={onCancel}
        width={800}
      >
        <Spin spinning={equipment?.loading}>
          <AddEquipmentForm form={addEquipmentForm} clinicId={clinicId}>
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.equipment}
              editMode={true}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (addEquipmentForm.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showCreateModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </AddEquipmentForm>
        </Spin>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddEquipmentModal;
