import './scss/VLXCompositeSearchWithSelect.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LocalStorageFilters } from './types';

const { Option } = Select;
dayjs.extend(customParseFormat);

const VLXCompositeSearchWithSelect = ({
  action,
  setSearchItemsArray,
  selectOptions,
  selectPlaceholder,
  enableFilteringByDate,
  savedFiltersName,
  sort_key,
  sort_order,
  currentPage,
  hasSortingParams
}: any): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const filterParams = useLinkParams('filter');
  const selectParams = useLinkParams('statuses');
  const startDateFromParams = useLinkParams('startDateFrom');
  const startDateToParams = useLinkParams('startDateTo');
  const locale = useLocale('private.composite-search');
  const [searchValue, setSearchValue] = useState<string>(filterParams);
  const [selectValue, setSelectValue] = useState<any>([]);
  const [dateFrom, setDateFrom] = useState<string>(null);
  const [dateTo, setDateTo] = useState<string>(null);
  const selectRef = useRef(null);
  const [hasSavedFilters, setHasSavedFilters] = useState(false);

  useEffect((): void => {
    const savedFilters = JSON.parse(localStorage.getItem(savedFiltersName));
    let lsFilters: LocalStorageFilters = null;

    if (savedFilters !== null) {
      if (savedFilters[clinicId]) {
        lsFilters = savedFilters[clinicId];
      }
    }

    setSearchValue(filterParams || lsFilters?.searchValue);
    setSelectValue(selectParams?.split(',') ?? lsFilters?.selectValue);
    setDateFrom(startDateFromParams ?? lsFilters?.dateFrom);
    setDateTo(startDateToParams ?? lsFilters?.dateTo);
    setHasSavedFilters(true);
  }, []);

  useEffect(() => {
    if (hasSavedFilters) onSearch();
  }, [hasSavedFilters]);

  const pushHistoryState = (): void => {
    if (!window.history.pushState) return;

    let newUrl: string;

    const filterHistoryValue = searchValue ? `filter=${searchValue}` : '';
    const statusesHistoryValue = selectValue?.length > 0 ? `statuses=${selectValue.join(',')}` : '';
    const startHistoryDateFrom = dateFrom ? `startDateFrom=${dateFrom}` : '';
    const startHistoryDateTo = dateTo ? `startDateTo=${dateTo}` : '';
    const sortKey = sort_key? `sort_key=${sort_key}` : '';
    const sortOrder = sort_order? `sort_order=${sort_order}` : '';
    const page = currentPage ? `page=${currentPage}` : '';

    const searchValsArr = [
      filterHistoryValue,
      statusesHistoryValue,
      startHistoryDateFrom,
      startHistoryDateTo,
      sortKey,
      sortOrder,
      page
    ];

    searchValsArr.forEach((el) => {
      if (el) newUrl = newUrl ? `${newUrl}&${el}` : `?${el}`;
    });

    window.history.pushState({ path: `${window.location.pathname}${newUrl}` }, '', newUrl);
  };

  const checkCriteriaFilter = (val: string): string => {
    const res = selectOptions.find((el) => el.value === val);
    return res?.filter ?? '';
  };

  const onSearch = (): void => {
    const searchArray = [];

    searchValue?.split(' ').filter(c => c !== '')
      .forEach((criteria) => {
      if (criteria) {
        searchArray.push({
          name: 'composite',
          value: criteria,
          isArrayValue: true
        });
      }
    });

    selectValue?.forEach((criteria) => {
      if (criteria) {
        searchArray.push({
          name: checkCriteriaFilter(criteria) || 'status',
          value: criteria,
          isArrayValue: true
        });
      }
    });

    if (dateFrom) {
      searchArray.push({
        name: 'start_time_from',
        value: dateFrom
      });
    }

    if (dateTo) {
      searchArray.push({
        name: 'start_time_to',
        value: dateTo
      });
    }

    setSearchItemsArray(searchArray);
    pushHistoryState();
    setLocalStorageFilters();
    if (action) {
      dispatch(action(clinicId, currentPage ?? 1, searchArray, 20, {sort_key, sort_order}));
    }
  };

  const onSelectValueChange = (vals): void => {
    setSelectValue(vals.filter((el) => el));
  };

  const selectOptionsFormatted = selectOptions?.map(
    (item): JSX.Element => (
      <Option key={item.value} title={item.label} value={item.value}>
        {item.label}
      </Option>
    )
  );

  const onResetFilters = (): void => {
    const filters = JSON.parse(localStorage.getItem(savedFiltersName));
    if (filters) {
      for (const key in filters) {
        if (+key === +clinicId) {
          filters[clinicId] = null;
        }
      }
      localStorage.setItem(savedFiltersName, JSON.stringify(filters));
    }

    setDateFrom(null);
    setDateTo(null);
    setSearchValue('');
    setSearchItemsArray([]);
    setSelectValue([]);
    if (action) {
      dispatch(action(clinicId, 1, [], 20, {sort_key, sort_order}));
    }

    let newUrl = null;
    if (hasSortingParams) {
      const sortKey = sort_key? `sort_key=${sort_key}` : '';
      const sortOrder = sort_order? `sort_order=${sort_order}` : '';
      const page = currentPage ? `page=${currentPage}` : '';
      newUrl = `?${sortKey}&${sortOrder}&${page}`;
      if (window.history.pushState) {
        window.history.pushState({ path: `${window.location.pathname}${newUrl}` }, '', newUrl);
      }
    } else {
      window.history.pushState(null, null, window.location.pathname);
    }
  };

  const setLocalStorageFilters = (): void => {
    const filters = JSON.parse(localStorage.getItem(savedFiltersName));
    if (filters) {
      filters[clinicId] = { searchValue, selectValue, dateFrom, dateTo };
      localStorage.setItem(savedFiltersName, JSON.stringify(filters));
    } else {
      localStorage.setItem(
        savedFiltersName,
        JSON.stringify({ [clinicId]: { searchValue, selectValue, dateFrom, dateTo } })
      );
    }
  };

  return (
    <Row className='composit-search-select-option-search'>
      <Input
        className='search-field composit-search-select-option-search-input'
        allowClear
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        placeholder={locale.placeholders.searchCriteria}
      />
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        className='composit-search-select-option-search-select'
        placeholder={selectPlaceholder}
        mode='multiple'
        showSearch={false}
        onChange={onSelectValueChange}
        value={selectValue}
        ref={selectRef}
      >
        {selectOptionsFormatted}
      </Select>
      {enableFilteringByDate && (
        <>
          <DatePicker
            className='composit-search-select-option-search-date-picker'
            onChange={(e) => setDateFrom(e ? e.format('DD-MM-YYYY') : null)}
            format={'DD-MM-YYYY'}
            value={dateFrom ? dayjs(dateFrom, 'DD-MM-YYYY') : null}
            placeholder={locale.placeholders.dateFrom}
          />
          <DatePicker
            className='composit-search-select-option-search-date-picker'
            onChange={(e) => setDateTo(e ? e.format('DD-MM-YYYY') : null)}
            format={'DD-MM-YYYY'}
            value={dateTo ? dayjs(dateTo, 'DD-MM-YYYY') : null}
            placeholder={locale.placeholders.dateTo}
          />
        </>
      )}
      <Button
        className='composit-search-select-option-search-button'
        type='primary'
        onClick={() => onSearch()}
      >
        {locale.buttons.search}
      </Button>
      <Button
        className='composit-search-select-option-reset-button'
        disabled={!searchValue && !selectValue?.length && !dateFrom && !dateTo}
        icon={<CloseCircleOutlined style={{ margin: '0px' }} />}
        type='primary'
        onClick={onResetFilters}
      >
        {locale.buttons.resetFilters}
      </Button>
    </Row>
  );
};

export default VLXCompositeSearchWithSelect;
