import { IClient, IClientsList } from '../../interfaces/client';

export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_FILTERED_CLIENTS = 'GET_FILTERED_CLIENTS';
export const SHOW_CLIENT_MODAL = 'SHOW_CLIENT_MODAL';
export const SET_CLIENTS_LOADING = 'SET_CLIENTS_LOADING';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CLIENT_PATIENTS = 'SET_CLIENT_PATIENTS';
export const SET_CLIENT_DOCUMENTS_LIST = 'SET_CLIENT_DOCUMENTS_LIST';
export const MERGE_CLIENT_DOCUMENTS_LIST = 'MERGE_CLIENT_DOCUMENTS_LIST';
export const RESET = '[clients] RESET';

export interface ClientsResetAction {
  type: typeof RESET;
}

export interface GetClientsAction {
  type: typeof GET_CLIENTS;
  payload: IClientsList;
}

export interface GetFilteredClientsAction {
  type: typeof GET_FILTERED_CLIENTS;
  payload: IClientsList;
}

export interface ShowModalAction {
  type: typeof SHOW_CLIENT_MODAL;
  payload: boolean;
}

export interface SetLoadingAction {
  type: typeof SET_CLIENTS_LOADING;
  payload: boolean;
}

export interface SetSelectedClientAction {
  type: typeof SET_CLIENT;
  payload: IClient | null;
}

export interface SetClientPatientsAction {
  type: typeof SET_CLIENT_PATIENTS;
  payload: any;
}

export interface SetSelectedClientDocumentsListAction {
  type: typeof SET_CLIENT_DOCUMENTS_LIST;
  payload: IClient | null;
}

export interface MergeClientDocumentsListAction {
  type: typeof MERGE_CLIENT_DOCUMENTS_LIST;
  payload: IClient | null;
}

export type ClientsActions =
  | GetClientsAction
  | ClientsResetAction
  | ShowModalAction
  | SetLoadingAction
  | SetSelectedClientAction
  | SetClientPatientsAction
  | GetFilteredClientsAction
  | SetSelectedClientDocumentsListAction
  | MergeClientDocumentsListAction;
