import 'layout/modals/finances/addBill/AddBill.scss';
import '../SalesDetails.scss';

import { Button, Col, Form, InputNumber, notification, Row, Tag, Tooltip } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import VLXInputNumber from 'components/VLXInputNumber';
import { paymentStatuses } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { IClient } from 'interfaces/client';
import { ILocale } from 'interfaces/locale';
import { ISaleDetails } from 'interfaces/sales';
import { ClientsSelect } from 'layout/components/clientsSelect/ClientsSelect.tsx';
import AddClient from 'layout/modals/addClient/AddClient';
import { AddBillPayment } from 'layout/modals/finances/addBillPayment/AddBillPayment';
import { BillContentToPrint } from 'pages/finances/bills/print/BillContentToPrint';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { showModal as showCreateNewClientModal } from 'redux/clients/actions';
import { showPaymentsModal } from 'redux/finances/bills/actions';
import { getBillsDetails } from 'redux/finances/bills/actions.ts';
import { createSale, removeSale, setSalesDetails, updateSale } from 'redux/sales/actions';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import { ShowPaymentResponse } from 'services/clinic/payments/models/ShowPaymentResponse.ts';

import { renderPaymentStatus } from '../../appointments/AppointmentsBaseHelper';
import { formPayloadItems } from '../utils/formPayloadItems';

type Props = {
  saleDetails: ISaleDetails;
  locale: ILocale;
  setSaleEditable: (boolean) => void;
  selectedClient: IClient;
  setSelectedClient: (IClient) => void;
  saleEditable: boolean;
  setFieldsTouched: (boolean) => void;
  fieldsTouched: boolean;
};

type TotalInfoProps = {
  id: string;
  content: string;
  title: string;
};

const TotalInfo = ({
  id,
  content,
  title
}: TotalInfoProps): JSX.Element => (
  <Row className='sale-form__top-cont__count-item'>
    <div className='sale-form__top-cont__count-item__cont'>
      <span className='price-label'>{title}</span>
    </div>
    <div className='sale-form__top-cont__count-item__cont' style={{ marginLeft: '10px' }}>
      <span id={id}>{content}</span>
    </div>
  </Row>
);

const findClient = (id: number, clients: IClient[]): IClient =>
  clients.find((client) => client.id === id);

export const SaleDetailsOverview = ({
  saleDetails,
  locale,
  saleEditable,
  setSaleEditable,
  selectedClient,
  setSelectedClient,
  setFieldsTouched,
  fieldsTouched
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clinicId = useClinicId();
  const [form] = Form.useForm();
  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);
  const contentToPrint = useRef(null);

  const clients = useSelector(({ clients }: IAppState) => clients.filteredClients);
  const billData = useSelector(({ bills }: IAppState) => bills.selectedBill);
  const openedShift = useSelector(({ shifts }: IAppState) => shifts.openedShift);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [discountPrice, setDiscountPrice] = useState(0);
  const showRemainingPrice = saleDetails?.payment_status === 'partially_paid';

  useEffect((): void => {
    if (saleDetails?.client) {
      setSelectedClient(saleDetails?.client);
    }
  }, [saleDetails]);

  useEffect((): void => {
    if (saleDetails) {
      form.setFieldValue(['stock_discount', 'amount'], saleDetails.stock_discount.amount || 0);
      form.setFieldValue(
        ['stock_discount', 'percentage'],
        saleDetails.stock_discount.percentage || 0
      );
      setDiscountPrice(saleDetails.stock_discount.amount);
    }
    setSaleEditable(saleDetails?.payment_status === 'unpaid');
  }, [saleDetails]);

    useEffect(()=>{
    if (saleDetails?.bill_id) dispatch(getBillsDetails(clinicId, saleDetails.bill_id));
  }, [saleDetails]);

  const handlePrint = useReactToPrint({
    documentTitle: '',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    removeAfterPrint: true
  });

  const selectClient = (id: number): void => {
    setSelectedClient(findClient(id, clients));
    setSaleEditable(true);
    setFormTouched();
  };

  const createClientCallback = (data: IClient): void => {
    setTimeout(() => setSelectedClient(data), 1500);
  };

  const setFormTouched = (): void => {
    if (!fieldsTouched) {
      setFieldsTouched(true);
    }
  };

  const onDiscountPercantageChange = (val: number): void => {
    const priceWithDiscount = saleDetails?.total_billed_amount * val * 0.01;
    form.setFieldValue(['stock_discount', 'amount'], +priceWithDiscount.toFixed(2));
    setDiscountPrice(priceWithDiscount);
    setFormTouched();
  };

  const onDiscountAmountChange = (val: number): void => {
    const priceWithDiscount = (val * 100) / saleDetails?.total_billed_amount;
    form.setFieldValue(['stock_discount', 'percentage'], +priceWithDiscount.toFixed(0));
    setDiscountPrice(val);
    setFormTouched();
  };

  const onConfirmCancelClick = (): void => {
    setShowCancelModal(false);
    form.resetFields();
  };

  const onConfirmDeleteClick = (): void => {
    dispatch(removeSale(clinicId, saleDetails?.id));
    navigate(`/clinic/${clinicId}/shop-farmacy`);
  };

  const onSubmit = (data): void => {
    const payload = {
      client_id: selectedClient?.id ?? null,
      items: saleDetails?.stock.map(formPayloadItems) || [],
      vat_included: false,
      amount: 0,
      fiscal: true,
      discount_amount: data.stock_discount.amount,
      discount_percentage: data.stock_discount.percentage
    };

    if (saleDetails) {
      dispatch(
        updateSale(+clinicId, saleDetails.id, payload, () => {
          notification.success({ message: locale.messages.dataSavedSuccessfully });
        })
      );
    } else {
      dispatch(
        createSale(+clinicId, payload, (data) => {
          if (data?.bill_id) {
            const newUrl = `/clinic/${clinicId}/shop-farmacy/${data.id}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
            notification.success({ message: locale.messages.dataSavedSuccessfully });
          }
        })
      );
    }
  };

  const getContentToPrint = (): JSX.Element => (
    <div className='bill-print-wrapper'>
      <div className='bill-print-content' ref={contentToPrint}>
        <BillContentToPrint data={billData} billId={saleDetails?.bill_id} />
      </div>
    </div>
  );

  return (
    <>
      {getContentToPrint()}
      <Form
        key={'SaleOverviewForm'}
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={{
          client_id: selectedClient?.id,
          amount: saleDetails?.total_billed_amount || 0,
          stock_discount: {
            amount: null,
            percentage: null
          }
        }}
        className='sale-form'
      >
        <Row justify={'space-between'} style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          {!!saleDetails?.created_at && (
            <span className='price-label' style={{ fontSize: '16px' }}>
            {dayjs(saleDetails?.created_at).format('DD.MM.YYYY HH:mm')}
          </span>
          )}
          {!!saleDetails?.stock.length &&
            renderPaymentStatus(saleDetails?.payment_status, localizedPaymentStatuses)}
        </Row>
        <h5 className='price-label' style={{ marginBottom: '5px' }}>
          {locale.labels.clientInfo}
        </h5>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Col className='sale-form__top-cont__client-select'>
            <Form.Item className='long-value-form-item'>
              <ClientsSelect
                disabled={saleDetails && !saleEditable}
                onChange={(id) => selectClient(id)}
                clinicId={clinicId}
                selectedClientId={selectedClient?.id}
              />
            </Form.Item>
          </Col>
          {saleEditable && (
            <Button
              id='add-new-client'
              type={'primary'}
              style={{ borderRadius: 30 }}
              className='sale-form__top-cont__add-new-client-btn'
              icon={
                <span
                  className='icofont icofont-plus mr-2 sticky-btn-icon'
                  style={{ fontSize: '1.3em' }}
                />
              }
              onClick={() => dispatch(showCreateNewClientModal(true))}
            >
              <span className='create-client-txt'>{locale.buttons.createNew}</span>
            </Button>
          )}
          <AddClient simplifiedValidation hideDefaultBtn callbackFunction={createClientCallback} />
        </Row>
        {selectedClient && (
          <div className='card-content card-content--client-info'>
            {selectedClient.name && (
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.client}: </span>
                <Link
                  id='client-name'
                  className='custom-links'
                  to={`/clinic/${clinicId}/clients/${selectedClient.id}`}
                >
                  {selectedClient.name}
                  {blacklistedStatus(selectedClient)}
                </Link>
              </Col>
            )}
            {selectedClient.phone_number && (
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.phoneNumber}: </span>
                <Link
                  id='client-phone'
                  className='custom-links'
                  to={`tel:${selectedClient.country_code}${selectedClient.phone_number}`}
                >
                  {selectedClient.phone_number
                    ? `+${selectedClient.country_code}${selectedClient.phone_number}`
                    : '-'}
                </Link>
              </Col>
            )}
            {selectedClient.number_of_pets > 0 && (
              <Col>
                <span style={{ fontWeight: 600 }}>{locale.labels.numberOfPets}: </span>
                <span id='numberOfPets'>{selectedClient.number_of_pets}</span>
              </Col>
            )}
          </div>
        )}
        <Row
          className='sale-form__top-cont__count-item__cont'
          style={{
            justifyContent: 'space-between',
            marginTop: -5
          }}
        >
        </Row>
        <Row justify={'space-between'}>
          <Col
            sm={24}
            md={12}
            xl={15}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 20
            }}
          >
            <Col sm={10} xl={15} style={{ marginRight: '10px' }}>
              <Form.Item
                label={`${locale.labels.discount} (%)`}
                name={['stock_discount', 'percentage']}
              >
                <InputNumber
                  disabled={!saleDetails?.stock.length || !saleEditable}
                  placeholder='0'
                  id='discount-percentage'
                  max={100}
                  min={0}
                  type='number'
                  precision={0}
                  onChange={onDiscountPercantageChange}
                />
              </Form.Item>
            </Col>
            <Col sm={10} xl={15}>
              <Form.Item
                label={`${locale.labels.discount} (${locale.labels.uah})`}
                name={['stock_discount', 'amount']}
              >
                <VLXInputNumber
                  disabled={!saleDetails?.stock.length || !saleEditable}
                  placeholder='0'
                  id='discount-amount'
                  type='number'
                  precision={2}
                  max={saleDetails?.total_billed_amount}
                  min={0}
                  onChange={onDiscountAmountChange}
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <TotalInfo
          title={`${locale.labels.itemsCount}: `}
          id='itemsCount'
          content={`${saleDetails?.stock.length || 0}`}
        />
        <Row style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap'
        }}>
          <TotalInfo
            title={`${locale.labels.totalPrice}: `}
            id='totalPrice'
            content={`${(saleDetails?.total_billed_amount || 0).toFixed(2)} ${locale.labels.uah}`}
          />
          {!!saleDetails?.stock.length && (
            <Link
              to={`/clinic/${clinicId}/finances/bills/${saleDetails.bill_id}`}
              style={{ marginLeft: 10 }}
            >
              {' '}
              <span className='icofont-ui-file'></span>
            </Link>
          )}
        </Row>
        <Form.Item>
          <TotalInfo
            title={`${locale.labels.priceWithDiscount}: `}
            id='priceWithDiscount'
            content={`${((saleDetails?.total_billed_amount - discountPrice) || 0).toFixed(2)} ${locale.labels.uah}`}
          />
        </Form.Item>
        <TotalInfo
          title={`${locale.labels.paid}: `}
          id='totalPaidAmount'
          content={`${((saleDetails?.amount_with_discount - saleDetails?.remaining_amount) || 0).toFixed(2)}
              ${locale.labels.uah}`}
        />
        {showRemainingPrice && <Form.Item>
          <TotalInfo
            title={`${locale.labels.remainingAmount}: `}
            id='remainingAmount'
            content={`${(saleDetails?.remaining_amount).toFixed(2)} ${locale.labels.uah}`}
          />
        </Form.Item>}
        <>
          {!!saleDetails?.stock.length && (
            <>
              {saleDetails?.payment_status !== 'paid' && (
                <Row className='sale-form__top-cont__count-item'>
                  <Button
                    style={{
                      marginRight: 0,
                      marginTop: 10
                    }}
                    disabled={!openedShift?.open || !saleDetails || fieldsTouched}
                    onClick={() => dispatch(showPaymentsModal(true))}
                    className='change-status-basic-button'
                  >
                    <Tooltip
                      trigger={'hover'}
                      placement='topLeft'
                      title={!openedShift?.open ? locale.labels.paymentBlockedClosedShift : null}
                    >
                      <span className='icofont-money' />
                      <span>{locale.buttons.addPayment}</span>
                    </Tooltip>
                  </Button>
                </Row>
              )}
              <Row style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }} className='sale-form__top-cont__count-item'>
                <Col>
                  { fieldsTouched && <Tag
                    id='bill-status-type'
                    color={'red'}
                    style={{
                      fontWeight: 'bold',
                      maxHeight: 30
                    }}
                  >
                    {locale.labels.unsavedChanges}
                  </Tag>}
                </Col>
                <Col style={{ display: 'flex' }}>
                  <Button
                    id='add-more-stock'
                    className='icofont icofont-save inventory-update-buttons'
                    type='primary'
                    disabled={!saleEditable}
                    style={{
                      borderRadius: 30,
                      marginRight: 12,
                      width: 50,
                      height: 50
                    }}
                    onClick={() => {
                      form.submit();
                      setFieldsTouched(false);
                    }}
                  ></Button>
                  <Button
                    onClick={() => handlePrint(null, () => contentToPrint.current)}
                    block
                    type='primary'
                    shape={'circle'}
                    style={{ width: 50, height: 50, fontSize: 17, marginRight: 12 }}
                  >
                    <span className='icofont icofont-print' />
                  </Button>
                  <Button
                    disabled={!saleEditable}
                    style={{
                      width: 50,
                      height: 50
                    }}
                    onClick={() => {
                      if (saleDetails) {
                        setShowDeleteModal(true);
                      } else {
                        setShowCancelModal(true);
                      }
                    }}
                    className={`inventory-update-buttons icofont icofont-${
                      saleDetails ? 'trash' : 'ban'
                    }`}
                    danger
                    type='primary'
                    shape={'circle'}
                  ></Button>
                </Col>
              </Row>
            </>
          )}
        </>

        {!!saleDetails && (
          <AddBillPayment
            bill={
              {
                id: saleDetails.bill_id,
                remaining_amount: saleDetails.remaining_amount
              } as IBillDetails
            }
            hideAddButton
            onBillPaymentCallback={(data: ShowPaymentResponse) => {
              dispatch(
                setSalesDetails({
                  ...saleDetails,
                  remaining_amount: data.bill.remaining_amount,
                  payment_status: data.bill.remaining_amount > 0 ? 'partially_paid' : 'paid'
                })
              );
            }}
            openedShift={openedShift}
          />
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
        <ConfirmDeleteModal
          type={'device'}
          open={showDeleteModal}
          onOk={onConfirmDeleteClick}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Form>
    </>
  );
};
