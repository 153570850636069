import './AuditDetails.scss';

import { Button, Form, Pagination, Table, TableColumnProps, Tag, Tooltip } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  destroyAuditItem,
  getAuditItemsById,
  updateAuditItem
} from 'redux/inventory/audit/actions';
import {
  IAuditItemPrice,
  ILocation,
  ISelectedAuditItem
} from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

import { Stat } from '../components/Stat';
import { AuditItemExapndableTable } from './AuditItemExapndableTable';
import { itemColumnTitle } from './utils/itemColumnTitle';

dayjs.extend(customParseFormat);

export const AuditItemsTable = ({
  itemsData,
  selectedAudit,
  currentPage,
  setCurrentPage,
  locale,
  clinicId
}: any): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const measurements = useI18n(measurementsLocale);
  const packages = useI18n(packagesLocale);
  const currencies = useLocale('private.currencies').labels;

  const getLoading = useSelector(({ audit }: IAppState) => audit.getLoading);
  const metadata = useSelector(({ audit }: IAppState) => audit.selectedAuditItems.metadata);

  const [expanded, setExpanded] = useState([]);
  const [expandedLocationsData, setExpandedLocationsData] = useState<ILocation[]>([]);
  const [expandedPricesData, setExpandedPricesData] = useState<IAuditItemPrice[]>([]);

  const [selectedStock, setSelectedStock] = useState<ISelectedAuditItem>(null);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState<boolean>(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  useEffect((): void => {
    if (!expanded?.length) return;

    const newVals = itemsData.find((el) => el.id === expanded[0]);
    if (!newVals) return;

    setExpandedLocationsData(newVals?.locations);
    setExpandedPricesData(newVals?.prices);
  }, [itemsData]);

  useEffect(() => {
    if (metadata.last < currentPage) {
      setCurrentPage(metadata.last);
    }
  }, [metadata]);

  const displayTotalStats = (stock: ISelectedAuditItem): JSX.Element => (
    <>
      {Number(stock.global_stats.items_surplus_packages) !== 0 && (
        <>
          <Stat color='green'>
            {`+${stock.global_stats.items_surplus_packages} ${
              packages[stock.packaging_format]
            } (~${stock.global_stats.money_surplus_packages?.toFixed(2)} ${currencies.uah})`}
          </Stat>
          <br />
        </>
      )}
      {Number(stock.global_stats.items_shortage_packages) !== 0 && (
        <>
          <Stat color='red'>
            {`-${Math.abs(stock.global_stats.items_shortage_packages)} ${
              packages[stock.packaging_format]
            } (~${Math.abs(stock.global_stats.money_shortage_packages).toFixed(2)} ${
              currencies.uah
            })`}
          </Stat>
          <br />
        </>
      )}
      {/* TODO implement proper handling of depleted items(on backend) @yubozhenko
         {Number(stock.totalRemove) !== 0 && (
          <Stat color='grey'>
            {locale.labels.toBeArchived} - {stock.totalRemove} ({locale.labels.depletedItems})
          </Stat>
        )} */}
    </>
  );

  const columns = useMemo(
    (): TableColumnProps<ISelectedAuditItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (name, e) => (
          <Link className={'custom-links'} to={`/clinic/${clinicId}/inventory/stock/${e?.id}`}>
            {e?.name}
          </Link>
        )
      },
      {
        key: 'manufacturer',
        title: locale.labels.manufacturer,
        sorter: (a, b) => (a.manufacturer > b.manufacturer ? 1 : -1),
        render: (e) => e.manufacturer || '-'
      },
      {
        key: 'completed',
        title: locale.labels.status,
        sorter: (a, b) => (a.progress_status > b.progress_status ? 1 : -1),
        render: ({ progress_status, global_stats }): JSX.Element => {
          const isFinished = progress_status === 'finished';

          if (progress_status === 'no_match') {
            return (
              <Tooltip title={locale.labels.incorrectQuantityTooltip}>
                <Tag id='appointment-status' color={'red'}>
                  {locale.labels.incorrectQuantity}
                </Tag>
              </Tooltip>
            );
          }
          return (
            <Tag id='appointment-status' color={isFinished ? 'green' : 'red'}>
              {isFinished ? locale.labels.completed : locale.labels.uncompleted}
            </Tag>
          );
        }
      },
      {
        key: 'category',
        title: locale.labels.category,
        sorter: (a, b) => (a.category > b.category ? 1 : -1),
        render: (e) => e.category || '-'
      },
      {
        key: 'total_diff',
        title: itemColumnTitle(locale.labels.statistics),
        render: displayTotalStats
      },
      {
        key: 'measurements',
        title: locale.labels.measurements,
        render: (e: ISelectedAuditItem) =>
          e.packaging_format === 'none'
            ? measurements[e.unit_of_measurement]
            : `1 ${packages[e.packaging_format] || ''} = ${e.quantity_per_package} ${
                measurements[e.unit_of_measurement]
              }`
      },
      {
        key: 'delete',
        title: locale.labels.operation,
        render: (e: ISelectedAuditItem) => (
          <div className='buttons-list nowrap center'>
            <Button
              style={{ zIndex: 8 }}
              onClick={() => {
                setItemIdToDelete(e.id);
                setShowDeleteItemModal(true);
              }}
              danger
              type='primary'
              shape='circle'
              className={'inventory-update-buttons'}
            >
              <span className='icofont icofont-trash' />
            </Button>
          </div>
        )
      }
    ],
    [itemsData]
  );

  const changeDataInStockList = (id: number, quantity: string, leftover: string): void => {
    const payload = {
      actual_quantity: Number(quantity),
      actual_leftover: Number(String(leftover || 0).replace(',', '.'))
    };

    dispatch(updateAuditItem(clinicId, selectedAudit.id, id, payload, currentPage));
  };

  const expandedRowRender = (): JSX.Element => (
    <>
      <AuditItemExapndableTable
        title={locale.labels.prices}
        status={selectedAudit.status}
        selectedStock={selectedStock}
        expandedData={expandedPricesData}
        locale={locale}
        changeDataInStockList={changeDataInStockList}
        isPriceTable
      />
      <AuditItemExapndableTable
        title={locale.labels.locations}
        status={selectedAudit.status}
        selectedStock={selectedStock}
        expandedData={expandedLocationsData}
        locale={locale}
        changeDataInStockList={changeDataInStockList}
      />
    </>
  );

  const removeStockItem = (stockId: number): void => {
    dispatch(destroyAuditItem(clinicId, selectedAudit.id, stockId, currentPage));
  };

  const onConfirmDeleteItemClick = (): void => {
    removeStockItem(itemIdToDelete);
    setItemIdToDelete(null);
    setShowDeleteItemModal(false);
  };

  const onPageChange = (page: number): void => {
    setCurrentPage(page);
    dispatch(getAuditItemsById(clinicId, selectedAudit.id, page));
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          dataSource={itemsData}
          loading={getLoading}
          columns={
            selectedAudit?.status !== 'finished'
              ? columns
              : columns.filter((el) => el.key !== 'delete')
          }
          rowKey='id'
          pagination={false}
          className='accent-header audits-list-table'
          expandable={{
            onExpand: (expanded, record: ISelectedAuditItem) => {
              const current = expanded ? [record.id] : [];
              setSelectedStock(record);
              setExpanded(current);
              setExpandedLocationsData(record?.locations);
              setExpandedPricesData(record?.prices);
            },
            expandedRowKeys: expanded,
            expandedRowRender
          }}
        />
        <Pagination
          defaultCurrent={currentPage}
          pageSize={20}
          showSizeChanger={false}
          showLessItems
          // hideOnSinglePage
          current={metadata.page}
          total={metadata.total}
          onChange={onPageChange}
        />
      </Form>
      <ConfirmDeleteModal
        type={'item'}
        open={showDeleteItemModal}
        onOk={onConfirmDeleteItemClick}
        onCancel={() => {
          setShowDeleteItemModal(false);
          setItemIdToDelete(null);
        }}
      />
    </>
  );
};
