import './AppointmentDetails.scss';

import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Popover, Row, Tooltip } from 'antd';
import { appointmentStatuses, paymentStatuses } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppointment } from 'interfaces/appointment';
import AddClient from 'layout/modals/addClient/AddClient';
import EditAppointment from 'layout/modals/editAppointment/EditAppointment';
import { AddBillPayment } from 'layout/modals/finances/addBillPayment/AddBillPayment';
import { ViewEditBillModal } from 'layout/modals/viewEditBill/ViewEditBillModal';
import { BillContentToPrint } from 'pages/finances/bills/print/BillContentToPrint';
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { patchAppointmentDetails, showBillModal, showEditAppoinmentModal } from 'redux/appointments/actions';
import { showModal } from 'redux/clients/actions';
import { showPaymentsModal } from 'redux/finances/bills/actions';
import { getTime } from 'utils/get-time';
import { getParticipants, getServices } from 'utils/string-from-entities-list';

import { IAppState } from '../../interfaces/app-state.ts';
import { renderAppointmentStatus, renderPaymentStatus } from './AppointmentsBaseHelper';
import CancellationForm from './components/CancellationForm';

const AppointmentDetailsTopContainer = ({
  appointment,
  servicesStockStatus,
  appointmentBill,
  appointmentPatients,
  locale,
  currencies,
  clinicId,
  appointmentClient,
  dispatch,
  disableAppointmentEdit,
  openedShift,
  appointmentLoading
}: any): JSX.Element => {
  const contentToPrint = useRef(null);

  const localizedStatuses = useLocalizedList(appointmentStatuses);
  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);
  const [showMoreNotes, setShowMoreNotes] = useState<boolean>(false);
  const [showCancellationModal, setShowCancellationModal] = useState<boolean>(false);
  const renderBillModal = useSelector(({ appointments }: IAppState) => appointments.showBillModal);

  const toggleShowMore = (): void => {
    setShowMoreNotes(!showMoreNotes);
  };

  const ClientAddress = useMemo((): JSX.Element => {
    const address =
      appointmentClient &&
      ['country', 'region', 'city', 'address']
        .map((k) => appointmentClient[k])
        .filter((value) => value && value !== '')
        .join(', ');
    return (
      address && (
        <>
          <span style={{ fontWeight: 600 }}>{locale.labels.address}: </span>
          <span id='client-address'>{address}</span>
        </>
      )
    );
  }, [appointmentClient]);

  const Content = (appointment: IAppointment): JSX.Element => {
    return (
      <div>
        <p style={{ marginBottom: '2px' }}>
          {locale.labels.cancelledBy}:{' '}
          {`${appointment?.cancellation?.cancelled_by?.name} (${appointment?.cancellation?.cancelled_by?.title})`}
        </p>
        <p style={{ marginBottom: '2px' }}>
          {locale.labels.reason}: {`${appointment?.cancellation?.reason}`}
        </p>
      </div>
    );
  };

  const editClientButton = (act: string): JSX.Element => (
    <EditOutlined
      className='appointment-details-top-container__edit-appointmnet-info'
      id='edit-button'
      onClick={() => {
        if (act === 'appointment') {
          dispatch(showEditAppoinmentModal(true));
        } else {
          dispatch(showModal(true));
        }
      }}
    />
  );

  const handleStatusChange = (status: string): void => {
    appointment.status = status;
    const payload = statusPayload(status);
    dispatch(patchAppointmentDetails(clinicId, appointment, payload));
  };

  const onFinishStatusChange = (): void => {
    dispatch(showBillModal(true));
  };

  const statusPayload = (status: string) => ({
    properties: [
      {
        name: 'status',
        value: status
      }
    ]
  });

  function healthRecordsNotFinalized (appPatients): boolean {
    return appPatients?.some((patient) => patient.health_record === null) ?? false;
  }

  function allowToStartAppointment (): boolean {
    const timeNow = dayjs();
    const appointmentStartTime = dayjs(appointment?.start_time);

    return appointmentStartTime.diff(timeNow, 'hours') <= 3;
  }

  function InventoryItemsNotFinalized (servStockStatus): boolean {
    let res = false;
    if (servStockStatus?.length) {
      res = servicesStockStatus.some((service) => service.status === false);
    }
    return res;
  }

  function toolTipMessage (): string | null {
    // if (!canFinishAppointment()) {
    //   return locale.messages.appointmentCanNotBeFinished;
    // } //clarified at 2 clinics, they can finalize appointment for each other, let's not block for now
    if (healthRecordsNotFinalized(appointmentPatients)) {
      return locale.messages.patientsHealthRecordsNotFinalized;
    }
    if (InventoryItemsNotFinalized(servicesStockStatus)) {
      return locale.messages.inventoryItemsNotFinalized;
    }

    return null;
  }

  const handlePrint = useReactToPrint({
    documentTitle: '',
    removeAfterPrint: true
  });

  const getContentToPrint = useCallback((): ReactNode => {
    return <div className='bill-print-wrapper'>
      <div className='bill-print-content' ref={contentToPrint}>
        <BillContentToPrint data={appointmentBill} billId={appointmentBill?.id} />
      </div>
    </div>;
  }, [appointmentBill]);

  const StatusButton = useMemo((): JSX.Element => {
    switch (appointment?.status.toLowerCase()) {
      case 'future':
        return (
          <>
            <Button
              id='start-appointment'
              disabled={!allowToStartAppointment()}
              onClick={() => handleStatusChange('in_progress')}
              className='change-status-basic-button'
            >
              <Tooltip
                trigger={'hover'}
                placement='topLeft'
                title={
                  !allowToStartAppointment() ? locale.messages.appointmentCanNotBeStarted : null
                }
              >
                <span className='icofont-play-alt-1' />
                <span style={{ verticalAlign: 'middle' }}>{locale.buttons.start}</span>
              </Tooltip>
            </Button>
            <Button
              id='cancel-appointment'
              onClick={() => setShowCancellationModal(true)}
              className='cancel-button'
            >
              <span className='icofont-ui-block' />
              <span>{locale.buttons.cancel}</span>
            </Button>
          </>
        );
      case 'in_progress':
        return (
          <>
            <Tooltip trigger={'hover'} placement='topLeft' title={toolTipMessage()}>
              <Button
                id='finish-appointment'
                className='change-status-basic-button'
                disabled={
                  healthRecordsNotFinalized(appointmentPatients) ||
                  InventoryItemsNotFinalized(servicesStockStatus)
                }
                onClick={() => onFinishStatusChange()}
              >
                <span className='icofont-stop' />
                <span>{locale.buttons.finish}</span>
              </Button>
            </Tooltip>
            <Button
              id='cancel-appointment'
              onClick={() => setShowCancellationModal(true)}
              className='cancel-button'
            >
              <span className='icofont-ui-block' />
              <span>{locale.buttons.cancel}</span>
            </Button>
          </>
        );
      case 'finished':
        return <Row>
          {appointmentBill?.remaining_amount > 0 && <Button
            id='add-payment'
            disabled={!openedShift?.open}
            onClick={() => dispatch(showPaymentsModal(true))}
            className='change-status-basic-button'
          >
            <Tooltip
              trigger={'hover'}
              placement='topLeft'
              title={!openedShift?.open ? locale.labels.paymentBlockedClosedShift : null}
            >
              <span className='icofont-money' />
              <span>{locale.buttons.addPayment}</span>
            </Tooltip>
          </Button>}
          <Button
            onClick={() => handlePrint(null, () => contentToPrint.current)}
            block
            type='primary'
            shape={'circle'}
            style={{
              width: 40,
              height: 40,
              fontSize: 17
            }}
          >
            <span className='icofont icofont-print' />
          </Button>
        </Row>;
      default:
        return null;
    }
  }, [
    appointment?.status,
    appointmentBill?.remaining_amount,
    openedShift?.open,
    appointmentPatients,
    servicesStockStatus
  ]);

  const showPrice = (): boolean => appointment.status === 'finished';

  const showRemainedPrice = (): boolean => appointmentBill?.payment_status === 'partially_paid';

  return (
    <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
      {getContentToPrint()}
      <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
        <Row className='cards-container'>
          <Card className='ant-card-bordered personal-info-card with-shadow'>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Col
                span={16}
                style={{
                  marginBottom: '5',
                  marginTop: '10px'
                }}
              >
                <Row style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <Col span={12} style={{
                    display: 'contents'
                  }}>
                <span
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    padding: '4px'
                  }}
                >
                  {appointment?.title}
                </span>
                  </Col>
                  <Col span={4}>
                    <>
                      {renderAppointmentStatus(appointment?.status, localizedStatuses)}
                      {appointment?.status === 'cancelled' && (
                        <Popover
                          placement='topLeft'
                          title={locale.labels.appointmentCancelled}
                          content={Content(appointment)}
                        >
                          <span className='icofont-info-circle' style={{ fontSize: '15px' }} />
                        </Popover>
                      )}
                    </>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginTop: '10px' }}>{StatusButton}</Col>
            </Row>
            <Row
              style={{
                marginTop: '5px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {showPrice() && (
                  <>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        padding: '4px'
                      }}
                    >
                      {locale.labels.price}: {appointmentBill?.final_amount} {currencies.uah}
                      <Link to={`/clinic/${clinicId}/finances/bills/${appointmentBill?.id}`}>
                        {' '}
                        <span className='icofont-ui-file'></span>
                      </Link>
                    </span>
                    {renderPaymentStatus(appointmentBill?.payment_status, localizedPaymentStatuses)}
                  </>
                )}
              </div>
              {showRemainedPrice() && (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    padding: '4px'
                  }}
                >
                  {locale.labels.remainedToPay}: {appointmentBill?.remaining_amount}{' '}
                  {currencies.uah}
                </span>
              )}
            </Row>
          </Card>
        </Row>
        <Row className='cards-container'>
          <Col span={24} md={14}>
            <Card className='ant-card-bordered personal-info-card with-shadow'>
              <div className='card-content'>
                <Col>{appointment?.start_time && getTime(appointment)}</Col>
                <Col>
                  {appointment?.services?.length > 0 && (
                    <>
                      {' '}
                      <span style={{ fontWeight: 600 }}>{locale.labels.services}: </span>
                      <span id='appointment-services'>{getServices(appointment?.services)}</span>
                    </>
                  )}
                </Col>
                <Col>
                  {appointment?.doctor?.first_name && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.doctor}: </span>
                      <Link
                        id='doctor-name'
                        className={'custom-links'}
                        to={`/clinic/${clinicId}/administration/employees/${appointment?.doctor?.id}`}
                      >{`${appointment?.doctor?.first_name} ${appointment?.doctor?.last_name} `}</Link>
                      (
                      <Link
                        id='doctor-phone'
                        to={`tel:${appointment?.doctor?.country_code}${appointment?.doctor?.phone_number}`}
                        className='d-flex align-baseline nowrap'
                      >
                        {appointment?.doctor?.phone_number
                          ? `+${appointment?.doctor?.country_code}${appointment?.doctor?.phone_number}`
                          : '-'}
                      </Link>
                      )
                    </>
                  )}
                </Col>
                <Col>
                  {appointment?.participants.length !== 0 && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.otherParticipants}: </span>
                      <span id='participants'>{getParticipants(appointment?.participants)}</span>
                    </>
                  )}
                </Col>
                <Col>
                  {appointment?.location?.details && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.location}: </span>
                      <span id='location'>
                        {appointment?.location?.details ? appointment?.location?.details : '-'}
                      </span>
                    </>
                  )}
                </Col>
                <Col>
                  {appointment?.notes && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.notes}: </span>
                      <span id='notes'>
                        {showMoreNotes
                          ? appointment?.notes
                          : `${appointment?.notes.substring(0, 250)}`}
                        {appointment?.notes.length > 250 && (
                          <span
                            style={{
                              color: '#49afb3',
                              cursor: 'pointer'
                            }}
                            onClick={toggleShowMore}
                          >
                            {showMoreNotes
                              ? ` ...${locale.labels.showLess}`
                              : ` ...${locale.labels.showMore}`}
                          </span>
                        )}
                      </span>
                    </>
                  )}
                </Col>
                {!disableAppointmentEdit && editClientButton('appointment')}
              </div>
            </Card>
          </Col>
          <Col span={24} md={10}>
            <Card className='ant-card-bordered personal-info-card with-shadow client-container'>
              <div className='card-content'>
                <Col>
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.client}: </span>
                    {!!appointmentClient && (
                      <Link
                        id='client-name'
                        className={'custom-links'}
                        to={`/clinic/${clinicId}/clients/${appointmentClient?.id}`}
                      >
                        {appointmentClient?.first_name + ' '}{' '}
                        {appointmentClient?.last_name !== null && appointmentClient?.last_name}
                      </Link>
                    )}
                    {blacklistedStatus(appointmentClient)}
                  </>
                </Col>
                <Col>
                  <>
                    <span style={{ fontWeight: 600 }}>{locale.labels.phoneNumber}: </span>
                    {!!appointmentClient && (
                      <Link
                        id='client-phone'
                        to={`tel:${appointmentClient?.country_code}${appointmentClient?.phone_number}`}
                        className='d-flex align-baseline nowrap'
                      >
                        {appointmentClient?.phone_number
                          ? `+${appointmentClient.country_code}${appointmentClient.phone_number}`
                          : '-'}
                      </Link>
                    )}
                  </>
                </Col>
                <Col style={{
                  flexWrap: 'nowrap',
                  display: 'flex'
                }}>
                  {appointmentClient?.email && (<>
                      <span style={{
                        fontWeight: 600,
                        minWidth: 55
                      }}>E-mail: </span>
                      <Link
                        id='client-email'
                        to={`mailto:${appointmentClient?.email}`}
                        className='custom-links d-flex align-baseline nowrap ellipsis-overflow-text'
                      >
                        {appointmentClient?.email}
                      </Link>
                    </>
                  )}
                </Col>
                <Col>{ClientAddress}</Col>
                <Col>
                  {appointmentClient?.notes && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.notes}: </span>
                      {appointmentClient?.notes}
                    </>
                  )}
                </Col>
                {!disableAppointmentEdit && editClientButton('client')}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <AddClient initialValues={appointmentClient} hideDefaultBtn editMode />
      <EditAppointment
        disableTimeEditing={disableAppointmentEdit || appointment.status === 'in_progress'}
        initialValues={appointment}
        hideDefaultCreatePetFormBtn
      />
      {renderBillModal && <ViewEditBillModal
        appointment={appointment}
        initialValues={appointmentBill}
        appointmentClient={appointmentClient}
      />}
      <AddBillPayment bill={appointmentBill} hideAddButton openedShift={openedShift} />
      <CancellationForm
        listView={false}
        appointmentId={appointment.id}
        clinicId={clinicId}
        loading={appointmentLoading}
        showCancellationModal={showCancellationModal}
        setShowCancellationModal={setShowCancellationModal}
      />
    </div>
  );
};

export default AppointmentDetailsTopContainer;
