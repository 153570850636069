import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeProvider, setShowEditProviderModal } from 'redux/inventory/providers/actions';
import { ISelectedProvidersItem } from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';

import { AddProviderForm } from './AddProviderForm';

type Props = {
  initialValues: ISelectedProvidersItem;
};

export const EditProvider = ({ initialValues }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const clinicId = useClinicId(); 
  const navigate = useNavigate();
  const locale = useLocale('private.inventory.providers'); 
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const { showEditModal } = useSelector<IAppState, any>((state) => state.providers);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(setShowEditProviderModal(false));
    setShowCancelModal(false);
  };

  const onSaveModalClick = () => {
  };

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  const onConfirmDeleteClick = () => {
    setShowDeleteModal(false);
    dispatch(removeProvider(clinicId, initialValues?.id)); 
    navigate(`/clinic/${clinicId}/inventory/providers`);
  };

  return (
    <>
      {initialValues && (
        <VLXModal
          title={locale.labels.editProvider}
          centered={true}
          form={form}
          open={showEditModal}
          onModalClose={() => {
            dispatch(setShowEditProviderModal(false));
          }}
          width={800}
        >
          <AddProviderForm 
            form={form}
            onFieldsChange={onFieldsChange}
            initialValues={initialValues}
            formEditMode
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.services}
              onSaveModalClick={onSaveModalClick}
              editMode={editMode}
              onEditClick={() => setEditMode(true)}
              hideDeleteBtn
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(setShowEditProviderModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </AddProviderForm>
        </VLXModal>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
}; 
