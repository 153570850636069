import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, Select, Typography } from 'antd';
import useClinicId from 'hooks/useClinicId';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendInvitation } from 'redux/invitations/actions';
import { getRoles } from 'redux/roles/actions';

const { Option } = Select;
const { Title } = Typography;

type Props = React.PropsWithChildren<{
  form: FormInstance;
}>;

const InvitationForm = ({ children, form }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const roles = useSelector(({ roles }: IAppState) => roles);

  useEffect(() => {
    if (clinicId) dispatch(getRoles(clinicId));
  }, [clinicId]);

  const onSubmit = (values: any): void => {
    dispatch(sendInvitation(values));
    form.resetFields();
  };

  const rolesList = useMemo(
    () =>
      roles.data.map(
        (item): JSX.Element => (
          <Option value={item.id} key={item.id}>
            {item.name}
          </Option>
        )
      ),
    [roles]
  );

  return (
    <Form
      initialValues={{ list: [undefined] }}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
    >
      <Title level={5}>Users:</Title>
      <Form.Item name='clinic_id' initialValue={clinicId} hidden>
        <Input />
      </Form.Item>
      <Form.List name='list'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className='row invitation-creation-form-row'>
                <Form.Item
                  {...restField}
                  name={[name, 'email']}
                  rules={[{ required: true, message: 'Email is required' }]}
                  className='col-md-4 col-sm-12'
                >
                  <Input placeholder='E-mail' />
                </Form.Item>
                <Form.Item
                  {...restField}
                  rules={[{ required: true, message: 'Phone number is required' }]}
                  name={[name, 'phone_number']}
                  className='col-md-4 col-sm-12'
                >
                  <Input placeholder='Phone Number' />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'role_id']}
                  rules={[{ required: true, message: 'Role is required' }]}
                  className='col-md-3 col-sm-12'
                >
                  <Select placeholder='Select a role'>{rolesList}</Select>
                </Form.Item>
                {key > 0 && (
                  <MinusCircleOutlined
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    className='col-md-1 col-sm-12'
                    onClick={() => remove(name)}
                  />
                )}
              </div>
            ))}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                Add user
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {children}
    </Form>
  );
};

export default InvitationForm;
