import { Form, Input, Select } from 'antd';
import { allCountries } from 'country-telephone-data';
import React from 'react';

const PhoneFieldFormItem = ({ localization, formItemPhonePrefix, formItemPhoneNumber, initialValues, hideLabel }: any) => {
  const countryOptions = [];
  allCountries.map((item, index) => {
    countryOptions.push(
      {
        label: item.name,
        value: item.dialCode,
        key: index
      }
    );
    return countryOptions;
  });

  const prefixSelector = (
    <Form.Item
      name={formItemPhonePrefix !== undefined ? formItemPhonePrefix : 'country_code'}
      initialValue={initialValues?.country_code ? initialValues?.country_code : '380'}
      noStyle>
      <Select
        style={{ width: 80 }}
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={countryOptions}
        optionLabelProp='value'
        popupClassName='custom-countries-list-options'
      >
      </Select>
    </Form.Item>
  );

  return (
    <Form.Item
      label={!hideLabel && localization.labels.phoneNumber}
      initialValue={initialValues?.phone_number ? initialValues?.phone_number : null}
      name={formItemPhoneNumber !== undefined ? formItemPhoneNumber : 'phone_number'}
      rules={[
        {
          required: true,
          pattern: (/^\+?([1-9][0-9]\d{7})$/),
          message: localization.messages.phoneNumberNotValid
        }
      ]}
    >
      <Input
        className={'input-group-custom'}
        addonBefore={prefixSelector}
        style={{ width: '100%' }}
        placeholder='(__)-___-____'
      />
    </Form.Item>
  );
};

export default PhoneFieldFormItem;
