export const yesNoUnknownOptions = [
  {
    ukUA: 'Так',
    enGB: 'Yes',
    value: 'yes'
  },
  {
    ukUA: 'Ні',
    enGB: 'No',
    value: 'no'
  },
  {
    ukUA: 'Невідомо',
    enGB: 'Unknown',
    value: 'unknown'
  }
];

export const yesNoBooleanOptions = [
  {
    ukUA: 'Так',
    enGB: 'Yes',
    value: true
  },
  {
    ukUA: 'Ні',
    enGB: 'No',
    value: false
  }
];

export const yesNoBooleanOptionsWithUnknown = [
  {
    ukUA: 'Так',
    enGB: 'Yes',
    value: 'yes'
  },
  {
    ukUA: 'Ні',
    enGB: 'No',
    value: 'no'
  },
  {
    ukUA: 'Невідомо',
    enGB: 'Unknown',
    value: 'unknown'
  }
];

export const animalSex = [
  {
    ukUA: 'Самець',
    enGB: 'Male',
    value: 'male'
  },
  {
    ukUA: 'Самка',
    enGB: 'Female',
    value: 'female'
  },
  {
    ukUA: 'Невідомо',
    enGB: 'Unknown',
    value: 'unknown'
  }
];
export const appointmentStatuses = [
  {
    value: 'future',
    ukUA: 'Запланований',
    enGB: 'Planned'
  },
  {
    value: 'in_progress',
    ukUA: 'В процесі',
    enGB: 'In progress'
  },
  {
    value: 'finished',
    ukUA: 'Завершений',
    enGB: 'Finished'
  },
  {
    value: 'no-show',
    ukUA: 'Не зʼявився',
    enGB: 'No-Show'
  },
  {
    value: 'cancelled',
    ukUA: 'Скасований',
    enGB: 'Cancelled'
  }
];

export const roomStatuses = [
  {
    ukUA: 'Активний',
    enGB: 'Active',
    value: 'active'
  },
  {
    ukUA: 'Обслуговування',
    enGB: 'Maintenance',
    value: 'maintenance'
  },
  {
    ukUA: 'Закритий',
    enGB: 'Closed',
    value: 'closed'
  }
];

export const locationTypes = [
  {
    ukUA: 'На місці',
    enGB: 'In-house',
    value: 'room'
  },
  {
    ukUA: 'Інше',
    enGB: 'Other',
    value: 'other'
  }
];

export const eventTypes = [
  {
    ukUA: 'Подія',
    enGB: 'Event',
    value: 'event'
  },
  {
    ukUA: 'Візит',
    enGB: 'Appointment',
    value: 'appointment'
  }
];

export const patientAggressiveness = [
  {
    ukUA: 'Дружелюбний',
    enGB: 'Friendly',
    value: 'friendly'
  },
  {
    ukUA: 'Реактивний',
    enGB: 'Reactive',
    value: 'reactive'
  },
  {
    ukUA: 'Помірно агресивний',
    enGB: 'Moderately aggressive',
    value: 'moderately-aggressive'
  },
  {
    ukUA: 'Дуже агресивний',
    enGB: 'Highly aggressive',
    value: 'highly-aggressive'
  }
];

export const stockStatuses = [
  {
    ukUA: 'В наявності',
    enGB: 'In stock',
    value: 'in_stock'
  },
  {
    ukUA: 'Закінчується',
    enGB: 'Running out',
    value: 'critical_level'
  },
  {
    ukUA: 'Закінчився',
    enGB: 'Out of stock',
    value: 'out_of_stock'
  }
];

export const vaccineAntigens = [
  {
    ukUA: 'Сказ',
    enGB: 'Rabies',
    value: 'rabies'
  },
  {
    ukUA: 'Котяча чума (панлейкопенія)',
    enGB: 'Feline Distemper (Panleukopenia)',
    value: 'panleukopenia'
  },
  {
    ukUA: 'Котячий герпесвірус',
    enGB: 'Feline Herpesvirus (FHV)',
    value: 'feline_herpesvirus'
  },
  {
    ukUA: 'Каліцивірус',
    enGB: 'Calicivirus (FCV)',
    value: 'calicivirus'
  },
  {
    ukUA: 'Вірус котячої лейкемії',
    enGB: 'Feline Leukemia Virus (FeLV)',
    value: 'feline_leukemia'
  },
  {
    ukUA: 'Бордетела',
    enGB: 'Bordetella',
    value: 'bordetella'
  },
  {
    ukUA: 'Чума',
    enGB: 'Distemper (CDV)',
    value: 'distemper'
  },
  {
    ukUA: 'Парвовірус',
    enGB: 'Parvovirus',
    value: 'parvovirus'
  },
  {
    ukUA: 'Аденовірус типу 1 (CAV-1, собачий гепатит)',
    enGB: 'Adenovirus, type 1 (CAV-1, canine hepatitis)',
    value: 'adenovirus_1'
  },
  {
    ukUA: 'Аденовірус, тип 2 (CAV-2)',
    enGB: 'Adenovirus, type 2 (CAV-2, kennel cough)',
    value: 'adenovirus_2'
  },
  {
    ukUA: 'Парагрип',
    enGB: 'Parainfluenza',
    value: 'parainfluenza'
  },
  {
    ukUA: 'Bordetella bronchiseptica (вольєрний кашель)',
    enGB: 'Bordetella bronchiseptica (kennel cough)',
    value: 'bordetella_bronchiseptica'
  },
  {
    ukUA: 'Borrelia burgdorferi (хвороба Лайма)',
    enGB: 'Borrelia burgdorferi (Lyme disease)',
    value: 'lyme'
  },
  {
    ukUA: 'Лептоспіроз',
    enGB: 'Leptospirosis',
    value: 'leptospirosis'
  },
  {
    ukUA: 'Собачий грип (influenza)',
    enGB: 'Canine influenza',
    value: 'canine_influenza'
  },
  {
    ukUA: 'Інше',
    enGB: 'Other',
    value: 'other'
  }
];

export const patientState = [
  {
    ukUA: 'Пацієнт здоровий',
    enGB: 'Healthy',
    value: 'healthy'
  },
  {
    ukUA: 'На лікуванні',
    enGB: 'In treatment',
    value: 'in treatment'
  },
  {
    ukUA: 'Ремісія',
    enGB: 'Remission',
    value: 'remission'
  },
  {
    ukUA: 'Власник відмовився від лікування',
    enGB: 'Owner refused treatment',
    value: 'owner refused treatment'
  }
];

export const financialAcountsOptions = [
  {
    ukUA: 'Активний',
    enGB: 'Active',
    value: 'active'
  },
  {
    ukUA: 'Неактивний',
    enGB: 'Inactive',
    value: 'inactive'
  },
  {
    ukUA: 'Заблокований',
    enGB: 'Blocked',
    value: 'blocked'
  }
];

export const financialAccountsTypesOptions = [
  {
    ukUA: 'Касовий апарат',
    enGB: 'Cash register',
    value: 'cash_register'
  },
  {
    ukUA: 'Банківський рахунок',
    enGB: 'Bank account',
    value: 'bank'
  },
  {
    ukUA: 'Готівка',
    enGB: 'Cash',
    value: 'cash'
  }
];

export const billType = [
  {
    ukUA: 'Дохід',
    enGB: 'Income',
    value: 'income'
  },
  {
    ukUA: 'Витрата',
    enGB: 'Outcome',
    value: 'outcome'
  }
];

export const paymentStatuses = [
  {
    ukUA: 'Оплачений',
    enGB: 'Paid',
    value: 'paid'
  },
  {
    ukUA: 'Очікує на оплату',
    enGB: 'Pending payment',
    value: 'unpaid'
  },
  {
    ukUA: 'Частково оплачений',
    enGB: 'Partially paid',
    value: 'partially_paid'
  },
  {
    ukUA: 'Переплачений',
    enGB: 'Overpaid',
    value: 'overpaid'
  },
  {
    ukUA: 'Не потребує оплати',
    enGB: 'Payment not required',
    value: 'payment_not_required'
  }
];

export const billTypes = [
  {
    ukUA: 'Магазин',
    enGB: 'Sale',
    value: 'sale'
  },
  {
    ukUA: 'Інше',
    enGB: 'Other',
    value: 'standalone'
  },
  {
    ukUA: 'Візит',
    enGB: 'Appointment',
    value: 'appointment'
  },
  {
    ukUA: 'Накладна',
    enGB: 'Invoice',
    value: 'invoice'
  }
];

export const auditStatuses = [
  {
    ukUA: 'В очікуванні',
    enGB: 'Pending',
    value: 'pending'
  },
  {
    ukUA: 'В процесі',
    enGB: 'In progress',
    value: 'in_progress'
  },
  {
    ukUA: 'Завершено',
    enGB: 'Finished',
    value: 'finished'
  }
];

export const appointmentListStatuses = [
  {
    value: 'future',
    ukUA: 'Запланований',
    enGB: 'Planned',
    filter: 'status'
  },
  {
    value: 'in_progress',
    ukUA: 'В процесі',
    enGB: 'In progress',
    filter: 'status'
  },
  {
    ukUA: 'Очікує на оплату',
    enGB: 'Pending payment',
    value: 'unpaid',
    filter: 'payment_status'
  },
  {
    ukUA: 'Частково оплачений',
    enGB: 'Partially paid',
    value: 'partially_paid',
    filter: 'payment_status'
  },
  {
    ukUA: 'Оплачений',
    enGB: 'Paid',
    value: 'paid',
    filter: 'payment_status'
  },
  {
    value: 'cancelled',
    ukUA: 'Скасований',
    enGB: 'Cancelled',
    filter: 'status'
  }
];

export const companyTypes = [
  {
    value: 'clinic',
    ukUA: 'Ветеринарна клініка',
    enGB: 'Veterinary clinic'
  },
  {
    value: 'veterinary_center',
    ukUA: 'Ветеринарний центр',
    enGB: 'Veterinary center'
  },
  {
    value: 'solo_practice',
    ukUA: 'Приватна практика',
    enGB: 'Private practice'
  },
  {
    value: 'grooming',
    ukUA: 'Грумінг салон',
    enGB: 'Grooming salon'
  },
  {
    value: 'rehabilitation',
    ukUA: 'Реабілітаційний центр',
    enGB: 'Rehabilitation center'
  },
  {
    value: 'shop_pharmacy',
    ukUA: 'Ветеринарна аптека/магазин',
    enGB: 'Veterinary pharmacy/store'
  }
  // , {
  //   ukUA: 'Тренувальний центр',
  //   enGB: 'Animal training center',
  //   value: 'training_center'
  // }, {
  //   ukUA: 'Готель для тварин',
  //   enGB: 'Pet boarding center',
  //   value: 'boarding'
  // }
];
