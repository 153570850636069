import { Form } from 'antd';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { IAppointment } from 'interfaces/appointment';
import { IClient } from 'interfaces/client';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showBillModal } from 'redux/appointments/actions';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';

import { ViewEditBill } from './ViewEditBill';

type Props = {
  initialValues: IBillDetails;
  appointment: IAppointment;
  appointmentClient: IClient;
};

export const ViewEditBillModal = ({
  initialValues,
  appointment,
  appointmentClient
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const locale = useLocale('private.appointments.event');

  const billModal = useSelector(({ appointments }: IAppState) => appointments.showBillModal);
  return (
    <VLXModal
      title={`${locale.labels.appointment}: ${initialValues?.id}`}
      centered={true}
      form={form}
      open={billModal}
      onModalClose={() => dispatch(showBillModal(false))}
      width={800}
    >
      <ViewEditBill
        form={form}
        appointment={appointment}
        appointmentClient={appointmentClient}
        initialValues={initialValues}
      />
    </VLXModal>
  );
};
