import { Dropdown } from 'antd';
import { locales } from 'constants/locales';
import * as localStorage from 'local-storage';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLocaleCode } from 'redux/localeCode/actions';

export function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Localization = (): JSX.Element => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState<any>();

  const onSelect = (e): void => {
    localStorage.set('locale', e.key);
    setLanguage(e.key);
    dispatch(setLocaleCode(e.key));
  };

  useEffect(() => {
    const localeCode = localStorage.get('locale');
    if (localeCode) setLanguage(localeCode);
  }, []);

  return (
    <Dropdown
      menu={{
        items: locales,
        onClick: onSelect
      }}
      trigger={['click']}
      placement='bottom'
    >
      <span className='locale-menu'>
        {locales.find((lang) => lang.key === language)?.label || 'UA'}
      </span>
    </Dropdown>
  );
};

export default Localization;
