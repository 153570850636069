import './ClinicInfo.scss';

import { CopyOutlined } from '@ant-design/icons';
import { Card, Col, Row, Spin, Tooltip, Typography } from 'antd';
import clinicDefaultLogo from 'assets/img/clinicDefaultLogo.png';
import profileBackground from 'assets/img/profile-bg.jpg';
import AvatarComponent from 'components/Avatar';
import { companyTypes } from 'constants/dictionary/default/selectOptions';
import { getSocialMediaData } from 'constants/dictionary/default/socialMediaOptions';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getClinic, setAvatar } from 'redux/clinic/actions';

import { ClinicInfoForm } from './ClinicInfoForm';

const ClinicInfo = (): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const locale = useLocale('private.administration.general');
  const editAccess = useUserAccess().accesses.clinics.edit;
  const companyTypesData = useLocalizedList(companyTypes);

  const clinic = useSelector(({ clinic }: IAppState) => clinic);
  const clinicLoading = useSelector(({ clinic }: IAppState) => clinic.loading);
  const userLoading = useSelector(({ user }: IAppState) => user.loading);

  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    dispatch(getClinic(clinicId));
  }, [clinicId]);

  const getCompanyTypeAndName = (type: string, name: string): string => {
    const localeObject = companyTypesData.find((el) => el.value === type);
    const companyType = localeObject?.label;

    return companyType ? `${companyType} "${name}"` : name;
  };

  const copyUserData = async (): Promise<boolean | void> => {
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 1000);
    const copyText = `${clinic.name}\n${locale.labels.phoneNumber}: +${clinic.country_code}${clinic.phone_number}\n${locale.labels.eMail}: ${clinic.email}\n${locale.labels.address}: ${clinic.location}`;

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(copyText);
    } else {
      return document.execCommand('copy', true, copyText);
    }
  };

  const handleSetAvatar = (data, callBackFnc: () => void): void => {
    const avatarData = new FormData();
    avatarData.append('avatar', data);
    dispatch(
      setAvatar(clinicId, avatarData, () => {
        setChangeAvatarModalVisible(false);
        if (callBackFnc) callBackFnc();
      })
    );
  };

  return (
    <Spin spinning={!clinic}>
      <div className='stack clinic-info-page'>
        <Row style={{ paddingTop: '20px' }}>
          <Col span={24} lg={24} style={{ padding: '0 24px' }}>
            <Col span={24}>
              <Card
                className='ant-card-bordered personal-info-card with-shadow'
                cover={<img alt='cover' src={profileBackground} />}
              >
                <Row justify='center' style={{ marginTop: '-50px' }}>
                  <AvatarComponent
                    loading={userLoading}
                    editAccess={editAccess}
                    onImageChange={handleSetAvatar}
                    src={clinic.avatar || clinicDefaultLogo}
                    placeholder={clinicDefaultLogo}
                    avatarTitle={locale.labels.addLogo}
                    setChangeAvatarModalVisible={setChangeAvatarModalVisible}
                    changeAvatarModalVisible={changeAvatarModalVisible}
                  />
                </Row>
                <Row align='middle' justify='space-between' style={{ marginBottom: 15 }}>
                  <Typography.Title level={4}>
                    {getCompanyTypeAndName(clinic.company_type, clinic.name)}
                  </Typography.Title>
                  <Row>
                    <ClinicInfoForm clinic={clinic} loading={clinicLoading} />
                    {
                      <Tooltip
                        open={tooltipOpen}
                        mouseLeaveDelay={3}
                        title={locale.placeholders.copied}
                      >
                        <CopyOutlined onClick={copyUserData} className='copy-clinic-icon' />
                      </Tooltip>
                    }
                  </Row>
                </Row>
                <Row>{clinic.description ?? ''}</Row>
                <br />
                <Row justify='space-between' style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Col span={6} className='min-width-300'>
                    <Row align='middle' justify='space-between' style={{ marginBottom: 15 }}>
                      <Typography.Title style={{ margin: 0 }} level={5}>
                        {locale.labels.contacts}
                      </Typography.Title>
                    </Row>
                    <Row align='middle' style={{ marginBottom: '10px' }}>
                      <Col style={{ width: '50px' }}>
                        <span className='icofont-ui-touch-phone info-icon' />
                      </Col>
                      <Col>
                        <div>{locale.labels.phone}</div>+{clinic.country_code}
                        {clinic.phone_number}
                      </Col>
                    </Row>
                    <Row align='middle' style={{ marginBottom: '10px' }}>
                      <Col style={{ width: '50px' }}>
                        <span className='icofont-ui-message info-icon' />
                      </Col>
                      <Col>{clinic.email}</Col>
                    </Row>
                    <Row align='middle' style={{ marginBottom: '10px' }}>
                      <Col style={{ width: '50px' }}>
                        <span className='icofont-location-pin info-icon' />
                      </Col>
                      <Col>{clinic.location}</Col>
                    </Row>
                    <Typography.Title style={{ marginTop: '10px' }} level={5}>
                      {locale.labels.workingHours}
                    </Typography.Title>
                    <Row align='middle' style={{ marginBottom: '10px' }}>
                      <Col style={{ width: '50px' }}>
                        <span className='icofont-stopwatch info-icon' />
                      </Col>
                      <Col>{clinic.working_hours}</Col>
                    </Row>
                  </Col>
                  <Col span={12} className='min-width-300'>
                    {clinic.social_media_links && (
                      <Typography.Title style={{ marginTop: '10px' }} level={5}>
                        {locale.labels.socialMedia}
                      </Typography.Title>
                    )}
                    {clinic.social_media_links &&
                      JSON.parse(clinic.social_media_links)?.map((link, index) => {
                        const media = getSocialMediaData(link.type);
                        return (
                          <Row key={index} align='middle'>
                            <Col className='clinic-info-icon-style'>{media?.icon}</Col>
                            <Col id='client-email' className='clinic-media-link-container'>
                              <Link
                                to={`${media?.baseUrl}${link?.description}`}
                                style={{ fontSize: 15 }}
                                className='clinic-media-link'
                              >
                                {link?.description}
                              </Link>
                            </Col>
                          </Row>
                        );
                      })}
                  </Col>
                  <Col span={6} className='min-width-300'>
                    {clinic.additional_contacts?.length > 0 && (
                      <Typography.Title style={{ marginTop: '10px' }} level={5}>
                        {locale.labels.additionalContacts}
                      </Typography.Title>
                    )}
                    {clinic.additional_contacts?.length > 0 &&
                      JSON.parse(clinic.additional_contacts)?.map((contact, index) => (
                        <Row key={index} align='middle' style={{ paddingBottom: '10px' }}>
                          <Col className='icon-style' span={2} style={{ marginRight: 10 }}>
                            <span className='icofont-ui-touch-phone info-icon' />
                          </Col>
                          <Col span={10}>
                            <Link to={`tel:${contact?.phone_number}`} style={{ fontSize: 15 }}>
                              {contact?.phone_number}
                            </Link>
                          </Col>
                          <Col span={11}>
                            <span style={{ fontSize: 15 }}>{contact?.description}</span>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default ClinicInfo;
