import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { companyTypes } from 'constants/dictionary/default/selectOptions';
import { getIcon, socialMediaLinks } from 'constants/dictionary/default/socialMediaOptions';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editClinic, showModal } from 'redux/clinic/actions';
import { ClinicState } from 'redux/clinic/reducer';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  clinic: ClinicState;
  loading: boolean;
};

export const ClinicInfoForm = ({ clinic, loading }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const editAccess = useUserAccess().accesses.clinics.edit;
  const locale = useLocale('private.administration.general');
  const [form] = Form.useForm();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();

  useEffect(() => form.resetFields(), [clinic]);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  const onSubmit = (values: any): void => {
    values.markup_package = Number(clinic.markup_package);
    values.markup_unit = Number(clinic.markup_unit);
    values.social_media_links = values.social_media_links
      ? JSON.stringify(values.social_media_links)
      : null;
    values.additional_contacts = values.additional_contacts
      ? JSON.stringify(values.additional_contacts)
      : null;
    dispatch(editClinic(clinic.id, values));
  };

  const editButton = (): JSX.Element | null => {
    if (!editAccess) return null;
    return <EditOutlined style={{ fontSize: '20px' }} onClick={() => dispatch(showModal(true))} />;
  };

  const socialMediaList = (): JSX.Element[] =>
    socialMediaLinks.map((link, index) => (
      <Option key={index} value={link.value}>
        {link.value}
        {getIcon(link.key === 'other' ? 'page' : link.key, 16)}
      </Option>
    ));

  return (
    <>
      {editButton()}

      <VLXModal
        title={locale.labels.updateNewClinic}
        centered={true}
        form={form}
        open={clinic.showModal}
        onModalClose={() => dispatch(showModal(false))}
        width={800}
      >
        <Spin spinning={loading}>
          <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
            <Form.Item
              label={locale.labels.clinicName}
              name='name'
              rules={[{ required: true, message: locale.messages.nameEmpty }]}
              initialValue={clinic.name}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.type}
              name='company_type'
              initialValue={clinic.company_type}
            >
              <Select options={useLocalizedList(companyTypes)} />
            </Form.Item>
            <PhoneFieldFormItem
              formItemPhonePrefix={['country_code']}
              formItemPhoneNumber={['phone_number']}
              initialValues={clinic}
              localization={locale}
            />
            <Form.Item
              label={locale.labels.eMail}
              name='email'
              rules={[
                { required: true, message: locale.messages.eMailEmpty },
                {
                  required: true,
                  pattern: /^(?=.{1,256}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: locale.messages.emailNotValid
                }]}
              initialValue={clinic.email}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.address}
              name='location'
              rules={[{ required: true, message: locale.messages.addressEmpty }]}
              initialValue={clinic.location}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={locale.labels.workingHours}
              name='working_hours'
              rules={[{ required: true, message: locale.messages.workingHoursEmpty }]}
              initialValue={clinic.working_hours}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              label={locale.labels.description}
              name='description'
              initialValue={clinic.description}
            >
              <TextArea />
            </Form.Item>
            <Typography.Title level={5}>{locale.labels.socialMedia}</Typography.Title>
            <Form.List
              name='social_media_links'
              initialValue={clinic.social_media_links ? JSON.parse(clinic.social_media_links) : []}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Row key={key} gutter={[20, 10]}>
                      <Col span={22} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'type']}
                          rules={[
                            {
                              required: true,
                              message: locale.messages.selectSocialNetwork
                            }
                          ]}
                        >
                          <Select
                            placeholder={locale.placeholders.selectSocialMedia}
                            filterOption={(input, option) => {
                              return option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            showSearch
                          >
                            {socialMediaList()}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={22} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          rules={[
                            {
                              required: true,
                              message: locale.messages.specifyLink
                            }
                          ]}
                        >
                          <Input placeholder={locale.placeholders.linkToProfile} />
                        </Form.Item>
                      </Col>
                      <Button
                        onClick={() => {
                          remove(name);
                        }}
                        danger
                        type='primary'
                        shape={'circle'}
                        className={'inventory-update-buttons'}
                      >
                        <span className='icofont icofont-trash' />
                      </Button>
                    </Row>
                  ))}
                  <Row>
                    <Form.Item>
                      <Button
                        className='icofont icofont-plus'
                        type={'primary'}
                        style={{
                          borderRadius: 30,
                          fontWeight: 'bold'
                        }}
                        onClick={() => add()}
                      >
                        {fields.length
                          ? locale.buttons.addMore
                          : locale.buttons.addSocialMediaLinks}
                      </Button>
                    </Form.Item>
                  </Row>
                </>
              )}
            </Form.List>
            <Row>
              <Typography.Title level={5}>{locale.labels.additionalContacts}</Typography.Title>
            </Row>
            <Form.List
              name='additional_contacts'
              initialValue={
                clinic.additional_contacts ? JSON.parse(clinic.additional_contacts) : []
              }
            >
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Row key={key} gutter={[20, 10]}>
                      <Col span={22} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'phone_number']}
                          rules={[
                            {
                              required: true,
                              message: locale.messages.specifyPhoneNumber
                            }
                          ]}
                        >
                          <Input placeholder={locale.placeholders.phoneNumber} />
                        </Form.Item>
                      </Col>
                      <Col span={22} md={11}>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          rules={[
                            {
                              required: true,
                              message: locale.messages.specifyDescription
                            }
                          ]}
                        >
                          <Input placeholder={locale.placeholders.description} />
                        </Form.Item>
                      </Col>
                      <Button
                        onClick={() => {
                          remove(name);
                        }}
                        danger
                        type='primary'
                        shape={'circle'}
                        className={'inventory-update-buttons'}
                      >
                        <span className='icofont icofont-trash' />
                      </Button>
                    </Row>
                  ))}
                  <Row>
                    <Form.Item>
                      <Button
                        className='icofont icofont-plus'
                        type={'primary'}
                        style={{
                          borderRadius: 30,
                          fontWeight: 'bold'
                        }}
                        onClick={() => add()}
                      >
                        {fields.length
                          ? locale.buttons.addMore
                          : locale.buttons.addAdditionalContacts}
                      </Button>
                    </Form.Item>
                  </Row>
                </>
              )}
            </Form.List>
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.clinics}
              editMode={true}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </Form>
        </Spin>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
