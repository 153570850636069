import React from 'react';
import { Tag } from 'antd';
import './ClientsList.scss';
import { money } from '../../helpers/list-helpers';

export function paymentStatus (client, locale, currencies) {
  if (client?.diff !== undefined && client?.diff !== null) {
    if (client.diff < 0) {
      const diff = (Number(client.diff) * -1).toFixed(2);
      return <Tag id='appointment-status' color={'red'}>{locale.labels.debt}: {money(diff)} {currencies.uah}</Tag>;
    } else if (client.diff === 0) {
      return <Tag id='appointment-status' color={'green'}>{locale.labels.allPayed}</Tag>;
    } else {
      return <Tag id='appointment-status' color={'yellow'}>{locale.labels.overpaid}: {client.diff} {currencies.uah}</Tag>;
    }
  }
  return null;
}
