import './ViewAndEditPatient.scss';

import { Button, Col, DatePicker, Divider, Form, Input, Radio, RadioChangeEvent, Row, Select, Typography } from 'antd';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import VLXInputNumber from 'components/VLXInputNumber';
import animalsList from 'constants/dictionary/animals';
import { animalSex, patientAggressiveness, yesNoUnknownOptions } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createPatient, updatePatient } from 'redux/patients/actions';

import { ClientsSelect } from '../../components/clientsSelect/ClientsSelect.tsx';

const { TextArea } = Input;

const AddPatient = ({
  form,
  initialValues,
  formEditMode,
  children,
  getClientPetsAfterSuccess,
  callBackAfterCreate,
  disableOwnerSelect
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const params = useParams() as any;
  const locale = useLocale('private.patients.patient-page');
  const [ownerExists, setOnwerExists] = useState(true);
  const [showDeceasedFields, setShowDeceasedFields] = useState(false);
  const [selectedPetBreedsList, setSelectedPetBreedsList] = useState([]);
  const [selectedPetColorsList, setSelectedPetColorsList] = useState([]);
  const [selectedPetCoatsList, setSelectedPetCoatsList] = useState([]);
  const [selectedBreedLabel, setSelectedBreedLabel] = useState(locale.labels.breed);
  const [selectedOwnerId, setSelectedOwnerId] = useState(null);
  const [shouldResetForm, setShouldResetForm] = useState(true);

  useEffect(() => {
    form.resetFields();
    setSelectedPetBreedsList([]);
    setSelectedPetColorsList([]);
    setSelectedPetCoatsList([]);
  }, []);

  useEffect(() => {
    if (shouldResetForm) {
      if (formEditMode) {
        form.resetFields();
        setShouldResetForm(false);
      }
    }

    if (!!initialValues) {
      setSelectedOwnerId(initialValues.primary_contact_id ?? initialValues?.owner);

      if (!!initialValues.species) {
        const breeds = animalsList.find((el) => el.value === initialValues.species).breeds;
        const colors = animalsList.find((el) => el.value === initialValues.species).colors;
        const coats = animalsList.find((el) => el.value === initialValues.species).coats;

        if (breeds) {
          setSelectedPetBreedsList(breeds);
        } else {
          setSelectedPetBreedsList([]);
        }
        if (colors) {
          setSelectedPetColorsList(colors);
        } else {
          setSelectedPetColorsList([]);
        }
        if (coats) {
          setSelectedPetCoatsList(coats);
        } else {
          setSelectedPetCoatsList([]);
        }
      }
      if (showDeceasedFields !== initialValues.deceased) {
        setShowDeceasedFields(initialValues.deceased);
      }
    }
  }, [initialValues]);

  const onSubmit = (data: any) => {
    data.weight = data.weight
      ? `${Number(data.weight.toString().replace(',', '.')).toFixed(3)}`
      : '0';
    const apiData = {
      ...data,
      additional_contacts:
        data.additional_contacts?.length > 0 ? JSON.stringify(data.additional_contacts) : null
    };
    if (formEditMode) {
      if (data.death_date) {
        apiData.death_date = dayjs(data.death_date).format('DD.MM.YYYY');
      }
      if (data.birth_date) {
        apiData.birth_date = dayjs(data.birth_date).format('DD.MM.YYYY');
      } else {
        apiData.birth_date = null;
      }
      dispatch(
        updatePatient(
          clinicId,
          apiData,
          initialValues.id,
          params?.appointmentId,
          getClientPetsAfterSuccess
        )
      );
    } else {
      if (data.death_date) {
        apiData.death_date = dayjs(data.death_date).format('DD.MM.YYYY');
      }
      if (data.birth_date) {
        apiData.birth_date = dayjs(data.birth_date).format('DD.MM.YYYY');
      }
      dispatch(
        createPatient(
          clinicId,
          apiData,
          getClientPetsAfterSuccess,
          params?.appointmentId,
          callBackAfterCreate
        )
      );
    }
  };

  function remapInitialValues () {
    if (initialValues?.additional_contacts) {
      return {
        ...initialValues,
        additional_contacts: JSON.parse(initialValues?.additional_contacts)
      };
    } else {
      return initialValues;
    }
  }

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={remapInitialValues()}
    >
      <Form.Item name='clinic_id' initialValue={clinicId} hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.name}
            name={['name']}
            rules={[
              {
                required: true,
                message: locale.messages.requiredField
              },
              {
                pattern: /^.{1,100}$/,
                message: locale.messages.maximum100Chars
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.species}
            name={['species']}
            className='add-new-patient__owner-select'
            rules={[
              {
                required: true,
                message: locale.messages.inputSpecies
              }
            ]}
          >
            <Select
              filterOption={false}
              onChange={(val) => {
                const breeds = animalsList.find((el) => el.value === val).breeds;
                const colors = animalsList.find((el) => el.value === val).colors;
                const coats = animalsList.find((el) => el.value === val).coats;
                form.setFieldValue('color', null);
                form.setFieldValue('breed', null);
                form.setFieldValue('fur', null);
                setSelectedBreedLabel(val === 'exotic' || val === 'rodent' ? locale.labels.subtype : locale.labels.breed);
                if (breeds) {
                  setSelectedPetBreedsList(breeds);
                } else {
                  setSelectedPetBreedsList([]);
                }
                if (colors) {
                  setSelectedPetColorsList(colors);
                } else {
                  setSelectedPetColorsList([]);
                }
                if (coats) {
                  setSelectedPetCoatsList(coats);
                } else {
                  setSelectedPetCoatsList([]);
                }
              }}
              options={useLocalizedList(animalsList)}
            />
          </Form.Item>
          <Form.Item
            label={selectedBreedLabel}
            name={['breed']}
            className='add-new-patient__owner-select'
          >
            <Select
              disabled={selectedPetBreedsList.length === 0}
              filterOption={(input, option) => {
                return option.label.toString().toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
              options={useLocalizedList(selectedPetBreedsList)}
            />
          </Form.Item>
          <Form.Item label={locale.labels.chip} name={['chip_number']}>
            <Input
              onKeyDown={(event) => {
                if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.dateOfBirth}
            name='birth_date'
          >
            <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
          </Form.Item>
          <Form.Item
            // rules={[{ required: true, message: 'Please input Sex' }]}
            name='sex'
            label={locale.labels.sex}
          >
            <Select filterOption={false} options={useLocalizedList(animalSex)} />
          </Form.Item>
          <Form.Item label={locale.labels.weight} name={['weight']}>
            <VLXInputNumber placeholder='0' type='number' precision={3} step={0.01} />
          </Form.Item>
          <Form.Item label={locale.labels.color} name='color'>
            <Select
              disabled={selectedPetColorsList.length === 0}
              filterOption={(input, option) => {
                return option.label.toString().toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
              options={useLocalizedList(selectedPetColorsList)}
            />
          </Form.Item>
          <Form.Item name='fur' label={locale.labels.coat}>
            <Select
              filterOption={(input, option) => {
                return option.label.toString().toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
              disabled={selectedPetCoatsList.length === 0}
              options={useLocalizedList(selectedPetCoatsList)}
            />
          </Form.Item>
          <Form.Item
            name='blood_type'
            label={locale.labels.bloodType}
            rules={[{
              max: 15,
              message: locale.messages.max15Chars
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item name='neutering' label={locale.labels.neutering}>
            <Select filterOption={false} options={useLocalizedList(yesNoUnknownOptions)} />
          </Form.Item>
          <Form.Item name='aggressiveness' label={locale.labels.aggressiveness}>
            <Select filterOption={false} options={useLocalizedList(patientAggressiveness)} />
          </Form.Item>
          <Form.Item name='lives_outdoor' label={locale.labels.livesOutdoor}>
            <Radio.Group>
              <Radio value={true}>{locale.buttons.yes}</Radio>
              <Radio value={false}>{locale.buttons.no}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name='tattoo' label={locale.labels.tattoo}>
            <Input />
          </Form.Item>
          <Form.Item label={locale.labels.warnings} name={['warnings']}>
            <TextArea rows={3} />
          </Form.Item>
          <Form.Item label={locale.labels.lifeAnamnesis} name={['life_anamnesis']}>
            <TextArea rows={6} />
          </Form.Item>
          <Form.Item label={locale.labels.notes} name={['notes']}>
            <TextArea rows={3} />
          </Form.Item>
          <Form.Item
            help={locale.messages.specialNotesVisibility}
            label={locale.labels.specialNotes}
            name={['private_notes']}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name='deceased' label={locale.labels.deceased}>
            <Radio.Group
              onChange={({ target: { value } }: RadioChangeEvent) => {
                setShowDeceasedFields(value);
                if (value) {
                  form.setFieldValue('death_date', dayjs(new Date()));
                }
              }}
            >
              <Radio value={true}>{locale.buttons.yes}</Radio>
              <Radio value={false}>{locale.buttons.no}</Radio>
            </Radio.Group>
          </Form.Item>
          {showDeceasedFields && (
            <>
              <Form.Item
                label={locale.labels.dateOfDeath}
                name='death_date'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
              </Form.Item>
              <Form.Item label={locale.labels.reasonOfDeath} name={['deceaded_reason']}>
                <TextArea rows={5} />
              </Form.Item>
            </>
          )}
        </Col>
      </Row>
      <Row gutter={[20, 10]}>
        <Col span={24} md={12} style={{ borderTop: '1px solid grey' }}>
          <Typography.Title level={5}>{locale.labels.owner}</Typography.Title>
          {ownerExists && (
            <Row>
              <Col
                md={14}
                style={{
                  marginRight: 10,
                  minWidth: '100%'
                }}
              >
                <Form.Item
                  name={'owner'}
                  rules={[
                    {
                      required: true,
                      message: locale.messages.inputOwner
                    }
                  ]}
                >
                  <ClientsSelect
                    clinicId={clinicId}
                    selectedClientId={selectedOwnerId}
                    disabled={disableOwnerSelect}
                    onChange={(value) => {
                      setSelectedOwnerId(value);
                    }}
                  />
                </Form.Item>
              </Col>
              {!disableOwnerSelect && <Button
                id='add-new-owner'
                type={'primary'}
                style={{ borderRadius: 30 }}
                onClick={() => {
                  setOnwerExists(false);
                  form.setFieldValue('owner', null);
                }}
              >
                {locale.buttons.addNew}
              </Button>}
            </Row>
          )}
          {!ownerExists && (
            <div style={{ marginRight: 10 }}>
              <Form.Item
                label={locale.labels.firstName}
                name={['new_client', 'first_name']}
                rules={[
                  {
                    required: true,
                    message: locale.messages.inputFirstName
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={locale.labels.lastName}
                name={['new_client', 'last_name']}
                rules={[
                  {
                    required: true,
                    message: locale.messages.inputLastName
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <PhoneFieldFormItem
                formItemPhonePrefix={['new_client', 'country_code']}
                formItemPhoneNumber={['new_client', 'phone_number']}
                localization={locale}
                rules={[{ required: true }]}
              />
              <Form.Item label={locale.labels.address} style={{ marginBottom: 0 }}>
                <Form.Item
                  name={['new_client', 'country']}
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)',
                    marginRight: '16px'
                  }}
                >
                  <Input placeholder={locale.placeholders.country} />
                </Form.Item>
                <Form.Item
                  name={['new_client', 'region']}
                  style={{
                    display: 'inline-block',
                    width: 'calc(50% - 8px)'
                  }}
                >
                  <Input placeholder={locale.placeholders.region} />
                </Form.Item>
              </Form.Item>
              <Form.Item name={['new_client', 'city']}>
                <Input placeholder={locale.placeholders.city} />
              </Form.Item>
              <Form.Item name={['new_client', 'address']}>
                <Input placeholder={locale.placeholders.address} />
              </Form.Item>
              <Form.Item name={['new_client', 'email']} label={locale.labels.email}>
                <Input />
              </Form.Item>
              <Button
                type={'primary'}
                style={{ borderRadius: 30 }}
                onClick={() => {
                  setOnwerExists(true);
                  form.setFieldValue('owner', null);
                }}
              >
                {locale.buttons.chooseExisting}
              </Button>
            </div>
          )}
        </Col>
        <Col span={24} md={12} style={{ borderTop: '1px solid grey' }}></Col>
      </Row>
      <Divider />
      <Form.List name='additional_contacts'>
        {(fields, {
          add,
          remove
        }) => (
          <>
            {fields?.map(({
              key,
              name,
              ...restField
            }) => (
              <Row key={key} gutter={[20, 10]}>
                <Col span={22} md={11}>
                  <Form.Item
                    {...restField}
                    name={[name, 'phone_number']}
                    rules={[
                      {
                        required: true,
                        message: locale.messages.specifyPhoneNumber
                      }
                    ]}
                  >
                    <Input placeholder={locale.placeholders.phoneNumber} />
                  </Form.Item>
                </Col>
                <Col span={22} md={11}>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    rules={[
                      {
                        required: true,
                        message: locale.messages.specifyDescription
                      }
                    ]}
                  >
                    <Input placeholder={locale.placeholders.description} />
                  </Form.Item>
                </Col>
                <Button
                  onClick={() => {
                    remove(name);
                  }}
                  danger
                  type='primary'
                  shape={'circle'}
                  className={'inventory-update-buttons'}
                >
                  <span className='icofont icofont-trash' />
                </Button>
              </Row>
            ))}
            <Row>
              <Form.Item>
                <Button
                  className='icofont icofont-plus'
                  type={'primary'}
                  style={{
                    borderRadius: 30,
                    fontWeight: 'bold'
                  }}
                  onClick={() => add()}
                >
                  {fields.length ? locale.buttons.addAnother : locale.buttons.addAdditionalContacts}
                </Button>
              </Form.Item>
            </Row>
          </>
        )}
      </Form.List>
      {children}
    </Form>
  );
};

export default AddPatient;
