import { IClient, IClientsList } from 'interfaces/client';
import {
  createClinicClient,
  createClinicClientDocument,
  getClinicClientDetails,
  getClinicClientDocumentsList,
  getClinicClientPatients,
  getClinicClients,
  updateClinicClient,
  uploadAvatar
} from 'services/clinic/clients/clientsService';
import { ISortObject } from 'services/interfaces/ISortObject';

import {
  ClientsResetAction,
  GET_CLIENTS,
  GET_FILTERED_CLIENTS,
  GetClientsAction,
  GetFilteredClientsAction,
  MERGE_CLIENT_DOCUMENTS_LIST,
  MergeClientDocumentsListAction,
  RESET,
  SET_CLIENT,
  SET_CLIENT_DOCUMENTS_LIST,
  SET_CLIENT_PATIENTS,
  SET_CLIENTS_LOADING,
  SetClientPatientsAction,
  SetLoadingAction,
  SetSelectedClientAction,
  SetSelectedClientDocumentsListAction,
  SHOW_CLIENT_MODAL,
  ShowModalAction
} from './types';

export const resetClients = (): ClientsResetAction => ({
  type: RESET
});

export const setClients = (appointments: IClientsList): GetClientsAction => ({
  type: GET_CLIENTS,
  payload: appointments
});

export const setFilteredClients = (clients: IClientsList): GetFilteredClientsAction => ({
  type: GET_FILTERED_CLIENTS,
  payload: clients
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_CLIENT_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => {
  return {
    type: SET_CLIENTS_LOADING,
    payload: value
  };
};

export const setSelectedClient = (data: IClient | null): SetSelectedClientAction => ({
  type: SET_CLIENT,
  payload: data
});

export const setClientPatients = (data: any): SetClientPatientsAction => ({
  type: SET_CLIENT_PATIENTS,
  payload: data
});

export const setClientDocumentsList = (data: any): SetSelectedClientDocumentsListAction => ({
  type: SET_CLIENT_DOCUMENTS_LIST,
  payload: data
});

export const mergeClientDocumentsList = (data: any): MergeClientDocumentsListAction => ({
  type: MERGE_CLIENT_DOCUMENTS_LIST,
  payload: data
});

export const getClients = (
  clinicId: number,
  page?: number,
  searchValue?: any,
  sortObject?: ISortObject,
  filtered?: any,
  callBackFn?: () => void
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicClients(clinicId, page, searchValue, sortObject)
      .then((data) => {
        if (filtered) {
          dispatch(setFilteredClients(data));
        } else {
          dispatch(setClients(data));
        }
        dispatch(setLoading(false));
        callBackFn();
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getClientDetails = (clinicId: number, id: number): any => {
  return (dispatch) => {
    getClinicClientDetails(clinicId, id)
      .then((data) => {
        dispatch(setSelectedClient(data));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getClientPatients = (
  clinicId: number,
  id: number,
  searchValue?: any,
  page?: any
): any => {
  return (dispatch) => {
    getClinicClientPatients(clinicId, id, searchValue, page)
      .then((data) => {
        dispatch(setClientPatients(data.data));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createClient = (clinicId: number, data, callbackFunction: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicClient(clinicId, data)
      .then((res) => {
        dispatch(showModal(false));
        if (callbackFunction) {
          callbackFunction(res);
        } else {
          window.location.href = `/clinic/${clinicId}/clients/${res.id}`;
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateClient = (clinicId: number, data, clientId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicClient(clinicId, data, clientId)
      .then(() => {
        dispatch(getClientDetails(clinicId, clientId));
        dispatch(showModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const setAvatar = (clinicId, clientId, formData, callBackFnc): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    uploadAvatar(clinicId, clientId, formData)
      .then((data) => {
        dispatch(getClientDetails(clinicId, clientId));
        if (callBackFnc) {
          callBackFnc();
        }
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getClientDocumentsList = (clinicId: number, clientId: number): any => {
  return (dispatch) => {
    dispatch(setClientDocumentsList(null));
    dispatch(setLoading(true));
    getClinicClientDocumentsList(clinicId, clientId)
      .then((data) => {
        dispatch(setClientDocumentsList(data.reverse()));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createClientDocument = (clinicId: number, clientId: number, data: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicClientDocument(clinicId, clientId, data)
      .then((res) => {
        dispatch(getClientDocumentsList(clinicId, clientId));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
