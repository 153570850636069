import './UserRegistration.scss';

import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import * as localStorage from 'local-storage';
import React, { useEffect, useState } from 'react';

import PhoneFieldFormItem from '../../../components/PhoneFieldFormItem';
import { useLocale } from '../../../hooks/useLocale';
import { registration } from '../../../services/authService';
import MessageModal from '../messageModal/MessageModal';

const UserRegistration = ({
  closeModal,
  form
}) => {
  const [loading, setLoading] = useState(false);
  const locale = useLocale('public.modals.sign-up');
  const labels = locale.labels;
  const messages = locale.messages;
  const links = locale.links;
  const buttons = locale.buttons;
  const placeholders = locale.placeholders;

  useEffect(() => {
    form.resetFields();
  }, []);

  const onSubmit = (values: any) => {
    setLoading(true);
    registration(values)
      .then(() => {
        setLoading(false);
        closeModal();
        MessageModal('success', messages.registrationSuccess);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
      >
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={labels.firstName}
              name='first_name'
              rules={[
                {
                  required: true,
                  message: messages.firstNameEmpty
                },
                {
                  pattern: (/^[\u0041-\u005A\u0061-\u007A\u0400-\u04FF\u0080-\u00FF\u0100-\u024F\s'\\.\-_ʼ`]{1,100}$/),
                  message: messages.firstNameNotValid
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.lastName}
              name='last_name'
              rules={[
                {
                  required: true,
                  message: messages.lastNameEmpty
                },
                {
                  pattern: (/^[\u0041-\u005A\u0061-\u007A\u0400-\u04FF\u0080-\u00FF\u0100-\u024F\s'\\.\-_ʼ`]{1,100}$/),
                  message: messages.lastNameNotValid
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.enterPassword}
              name='password'
              rules={[
                {
                  required: true,
                  pattern: (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s).{8,}$/),
                  message: messages.inputPassword
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={labels.repeatPassword}
              name='repeatPassword'
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: messages.inputPasswordRetry
                },
                ({ getFieldValue }) => ({
                  async validator (_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(messages.passwordsDontMatch));
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <PhoneFieldFormItem localization={locale}/>
            <Form.Item
              label={labels.email}
              name='email'
              rules={[
                {
                  required: true,
                  pattern: (/^(?=.{1,256}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
                  message: messages.emailNotValid
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.dateOfBirth}
              name='birth_date'
              rules={[{
                required: true,
                message: messages.dateOfBirthNotValid
              }]}
            >
              <DatePicker disabledDate={d => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item
              label={<h6>{ labels.homeAddress}</h6>}
              style={{ marginBottom: 0 }}
              required={true}
            >
              <Form.Item
                name='country'
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                  marginRight: '16px'
                }}
                rules={[{
                  required: true,
                  message: messages.countryEmpty
                }]}
              >
                <Input placeholder={placeholders.country} />
              </Form.Item>
              <Form.Item
                name='region'
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)'
                }}
                rules={[{
                  required: true,
                  message: messages.regionEmpty
                }]}
              >
                <Input placeholder={placeholders.region} />
              </Form.Item>
            </Form.Item>
            <Form.Item
              name='city'
              rules={[{
                required: true,
                message: messages.cityEmpty
              }]}
            >
              <Input placeholder={placeholders.city} />
            </Form.Item>
            <Form.Item
              name='address'
              rules={[{
                required: true,
                message: messages.addressEmpty
              }]}
            >
              <Input placeholder={placeholders.address} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='agree'
          valuePropName='checked'
          validateTrigger={['onSubmit', 'onChange']}
          rules={[{
            required: true,
            transform: value => (value || undefined), // Those two lines
            type: 'boolean',
            message: messages.acceptTerms
          }]}
        >
          <Checkbox>{labels.acknowledge}<Button type='link' size={'small'}>{links.termsAndPrivacy}</Button></Checkbox>
        </Form.Item>
        <Form.Item
          hidden={true}
          name='locale'
          initialValue={localStorage.get('locale') ? localStorage.get('locale') : 'enGB'}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {buttons.submit}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default UserRegistration;
