import { Form, Popconfirm, Table, Typography } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import { useGetResidual } from 'pages/inventory/stock/utils/useGetResidual';
import { useCallback, useState } from 'react';
import {
  IAuditItemPrice,
  ILocation,
  ISelectedAuditItem
} from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

import { defineStatus } from './utils/defineStatus';
import { itemColumnTitle } from './utils/itemColumnTitle';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: ILocation | IAuditItemPrice;
  index: number;
  children: React.ReactNode;
}

const filterNonOperational = (data) => data.filter((el) => el.dataIndex !== 'operation');

const mergedColumnsMapper = (columns: any, isEditing: (id) => boolean, status: string) =>
  (status === 'finished' ? columns : filterNonOperational(columns)).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ILocation | IAuditItemPrice) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record.id)
      })
    };
  });

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: EditableCellProps): JSX.Element => {
  const locale = useLocale('private.inventory.audit');

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message:
                dataIndex === 'actual_leftover'
                  ? locale.messages.inputLeftover
                  : locale.messages.inputQuantity
            }
          ]}
        >
          <VLXInputNumber
            onKeyDown={(event) => {
              if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                event.preventDefault();
              }
            }}
            min={0}
            precision={0}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const showPackagingDiff = (
  e: ILocation | IAuditItemPrice,
  selectedStock: ISelectedAuditItem
): string => {
  if (!e?.residual_diff) return '-';

  return (e?.residual_diff / selectedStock.quantity_per_package).toFixed(2);
};

type Props = {
  title: string;
  status: string;
  selectedStock: ISelectedAuditItem;
  expandedData: any;
  locale: ILocale;
  changeDataInStockList: (id, quantity, leftover) => void;
  isPriceTable?: boolean;
};

export const AuditItemExapndableTable = ({
  title,
  selectedStock,
  expandedData,
  status,
  locale,
  changeDataInStockList,
  isPriceTable
}: Props): JSX.Element => {
  const [form] = Form.useForm();
  const { unitOfMeasurement, packagingFormat, noPackageFormat } = useGetResidual(selectedStock);
  const currencies = useLocale('private.currencies').labels;
  const [editingKey, setEditingKey] = useState(null);

  const isEditing = (id: number): boolean => id === editingKey;

  const saveChange = async (id: number): Promise<void> => {
    try {
      const row = await form.validateFields();

      changeDataInStockList(id, row.actual_quantity, row.actual_leftover);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const edit = (record: ILocation | IAuditItemPrice): void => {
    form.setFieldsValue({
      ...record,
      actual_quantity:
        record.actual_quantity !== undefined && record.actual_quantity !== null
          ? record.actual_quantity
          : 0,
      actual_leftover:
        record.actual_leftover !== undefined && record.actual_leftover !== null
          ? record.actual_leftover
          : 0
    });
    setEditingKey(record.id as number);
  };

  const ActionButtons = useCallback(
    (record): JSX.Element => {
      const editable = isEditing(record.id);

      return editable ? (
        <span>
          <Typography.Link
            onClick={async () => {
              saveChange(record.audit_item_id);
            }}
            style={{
              marginRight: 8,
              whiteSpace: 'nowrap'
            }}
          >
            {locale.labels.save}
          </Typography.Link>
          <Popconfirm title={locale.labels.sureToCancel} onConfirm={() => setEditingKey('')}>
            <Typography.Link>{locale.labels.cancel}</Typography.Link>
          </Popconfirm>
        </span>
      ) : (
        <Typography.Link
          disabled={editingKey}
          onClick={() => edit(record)}
          style={{ whiteSpace: 'nowrap' }}
        >
          {locale.labels.edit}
        </Typography.Link>
      );
    },
    [editingKey]
  );

  const customColumn = isPriceTable
    ? {
        key: 'price',
        title: `${locale.labels.price} ${
          noPackageFormat ? `${unitOfMeasurement}` : `(${packagingFormat}/${unitOfMeasurement})`
        }`,
        sorter: (a, b) => (a.price_per_package > b.price_per_package ? 1 : -1),
        render: (e) => priceDropDownValue(e)
      }
    : {
        key: 'name',
        title: `${locale.labels.name}`,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: ({ name }) => `${name === 'unassigned' ? locale.labels.unassigned : name}`
      };

  const columns = [
    customColumn,
    noPackageFormat
      ? null
      : {
          key: 'expectedQuantity',
          title: itemColumnTitle(`${locale.labels.expectedQuantity}, ${packagingFormat}`),
          render: (e) => `${e?.expected_quantity}`
        },
    {
      key: 'expectedResidual',
      title: itemColumnTitle(`${locale.labels.expectedResidual}, ${unitOfMeasurement}`),
      render: (e) =>
        `${
          e.expected_residual > 0
            ? Number(
                (
                  e.expected_residual -
                  e.expected_quantity * selectedStock.quantity_per_package
                ).toFixed(3)
              )
            : e.expected_residual
        }`
    },
    {
      key: 'actualQuantity',
      title: itemColumnTitle(
        `${locale.labels.actualQuantity}, ${noPackageFormat ? unitOfMeasurement : packagingFormat}`
      ),
      dataIndex: 'actual_quantity',
      editable: true
    },
    noPackageFormat
      ? null
      : {
          key: 'actualLeftover',
          title: itemColumnTitle(`${locale.labels.actualLeftover}, ${unitOfMeasurement}`),
          dataIndex: 'actual_leftover',
          editable: true
        },
    {
      key: 'residualDiff',
      title: itemColumnTitle(
        `${locale.labels.residualDiff} (${noPackageFormat ? unitOfMeasurement : packagingFormat})`
      ),
      render: (e) => showPackagingDiff(e, selectedStock)
    },
    {
      key: 'status',
      title: locale.labels.status,
      render: (e) => defineStatus(e?.status, locale)
    },
    {
      key: 'operation',
      title: locale.labels.operation,
      render: (_, record: IAuditItemPrice) => ActionButtons(record)
    }
  ].filter((column) => column);

  const mergedColumns = mergedColumnsMapper(columns, isEditing, status);

  const priceDropDownValue = (item: IAuditItemPrice): string => {
    const packagePrice = item.price_per_package;
    const sellingPrice = item.price_per_unit;
    if (noPackageFormat) return `${packagePrice} ${currencies.uah}`;
    return `${packagePrice}/${sellingPrice} ${currencies.uah}`;
  };

  return (
    <Form form={form} component={false}>
      <Typography.Title style={{ marginLeft: 50 }} level={5}>
        {title}
      </Typography.Title>
      <Table
        rowKey='id'
        components={{
          body: {
            cell: EditableCell
          }
        }}
        style={{ maxWidth: '1400px' }}
        columns={mergedColumns}
        dataSource={expandedData}
        pagination={false}
        size='small'
        rowClassName='editable-row'
        className='audits-items-table'
        bordered
      />
    </Form>
  );
};
