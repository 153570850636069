import { ISaleDetails, ISalesList } from 'interfaces/sales';
import {
  createClinicSale,
  getClinicSaleDetails,
  getClinicSales,
  removeClinicSale,
  updateClinicSale
} from 'services/clinic/sales/salesService';

import { getStock } from '../inventory/stock/actions';
import {
  RESET,
  SalesResetAction,
  SET_SALE_DETAILS,
  SET_SALES,
  SET_SALES_LOADING,
  SetLoadingAction,
  SetSaleDetailsAction,
  SetSalesAction,
  SHOW_SALES_MODAL,
  ShowModalAction
} from './types';

export const setSales = (sales: ISalesList): SetSalesAction => ({
  type: SET_SALES,
  payload: sales
});

export const resetSales = (): SalesResetAction => ({
  type: RESET
});

export const setShowModal = (value: boolean): ShowModalAction => ({
  type: SHOW_SALES_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_SALES_LOADING,
  payload: value
});

export const setSalesDetails = (data: ISaleDetails | null): SetSaleDetailsAction => ({
  type: SET_SALE_DETAILS,
  payload: data
});

export const getSales = (clinicId: number, page?: number, searchValue?: any, sortObject?): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicSales(clinicId, page, searchValue, sortObject)
      .then((data) => {
        dispatch(setSales(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getSaleDetails = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicSaleDetails(clinicId, id)
      .then((data) => {
        dispatch(setSalesDetails(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createSale = (clinicId: number, data: any, callBackFunction?: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicSale(clinicId, data)
      .then((res: ISaleDetails) => {
        dispatch(setSalesDetails(res));
        dispatch(setShowModal(false));
        callBackFunction(res);
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateSale = (clinicId: number, saleId: number, data, callBackFunction?: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicSale(clinicId, saleId, data)
      .then((res: ISaleDetails) => {
        dispatch(setSalesDetails(res));
        dispatch(setLoading(false));
        callBackFunction(res);
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const removeSale = (clinicId: number, saleId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    removeClinicSale(clinicId, saleId)
      .then(() => {
        dispatch(getStock(clinicId, 0));
        dispatch(getSales(clinicId));
        dispatch(setShowModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
