import './AuditDetails.scss';

import { Spin } from 'antd';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAuditById, getAuditItemsById, headAuditItems } from 'redux/inventory/audit/actions';
import { getStock } from 'redux/inventory/stock/actions';

import AddAuditItemModal from '../AuditModals/AddAuditItemModal';
import EditAuditModal from '../AuditModals/EditAuditModal';
import { AuditDetailsHeader } from './AuditDetailsHeader';
import { AuditItemsTable } from './AuditItemsTable';

dayjs.extend(customParseFormat);

const AuditDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, auditId }: any = useParams();
  const userAccess = useUserAccess();
  const locale = useLocale('private.inventory.audit');

  const selectedAudit = useSelector(({ audit }: IAppState) => audit.selectedAudit);
  const selectedAuditItemsData = useSelector(
    ({ audit }: IAppState) => audit.selectedAuditItems.data
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);
  const [showEditauditModal, setShowEditAuditModal] = useState<boolean>(false);

  useEffect((): void => {
    if (!clinicId) return;

    dispatch(getAuditById(clinicId, auditId));
    dispatch(getAuditItemsById(clinicId, auditId, 1));
    dispatch(headAuditItems(clinicId, auditId));
    dispatch(getStock(clinicId, 0));
  }, [clinicId]);

  return (
    <Spin spinning={!selectedAudit} className='audit-details-page-spin-container'>
      {!!selectedAudit && selectedAuditItemsData && (
        <div style={{ paddingLeft: '20px' }}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='audit-name'>
              {selectedAudit.name}
            </h4>
          </div>
          <div className='audit-row-container__form'>
            <AuditDetailsHeader
              selectedAuditItems={selectedAuditItemsData}
              selectedAudit={selectedAudit}
              locale={locale}
            />
            {selectedAudit.status !== 'finished' && (
              <div
                style={{
                  visibility: !showEditauditModal ? 'visible' : 'hidden',
                  padding: 10
                }}
              >
                <ViewDetailsButtonsGroup
                  hideCancelBtn
                  fixed
                  accessGroup={userAccess.accesses.audit}
                  editMode={false}
                  onEditClick={() => setShowEditAuditModal(true)}
                  hideDeleteBtn
                />
              </div>
            )}
          </div>

          <div className='stock-row-container__table'>
            <AuditItemsTable
              itemsData={selectedAuditItemsData}
              selectedAudit={selectedAudit}
              locale={locale}
              clinicId={clinicId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            {selectedAudit.status !== 'finished' && (
              <StickyButton
                offsetBottom={10}
                onClick={() => setShowAddItemModal(true)}
                textElement={locale.buttons.addAuditStockItems}
                iconElement={
                  <span
                    className='icofont icofont-plus mr-2 sticky-btn-icon'
                    style={{ fontSize: '1.3em' }}
                  />
                }
                customZIndex={998}
              />
            )}
          </div>

          {showEditauditModal && (
            <EditAuditModal
              locale={locale}
              selectedAudit={selectedAudit}
              auditId={auditId}
              setShowEditAuditModal={setShowEditAuditModal}
              showEditauditModal={showEditauditModal}
              setShowDeleteModal={setShowDeleteModal}
              showDeleteModal={showDeleteModal}
            />
          )}

          {showAddItemModal && (
            <AddAuditItemModal
              locale={locale}
              clinicId={clinicId}
              auditId={auditId}
              currentPage={currentPage}
              setShowAddItemModal={setShowAddItemModal}
              showAddItemModal={showAddItemModal}
            />
          )}
        </div>
      )}
    </Spin>
  );
};

export default AuditDetails;
