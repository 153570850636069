import './ClientsList.scss';

import { Button, Pagination, Row, Spin, Table, TableColumnProps, Tooltip, Typography } from 'antd';
import AppointmentListIcon from 'assets/img/new-appointment.svg';
import tomb from 'assets/img/tomb.svg';
import VLXListCompositeSearch from 'components/VLXListCompositSearch';
import { animalIdUrl } from 'constants/comon';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { UseSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import { IClient } from 'interfaces/client';
import { IPatient } from 'interfaces/patient';
import AddAppointment from 'layout/modals/addAppointment/AddAppointment';
import AddClient from 'layout/modals/addClient/AddClient';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setInitialDataForAppointment, showModal } from 'redux/appointments/actions';
import {
  getClientPatients,
  getClients,
  resetClients,
  setClientPatients,
  setSelectedClient,
} from 'redux/clients/actions';
import { localizedPetData } from 'utils/localized-pet-data';

import { getAnimalAge } from '../patients/helpers/PatientHelper';
import { paymentStatus } from './ClientsBase';

const ClientsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();

  const navigate = useNavigate();
  const locale = useLocale('private.clients.client');
  const currencies = useLocale('private.currencies').labels;
  const patientLocale = useLocale('private.patients.patient-page');

  const [expandedLoading, setExpandedLoading] = useState<boolean>(false);
  const [expanded, setExpended] = useState([]);

  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);
  const loading = useSelector(({ clients }: IAppState) => clients.loading);
  const {
    data,
    metadata
  } = useSelector(({ clients }: IAppState) => clients);
  const clientPatients = useSelector(({ clients }: IAppState) => clients.clientPatients);

  const {
    handleChange,
    onPageChange
  } = UseSearch({
    action: getClients,
    clinicId,
    defaultSortKey: 'name',
    defaultSortOrder: 'asc'
  });

  useEffect(() => {
    dispatch(setSelectedClient(null));

    return () => {
      dispatch(resetClients());
    };
  }, []);

  const redirectToClientDetails = (record: IClient): { onClick: () => void } => {
    return {
      onClick: () => {
        navigate(`/clinic/${clinicId}/clients/${record.id}`);
      }
    };
  };

  const redirectToPatientDetails = (record: IPatient): { onClick: () => void } => {
    return {
      onClick: () => {
        navigate(`/clinic/${clinicId}/patients/${record.id}`);
      }
    };
  };

  const getClientAddress = (e: IClient): string =>
    ['country', 'region', 'city', 'address']
      .map((k) => e[k])
      .filter((value) => value !== null && value !== '')
      .join(', ') || '-';

  const phoneNumber = (c: IClient): JSX.Element | string => {
    if (c.phone_number) {
      return <Link
        to={`tel:${c.country_code}${c.phone_number}`}
        className='custom-links d-flex align-baseline nowrap'
      >
        <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
        <span id='phone-number'>{`+${c.country_code}${c.phone_number}`}</span>
      </Link>;
    } else {
      return '-';
    }
  };

  const columns: TableColumnProps<IClient>[] = [
    {
      key: 'first_name',
      dataIndex: 'first_name',
      title: locale.labels.name,
      sorter: (a, b) => null,
      render: (first_name, e) => (
        <>
          <span>
            {e.first_name} {e.last_name || ''}
          </span>
          {blacklistedStatus(e)}
        </>
      ),
      onCell: redirectToClientDetails
    },
    {
      key: 'phone_number',
      title: locale.labels.phone,
      sorter: (a, b) => null,
      render: (phoneNumber)
    },
    {
      key: 'email',
      title: locale.labels.email,
      sorter: (a, b) => null,
      render: (e) => `${e.email ? e.email : '-'}`,
      onCell: redirectToClientDetails
    },
    {
      key: 'has_debt',
      title: locale.labels.paymentsStatus,
      sorter: (a, b) => null,
      render: (e) => paymentStatus(e, locale, currencies),
      onCell: redirectToClientDetails
    },
    {
      key: 'number_of_pets',
      title: locale.labels.numberOfPets,
      sorter: (a, b) => null,
      render: (e) => e.number_of_pets,
      onCell: redirectToClientDetails
    },
    {
      key: 'address',
      title: locale.labels.address,
      sorter: (a, b) => null,
      render: (e) => getClientAddress(e),
      onCell: redirectToClientDetails
    },
    {
      key: 'id',
      title: locale.labels.actions,
      render: (e) => (
        <div className='buttons-list nowrap'>
          <Button
            onClick={() => {
              dispatch(showModal(true));
              dispatch(
                setInitialDataForAppointment({
                  client_id: e.id,
                  phone_number: e.phone_number
                })
              );
            }}
            shape='circle'
            type='primary'
          >
            <img
              src={AppointmentListIcon}
              style={{
                width: 18,
                color: 'white',
                marginLeft: 1
              }}
            />
          </Button>
        </div>
      )
    }
  ];

  const getAnimalData = (data: IPatient): string => {
    const { species, breed } = localizedPetData(data, localeCode);
    return species ? (breed ? `${species} (${breed})` : species) : '-';
  };

  const getAge = (e: IPatient): JSX.Element => {
    return e?.date_of_death ? (
      <Typography id='patient-death-icon'>
        <Tooltip
          trigger={'hover'}
          placement='topLeft'
          title={getAnimalAge(patientLocale, e?.birth_date, e?.date_of_death)}
        >
          {'\u00A0'}
          <img
            src={tomb}
            style={{
              width: 22,
              height: 22
            }}
          />
        </Tooltip>
      </Typography>
    ) : (
      <Typography id='list-pet-age'>
        {e?.birth_date ? getAnimalAge(locale, e?.birth_date) : '-'}
      </Typography>
    );
  };

  const expandedColumns: Array<TableColumnProps<any>> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: locale.labels.name,
      onCell: redirectToPatientDetails,
      render: (name, e) => `${name}`
    },
    {
      key: 'age',
      title: locale.labels.age,
      onCell: redirectToPatientDetails,
      render: getAge
    },
    {
      key: 'species',
      title: locale.labels.speciesBreed,
      onCell: redirectToPatientDetails,
      render: getAnimalData
    },
    {
      key: 'chip_number',
      title: locale.labels.chip,
      render: (e) =>
        e.chip_number ? (
          <a className={'custom-links'} href={animalIdUrl + e.chip_number}>
            {e.chip_number}
          </a>
        ) : (
          '-'
        )
    },
    {
      key: 'id',
      title: locale.labels.actions,
      render: (e) => (
        <div className='buttons-list nowrap'>
          <Button
            onClick={() => {
              dispatch(showModal(true));
              dispatch(
                setInitialDataForAppointment({
                  patients_ids: [e.id],
                  client_id: expanded[0],
                  phone_number: data.find((el) => el.id === expanded[0]).phone_number
                })
              );
            }}
            shape='circle'
            type='primary'
          >
            <img
              src={AppointmentListIcon}
              style={{
                width: 18,
                color: 'white',
                marginLeft: 3
              }}
            />
          </Button>
          {/* for delete
          <Button onClick={() => {}} shape='circle' danger>
            <span className='icofont icofont-ui-delete' />
          </Button> */}
        </div>
      )
    }
  ];

  const expandedRowRender = (): JSX.Element =>
    clientPatients && (
      <Table
        rowKey='id'
        loading={expandedLoading}
        columns={expandedColumns}
        dataSource={clientPatients}
        pagination={false}
        size='small'
        className='client-table'
        bordered
      />
    );

  return (
    <>
      <VLXListCompositeSearch />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header clients-list-table'
          rowKey='id'
          dataSource={data}
          columns={columns}
          onChange={handleChange}
          expandable={{
            onExpand: (expanded, record) => {
              setExpandedLoading(true);
              dispatch(setClientPatients(null));
              if (expanded) dispatch(getClientPatients(clinicId, record.id, null, 0));
              const current = expanded ? [record.id] : [];
              setExpended(current);
              setExpandedLoading(false);
            },
            expandedRowKeys: expanded,
            expandedRowRender
          }}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            defaultCurrent={1}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            current={metadata.page}
            total={metadata.total}
            onChange={onPageChange}
          />
          <AddClient />
        </Row>
        <AddAppointment hideDefaultCreatePetFormBtn />
      </Spin>
    </>
  );
};

export default ClientsList;
