import { scrollToTop } from 'helpers/list-helpers';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ISearchCriteria } from '../interfaces/search-criteria.ts';

interface Props {
  action: any,
  clinicId: number,
  defaultSortKey: string,
  defaultSortOrder: string,
  customFilters?: ISearchCriteria[]
}

export const UseSearch =({ action, clinicId, defaultSortKey, defaultSortOrder, customFilters = [] }: Props) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get('page');
  const sort_key = searchParams.get('sort_key');
  const sort_order = searchParams.get('sort_order');
  const filter = searchParams.get('filter');

  useEffect(() => {
    setParams(currentPage ?? '1', sort_key ?? defaultSortKey, sort_order ?? defaultSortOrder, filter ?? '');
  }, []);

  useEffect((): void => {
    if (searchParams.get('page')) getResults();
  }, [searchParams]);

  const onPageChange = (page: number): void => {
    scrollToTop();
    setParams(page, sort_key, sort_order, filter);
  };

  const handleChange = (pagination, filters, sorter): void => {
    let order = '';
    let sortKey = sorter.columnKey;
    if (sorter.order) {
      order = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      order = defaultSortOrder;
      sortKey = defaultSortKey;
    }
    setParams(currentPage, sortKey, order, filter);
  };

  const setParams = (page: number | string, sort_key: string, sort_order: string, filter: string) => {
    setSearchParams({
      page: String(page),
      sort_key,
      sort_order,
      filter
    });
  };

  const getResults = () => {
      const searchArray = [];
      if (filter !== null) {
        const criteria = filter.split(' ');
        criteria.filter(c => c !== '')
          .forEach((c) => {
          if (c && c !== '') {
            searchArray.push({
              name: 'composite',
              value: c,
              isArrayValue: true
            });
          }
        });
      }
      searchArray.push(...customFilters);

    dispatch(action(clinicId, +currentPage, searchArray, {sort_key, sort_order}));
  };

  return { handleChange, onPageChange, getResults };
};
