import '../../../assets/scss/transfer.scss';

import { Button, Form, Input, Space, Spin, Transfer } from 'antd';
import React, { Key, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocale } from '../../../hooks/useLocale';
import { IAppState } from '../../../interfaces/app-state';
import { editRole, setEditMode } from '../../../redux/roles/actions';

const EditRoleForm = ({ clinicId }: any): JSX.Element => {
  const dispatch = useDispatch();
  const permissions = useSelector<IAppState, any>((state) => state.permissions);
  const roles = useSelector<IAppState, any>((state) => state.roles);
  const [form] = Form.useForm();
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const locale = useLocale('private.administration.roles');

  const onCancelClick = () => {
    dispatch(setEditMode(false));
  };

  const data = permissions.data.map((item) => {
    return {
      key: item.id,
      title: item.name,
      description: '',
      chosen: false
    };
  });

  const getData = () => {
    const target = roles.selectedRole?.permissions?.map((item) => {
      return item.id;
    });
    setTargetKeys(target);
  };

  useEffect(() => {
    getData();
    form.setFieldsValue(roles.selectedRole);
  }, [roles.selectedRole]);

  const onFinish = (values: any) => {
    const payload = {
      name: values.name,
      permissions_ids: values.permissions.map((item) => {
        if (item.id) {
          return parseInt(item.id);
        }
        return parseInt(item);
      })
    };
    dispatch(editRole(clinicId, roles.selectedRole.id, payload));
  };

  return (
    <Spin spinning={permissions.loading}>
      <Form autoComplete='off' form={form} layout={'vertical'} onFinish={onFinish}>
        <div className='row' style={{ margin: '10px 20px' }}>
          <div className='col-md-3 col-sm-12'>
            <Form.Item
              label={locale.labels.name}
              name='name'
              rules={[{ required: true, message: locale.messages.nameEmpty }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className='col-md-6 col-sm-12'>
            <Form.Item label={locale.labels.permissions} name='permissions' required={true}>
              <Transfer
                className={'transfer'}
                dataSource={data}
                titles={[locale.labels.availablePermissions, locale.labels.currentRolePermissions]}
                listStyle={{
                  maxWidth: '50%',
                  minWidth: '30%',
                  width: 400,
                  height: 500
                }}
                oneWay={true}
                showSearch
                targetKeys={targetKeys}
                onChange={(newTargetKeys) => {
                  setTargetKeys(newTargetKeys);
                }}
                render={(item) => item.title}
                pagination
              />
            </Form.Item>
          </div>
          <div className='col-md-3 col-sm-12'>
            <Space style={{ marginTop: 30 }}>
              <Form.Item>
                <Button type='primary' htmlType='submit' shape='circle'>
                  <span className='icofont icofont-save' />
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type='primary' onClick={onCancelClick} shape='circle' danger>
                  <span className='icofont icofont-ban' />
                </Button>
              </Form.Item>
            </Space>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default EditRoleForm;
