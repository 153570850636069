import { Input } from 'antd';
import { useLocale } from 'hooks/useLocale';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const { Search } = Input;

const VLXListCompositeSearch = (): JSX.Element => {
  const locale = useLocale('private.composite-search');
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = searchParams.get('filter');
  const [searchValue, setSearchValue] = useState<string>(filterParams);

  const onSearch = (value: string): void => {
    if (value && filterParams === value) return;
    const finalValue = value ?? '';
    setSearchValue(finalValue);
    searchParams.set('filter', finalValue);
    setSearchParams(searchParams);
  };

  return (
    <Search
      placeholder={locale.placeholders.searchCriteria}
      className='search-field'
      onSearch={onSearch}
      enterButton
      allowClear
      defaultValue={searchValue}
    />
  );
};

export default VLXListCompositeSearch;
