import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBlocker } from 'react-router-dom';

import ConfirmCancelModal from '../../components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from '../../components/ViewDetailsButtonsGroup';
import { useUserAccess } from '../../hooks/useUserAccess';
import { showModal } from '../../redux/clients/actions';
import { AppointmentDetailsPatientTabForm } from './AppointmentDetailsPatientTabForm';
import { AppointmentDetailsPatientTabServicesForm } from './AppointmentDetailsPatientTabServicesForm.tsx';
import { IAppointment } from '../../interfaces/appointment.ts';
import { ILocale } from '../../interfaces/locale.ts';
import { IHealthRecord } from '../../interfaces/health-record.ts';

type Props = {
  patientId: number;
  onlyServices: boolean;
  initialValues: IHealthRecord;
  locale: ILocale;
  appointment: IAppointment;
  disabled: boolean;
};

const AppointmentDetailsPatientTab = ({
  patientId,
  onlyServices,
  initialValues,
  locale,
  appointment,
  disabled
}: Props) => {
  const dispatch = useDispatch();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();
  const [createNewRecord, setCreateNewRecord] = useState(true);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
    setEditMode(false);
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  };

  const onFieldsChange = () => {
    if (form.isFieldsTouched() && !editMode) {
      setEditMode(true);
    }
  };

  useEffect(() => {
    if (initialValues) {
      setCreateNewRecord(false);
    } else {
      setCreateNewRecord(true);
    }
  }, [initialValues]);

  const blocker = useBlocker(({
    currentLocation,
    nextLocation
  }) => {
    console.log(nextLocation);
    // if (nextLocation) {
    //   setShowCancelModal(true);
    // }
    return false;
  });

  const buttons = () => (
    <ViewDetailsButtonsGroup
      fixed
      editMode={editMode}
      onEditClick={() => setEditMode(true)}
      accessGroup={userAccess.accesses.appointments}
      hideEditBtn
      hideDeleteBtn
      onCancelClick={() => {
        if (form.isFieldsTouched()) {
          setShowCancelModal(true);
        } else {
          setEditMode(false);
        }
      }}
      onDeleteClick={() => {
        setShowCancelModal(false);
      }}
    />
  );

  return (
    <>
      {onlyServices
        ? <AppointmentDetailsPatientTabServicesForm
          appointment={appointment}
          form={form}
          initialValues={initialValues}
          onFieldsChange={onFieldsChange}
          editMode={editMode}
          setEditMode={setEditMode}
          locale={locale}
          patientId={patientId}
          disabled={disabled}
        >
          {buttons()}
        </AppointmentDetailsPatientTabServicesForm>
        : <AppointmentDetailsPatientTabForm
          appointment={appointment}
          form={form}
          initialValues={initialValues}
          onFieldsChange={onFieldsChange}
          editMode={editMode}
          setEditMode={setEditMode}
          locale={locale}
          patientId={patientId}
          createNewRecord={createNewRecord}
          disabled={disabled}
        >
          {buttons()}
        </AppointmentDetailsPatientTabForm>}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};

export default AppointmentDetailsPatientTab;
