import {
  RESET,
  SET_LOADING,
  SET_OPENED_SHIFT,
  SET_SELECTED_SHIFT,
  SET_SHIFTS,
  SetLoadingAction,
  SetOpenedShiftAction,
  SetSelectedShiftAction,
  SetShiftsAction,
  ShiftsResetAction,
  SHOW_MODAL,
  ShowModalAction
} from './types';
import { IndexShiftsResponse } from '../../services/clinic/shifts/models/IndexShiftsResponse';
import { ShowShiftResponse } from '../../services/clinic/shifts/models/ShowShiftResponse';
import {
  closeClinicShift,
  indexClinicShifts,
  openClinicShift,
  showClinicShift,
  showOpenedClinicShift
} from '../../services/clinic/shifts/shiftsService';

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const resetShifts = (): ShiftsResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setShifts = (shifts: IndexShiftsResponse): SetShiftsAction => ({
  type: SET_SHIFTS,
  payload: shifts
});

export const setSelectedShift = (data: ShowShiftResponse | null): SetSelectedShiftAction => ({
  type: SET_SELECTED_SHIFT,
  payload: data
});

export const setOpenedShift = (data: ShowShiftResponse | null): SetOpenedShiftAction => ({
  type: SET_OPENED_SHIFT,
  payload: data
});

export const getShifts = (clinicId: number, page?: number, searchArray?: any): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicShifts(clinicId, page, searchArray)
      .then(data => {
        dispatch(setShifts(data));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const showShiftDetails = (clinicId: number, shiftId: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    showClinicShift(clinicId, shiftId)
      .then(data => {
        dispatch(setSelectedShift(data));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const openShift = (clinicId: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    openClinicShift(clinicId)
      .then(data => {
        dispatch(setOpenedShift(data));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getOpenedShift = (clinicId: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    showOpenedClinicShift(clinicId)
      .then(data => {
        dispatch(setOpenedShift(data));
      }).finally(() => dispatch(setLoading(false)));
  };
};

export const closeShift = (clinicId: number, shiftId: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    closeClinicShift(clinicId, shiftId)
      .then(data => {
        dispatch(setOpenedShift(data));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
