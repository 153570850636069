import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCreateModal } from 'redux/inventory/tools/actions';
import { ToolsListState } from 'redux/inventory/tools/reducer';

import AddRoomForm from './AddToolForm';

const AddToolModal = ({ clinicId }: { clinicId: number }): JSX.Element => {
  const dispatch = useDispatch();
  const tools = useSelector<IAppState, ToolsListState>(({ tools }) => tools);
  const [createToolForm] = Form.useForm();
  const locale = useLocale('private.inventory.tools');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();

  const onCancel = (): void => {
    dispatch(showCreateModal(false));
  };

  const onConfirmCancelClick = (): void => {
    createToolForm.resetFields();
    dispatch(showCreateModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      <StickyButton
        offsetBottom={10}
        onClick={() => dispatch(showCreateModal(true))}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.create}
      />
      <VLXModal
        form={createToolForm}
        title={locale.labels.createNewTool}
        open={tools.showCreateModal}
        onModalClose={onCancel}
        width={800}
      >
        <AddRoomForm form={createToolForm} clinicId={clinicId}>
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.tools}
            editMode={true}
            hideDeleteBtn={true}
            onCancelClick={() => {
              if (createToolForm.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                dispatch(showCreateModal(false));
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
            }}
          />
        </AddRoomForm>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddToolModal;
