import './AppointmentServices.scss';

import { Button, Checkbox, Col, Divider, Form, Row, Select } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { measurementsLocale } from 'i18n/measurements';
import React, { useEffect, useState } from 'react';
import { getClinicStockById } from 'services/clinic/inventory/stockService';
import {
  ISelectedStock,
  ISelectedStockLocations,
  ISelectedStockPrices
} from 'services/interfaces/clinics/inventory/stock/ISelectedStock';

import { StockSelect } from '../../../layout/components/stockSelect/StockSelect.tsx';

const { Option } = Select;

const AppointmentServicesStockItems = ({
  name,
  initialData,
  locale,
  remove,
  form,
  addedItems,
  setAddedItems,
  disabled
}: any): JSX.Element => {
  const [stockItem, setStockItem] = useState(null);
  const clinicId = useClinicId();
  const currencies = useLocale('private.currencies').labels;

  const [stockDetails, setStockDetails] = useState<ISelectedStock>(null);
  const unitOfMeasurement = useI18n(measurementsLocale)[stockDetails?.unit_of_measurement] || '';

  const [locations, setLocations] = useState<ISelectedStockLocations[]>(null);
  const [prices, setPrices] = useState<ISelectedStockPrices[]>(null);
  const [selectedLocation, setSelectedLocation] = useState<ISelectedStockLocations>(null);
  const [selectedPrice, setSelectedPrice] = useState<ISelectedStockPrices>(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [initialQuantity, setInitialQuantity] = useState(null);
  const [includedInPrice, setIncludedInPrice] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    setStockItem(form.getFieldValue(['stock', name]));
  }, [initialData]);

  useEffect(() => {
    if (stockItem?.stock?.id) {
      getItemsForNewStock(stockItem?.stock?.id);
    }
    setSelectedQuantity(stockItem?.quantity || 0);
    setInitialQuantity(stockItem?.quantity || 0);
    setIncludedInPrice(stockItem?.included_in_service_price || false);
  }, [stockItem?.stock?.id]);

  useEffect(() => {
    onIncludedInPriceChange({ target: { checked: stockItem?.included_in_service_price || false } });
  }, [selectedPrice, selectedQuantity]);

  useEffect(() => {
    if (!stockDetails) return;

    setLocations(stockDetails.locations);
    setPrices(stockDetails.prices);
    const data = {
      stock_price_id: null,
      stock_location_id: null
    };
    if (stockItem?.stock_location_id) {
      const selectedLocation = stockDetails.locations?.find(
        (el) => el.id === stockItem?.stock_location_id
      );
      setSelectedLocation(selectedLocation);
      data.stock_location_id = selectedLocation?.id;
    } else {
      const availableLocations = stockDetails.locations?.filter(
        (item) => !item.deleted && item.residual
      );
      const defaultLocation = availableLocations.length === 1 ? availableLocations[0] : null;
      setSelectedLocation(defaultLocation);
      data.stock_location_id = defaultLocation?.id;
    }
    if (stockItem?.stock_price_id) {
      const selectedPrice = stockDetails.prices?.find((el) => el.id === stockItem?.stock_price_id);
      setSelectedPrice(selectedPrice);
      data.stock_price_id = selectedPrice?.id;
    } else {
      const availablePrices = stockDetails.prices?.filter((item) => !item.deleted && item.residual);
      const defaultPrice = availablePrices.length === 1 ? availablePrices[0] : null;
      setSelectedPrice(defaultPrice);
      data.stock_price_id = defaultPrice?.id;
    }

    handleUpdateItem(data);
  }, [stockDetails]);

  useEffect(() => {
    if (!addedItems.some((el) => el === name)) {
      setAddedItems([...addedItems, name]);
    }
  }, [name]);

  const locationsList = (): JSX.Element[] =>
    locations
      ?.filter((item) => !item.deleted || item?.id === selectedLocation?.id)
      .sort((item) => (item.residual > 0 ? -1 : 1))
      .map((item) => (
        <Option
          className='item-form-select'
          key={item.id}
          disabled={item.residual <= 0}
          title={item.name}
          value={item.id}
        >
          {`${item.name === 'unassigned' ? locale.labels.unassigned : item.name} | ${
            item.residual
          } ${[unitOfMeasurement]}`}
        </Option>
      ));

  const priceDropDownValue = (item: ISelectedStockPrices): string => {
    const itemSellingPrice = `${item.price_per_unit} ${currencies.uah}`;
    const defaultPrice = item.type === 'default' ? `(${locale.labels.default})` : '';
    return `${itemSellingPrice} ${defaultPrice} | ${locale.labels.residual} ${item.residual} ${unitOfMeasurement}`;
  };

  const pricesList = (): JSX.Element[] =>
    prices
      ?.filter((item) => !item.deleted || item?.id === selectedPrice?.id)
      .sort((item) => (item.residual > 0 ? -1 : 1))
      .map((item) => (
        <Option
          className='item-form-select'
          key={item.id}
          disabled={item.residual <= 0}
          value={item.id}
        >
          {priceDropDownValue(item)}
        </Option>
      ));

  const handleUpdateItem = (newValues): void => {
    const stock = form.getFieldValue('stock');
    stock[name] = { ...stock[name], ...newValues };
    form.setFields([
      {
        name: ['stock', name, 'quantity'],
        errors: []
      }
    ]);
    form.setFieldsValue({ stock });
  };

  const getItemsForNewStock = (id: number): void => {
    getClinicStockById(clinicId, id).then((response: ISelectedStock) => {
      setStockDetails(response);
      setLocations(response.locations);
      setPrices(response.prices);
    });
  };

  const onStockChange = (id: number): void => {
    handleUpdateItem({
      quantity: null,
      price: null,
      stock_price_id: null,
      stock_location_id: null
    });
    setInitialQuantity(0);
    setSelectedQuantity(null);
    setStockItem(null);
    getItemsForNewStock(id);
  };

  const onQuantityChange = (value: string): void => {
    const quantity = Number(value);
    const newPrice = includedInPrice ? 0 : quantity * selectedPrice?.price_per_unit;

    setSelectedQuantity(quantity);

    handleUpdateItem({
      quantity: value,
      price: newPrice.toFixed(2)
    });
  };

  const onNewPriceSelect = (id: number): void => {
    const price = prices.find((el) => el.id === id);
    let newPrice = 0;
    if (!includedInPrice) {
      newPrice = selectedQuantity * Number(price?.price_per_unit);
    }
    handleUpdateItem({
      quantity: selectedQuantity,
      price: newPrice.toFixed(2)
    });
    setSelectedPrice(price);
  };

  const onIncludedInPriceChange = (e: any): void => {
    const price = selectedPrice?.price_per_unit;
    const newPrice = e.target.checked ? 0 : (selectedQuantity || 0) * (price || 0);
    handleUpdateItem({
      quantity: selectedQuantity,
      price: newPrice.toFixed(2)
    });
    setIncludedInPrice(e.target.checked);
  };

  const onNewLocationSelect = (id: number): void => {
    const location = locations.find((el) => el.id === id);
    setSelectedLocation(location);
  };

  const onConfirmDeleteClick = (): void => {
    setAddedItems([...addedItems.filter((el) => el !== name)]);
    remove(name);
  };

  return (
    <>
      <Row gutter={[20, 10]} className='appointment-inventory-items-form top-container'>
        <Row
          gutter={[24, 12]}
          className='appointment-inventory-items-form'
          style={{ width: '100%' }}
        >
          <Col span={12} md={8} xs={24} xl={8}>
            <Form.Item
              key='stockId'
              label={locale.labels.name}
              rules={[{ required: true }]}
              className='long-value-form-item'
              name={[name, 'stock', 'id']}
            >
              <StockSelect
                clinicId={clinicId}
                onChange={onStockChange}
                selectedStockId={stockDetails?.id}
                locale={locale}
              />
            </Form.Item>
          </Col>
          <Col span={9} md={9} xs={12} xl={9}>
            <Form.Item
              label={`${locale.labels.price} ${unitOfMeasurement ? `(${unitOfMeasurement})` : ''}`}
              key='stock_price_id'
              rules={[
                {
                  required: true,
                  message: locale.messages.priceShouldBeSelected
                }
              ]}
              className='long-value-form-item'
              name={[name, 'stock_price_id']}
            >
              <Select
                disabled={disabled}
                className='long-value-form-item'
                onSelect={(e) => onNewPriceSelect(e)}
                value={selectedPrice?.id}
                filterOption={(input, option) => {
                  return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
                }}
              >
                {pricesList()}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7} md={7} xs={12} xl={7}>
            <Form.Item
              key='stock_location_id'
              label={locale.labels.location}
              rules={[
                {
                  required: true,
                  message: locale.messages.locationShouldBeSelected
                }
              ]}
              className='long-value-form-item'
              name={[name, 'stock_location_id']}
            >
              <Select
                disabled={disabled}
                className='long-value-form-item'
                onSelect={(e) => onNewLocationSelect(e)}
                value={selectedLocation?.id}
                filterOption={(input, option) => {
                  return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
                }}
              >
                {locationsList()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={[24, 12]}
          className='appointment-inventory-items-form'
          style={{ width: '100%' }}
        >
          <Col span={12} md={6}>
            <Form.Item
              key='quantity'
              label={
                stockDetails?.unit_of_measurement
                  ? `${locale.labels.quantity} (${unitOfMeasurement})`
                  : locale.labels.quantity
              }
              rules={[
                {
                  required: true
                },
                {
                  message: locale.messages.notEnoughInLocation,
                  validator: async (_, value) => {
                    if (value) {
                      if (Number(value) <= selectedLocation?.residual + initialQuantity) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(locale.messages.notEnoughInLocation);
                      }
                    }
                  }
                },
                {
                  message: locale.messages.notEnoughWithPrice,
                  validator: async (_, value) => {
                    if (value) {
                      if (Number(value) <= selectedPrice?.residual + initialQuantity) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(locale.messages.notEnoughWithPrice);
                      }
                    }
                  }
                }
              ]}
              name={[name, 'quantity']}
            >
              <VLXInputNumber
                disabled={!selectedPrice || disabled}
                onChange={onQuantityChange}
                placeholder='0'
                type='number'
                precision={3}
                step={0.01}
              />
            </Form.Item>
          </Col>
          <Col span={12} md={6}>
            <Form.Item
              key='price'
              label={locale.labels.sum}
              rules={[{ required: !includedInPrice }]}
              name={[name, 'price']}
            >
              <VLXInputNumber
                disabled={!selectedPrice || !selectedQuantity || disabled || includedInPrice}
                placeholder='0'
                type='number'
                precision={2}
                step={0.1}
              />
            </Form.Item>
          </Col>
          <Col
            span={10}
            md={10}
            style={{
              display: 'flex',
              alignSelf: 'center'
            }}
          >
            <Form.Item
              className={'flat-checkbox-form-item'}
              label={locale.labels.includedInServicePrice}
              name={[name, 'included_in_service_price']}
              valuePropName='checked'
            >
              <Checkbox onChange={onIncludedInPriceChange} checked={includedInPrice} />
            </Form.Item>
          </Col>
          <Col span={2} md={2}>
            <Button
              onClick={() => {
                setShowDeleteModal(true);
              }}
              danger
              disabled={disabled}
              type='primary'
              shape={'circle'}
              className={'inventory-update-buttons'}
              style={{
                minHeight: '40px',
                minWidth: '40px'
              }}
            >
              <span className='icofont icofont-trash' />
            </Button>
          </Col>
        </Row>
        <ConfirmDeleteModal
          open={showDeleteModal}
          onOk={onConfirmDeleteClick}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Row>
      <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
    </>
  );
};

export default AppointmentServicesStockItems;
